import { useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getResult, reset } from "../../redux/openai/openaiSlice";
import { Helmet } from "react-helmet-async";

import Layout from "../components/layouts/Layout"
import Results from "../components/sections/Results";
import { RemainingWordsContext } from "../../App";
import SharedTextArea from "../components/sections/SharedTextArea";
import MainTitle from "../components/layouts/MainTitle";


const TextSummarizer = () => {
    const { setUserWords } = useContext(RemainingWordsContext);

    const [formData, setFormData] = useState({
        plot: "", tone: "משפיע ומרשים"
    });

    const dispatch = useDispatch();
    const { plot, tone } = formData;

    useEffect(() => {
        dispatch(reset());
    }, [dispatch])

    // const { user } = useSelector((state) => state.auth)
    const { data, isLoading } = useSelector(
        (state) => state.openai
    )

    const handleSubmit = async (e) => {
        e.preventDefault();
        let prompt = `Please Summarize in a ${tone ? `${tone}` : '' } way the following text: [${plot}]. Keep the same meaning but with different wording.`;
        let Newprompt = { ...formData, prompt: prompt, project: "סיכום טקסט" }
        dispatch(getResult(Newprompt));
        setUserWords(data.remainingWords);
    }
    return (
        <>
            <Helmet>
                <title>Doda.ai | סיכום טקסט</title>
            </Helmet>
            <Layout>
                <div className="min-h-100vh flex grow bg-white dark:bg-navy-900">
                    <main className="main-content w-full px-[var(--margin-x)] pb-8">
                       <MainTitle title="סיכום טקסט" />
                        <div className="grid grid-cols-12 gap-4 sm:gap-5 lg:gap-6">
                            <SharedTextArea handleSubmit={handleSubmit} descLabel="טקסט לסיכום" placeholderLabel="טקסט לסיכום" formData={formData} setFormData={setFormData} />
                            <Results isLoading={isLoading} data={data} />
                        </div>
                    </main>
                </div>
            </Layout>
        </>
    )
}
export default TextSummarizer