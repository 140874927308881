import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import authService from './authService';

//Get User from local Storage
const user = JSON.parse(localStorage.getItem("user__doda"))

const initialState = {
    user: user ? user : null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ''
}

export const register = createAsyncThunk('auth/register', async (user, thunkAPI) => {
    try {
        return await authService.register(user);
    } catch (error) {
        const message =
            (error.response &&
                error.response.data &&
                error.response.data.message) ||
            error.message ||
            error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const signupGoogle = createAsyncThunk('auth/signupgoogle', async (credential, thunkAPI) => {
    try {
        return await authService.signupGoogleUser(credential);
    } catch (error) {
        const message =
            (error.response &&
                error.response.data &&
                error.response.data.message) ||
            error.message ||
            error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})
export const loginGoogle = createAsyncThunk('auth/logingoogle', async (credential, thunkAPI) => {
    try {
        return await authService.loginGoogleUser(credential);
    } catch (error) {
        const message =
            (error.response &&
                error.response.data &&
                error.response.data.message) ||
            error.message ||
            error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})
// Login user
export const login = createAsyncThunk('auth/login', async (user, thunkAPI) => {
    try {
        return await authService.login(user)
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})
// update user
export const updateUserInfo = createAsyncThunk('auth/update-user', async (user, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.user.token
        return await authService.updateUser(user, token)
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})
// refresh user
export const fetchUser = createAsyncThunk('auth/refresh', async (_, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.user.token;
        return await authService.fetchAuthUser(token)
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const fetchGoogle = createAsyncThunk('auth/refreshGoogle', async (_, thunkAPI) => {
    try {
        return await authService.fetchGoogleUser()
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const forgotPasswordEmail = createAsyncThunk('auth/forgot-password', async (email, thunkAPI) => {
    try {
        return await authService.forgotPassword(email)
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const resetPassword = createAsyncThunk('auth/reset-password', async (formData, thunkAPI) => {
    try {
        return await authService.resetPassword(formData)
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const logout = createAsyncThunk('auth/logout', async (user, thunkAPI) => {
    await authService.logout(user)
})



export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false
            state.isSuccess = false
            state.isError = false
            state.message = ''
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(register.pending, (state) => {
                state.isLoading = true
            })
            .addCase(register.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.user = action.payload
            })
            .addCase(register.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.user = null
            })
            .addCase(signupGoogle.pending, (state) => {
                state.isLoading = true
            })
            .addCase(signupGoogle.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.user = action.payload
            })
            .addCase(signupGoogle.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.user = null
            })
            .addCase(loginGoogle.pending, (state) => {
                state.isLoading = true
            })
            .addCase(loginGoogle.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.user = action.payload
            })
            .addCase(loginGoogle.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.user = null
            })
            .addCase(login.pending, (state) => {
                state.isLoading = true
            })
            .addCase(login.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.user = action.payload
            })
            .addCase(login.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.user = null
            })
            .addCase(updateUserInfo.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.user = action.payload
            })
            .addCase(updateUserInfo.rejected, (state, action) => {
                state.isLoading = false
                state.isSuccess = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(logout.fulfilled, (state) => {
                state.user = null
            })
            .addCase(fetchUser.fulfilled, (state, action) => {
                state.user = action.payload
                state.isLoading = false
            })
            .addCase(fetchUser.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(fetchUser.rejected, (state, action) => {
                state.isLoading = false
                state.user = null
            })
            .addCase(fetchGoogle.fulfilled, (state, action) => {
                state.user = action.payload
                state.isLoading = false
            })
            .addCase(fetchGoogle.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(fetchGoogle.rejected, (state, action) => {
                state.isLoading = false
                state.user = null
            })
            .addCase(forgotPasswordEmail.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(forgotPasswordEmail.fulfilled, (state) => {
                state.isLoading = false
                state.isError = false
                state.isSuccess = true
            })
            .addCase(resetPassword.fulfilled, (state) => {
                state.isLoading = false
                state.isError = false
                state.isSuccess = true
            })
            .addCase(resetPassword.rejected, (state, action) => {
                state.isError = true
                state.isSuccess = false
                state.message = action.payload
            })

    },
})

export const { reset } = authSlice.actions;
export default authSlice.reducer;