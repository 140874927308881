import { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import Templates from "../sections/Templates";
import { ThemeContext } from "../../../App";


const Sidebar = ({ toggleSidebar }) => {
    const [isActiveToggle, setIsActiveToggle] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [currentPage, setCurrentPage] = useState('projects');
    const { user } = useSelector((state) => state.auth)
    const { darkMode } = useContext(ThemeContext);


    return (
        <>
            <Templates showModal={showModal} setShowModal={setShowModal} />
            <div className="sidebar print:hidden">
                <div className="sidebar-panel shadow-lg">
                    <div className="flex h-full grow flex-col bg-white px-4 dark:bg-navy-700">
                        <div className="flex h-18 w-full items-center justify-between pr-4 pl-1">
                            <Link to="/" className="font-bold lg:text-4xl text-xl text-base tracking-wider text-slate-800 dark:text-navy-100">
                                <img className="w-[50px]" src={`${darkMode ? '/assets/images/newWhite.png' : '/assets/images/newBlack.png'}`} alt="doda logo" />
                            </Link>
                            <button
                                onClick={toggleSidebar}
                                className="admin-btn h-7 w-7 rounded-full p-0 text-secondary hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:text-accent-light/80 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25 xl:hidden">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                                    stroke="currentColor" strokeWidth="2">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
                                </svg>
                            </button>
                        </div>
                        <div className="flex justify-between flex-col h-[calc(100%-4.5rem)] overflow-hidden pb-6">
                            <div>
                                <ul className="flex flex-1 flex-col px-4 font-inter text-lg">
                                    <li>
                                        <NavLink to="/chat" 
                                            className={`font-medium hover:text-slate-800 flex py-4 items-center tracking-wide outline-none transition-colors duration-300 ease-in-out`}>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"></path>
                                            </svg>
                                            צ׳אט </NavLink>
                                    </li>
                                    <li>
                                        <NavLink 
                                            to="/tools" className={`nav-link flex py-2 items-center tracking-wide outline-none transition-colors duration-300 ease-in-out hover:text-slate-800 dark:hover:text-navy-50`}
                                        > <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"></path>
                                            </svg> תבניות </NavLink>
                                    </li>
                                    <li 
                                        className={`${isActiveToggle ? 'is-active font-semibold text-slate-800 dark:text-navy-50' : ''}
                                         ac nav-parent  transition-colors duration-300 ease-in-out`}>
                                        <button onClick={() => setIsActiveToggle(!isActiveToggle)}
                                            className="ac-trigger flex w-full items-center justify-between py-2 tracking-wide text-slate-600 outline-none transition-[color,padding-left] duration-300 ease-in-out hover:text-slate-800 dark:text-navy-200 dark:hover:text-navy-50"
                                        ><div className="flex items-center">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4"></path>
                                                </svg>
                                                <span> כלים</span>
                                            </div>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="h-4 w-4 text-slate-400 transition-transform ease-in-out"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M15 19l-7-7 7-7"
                                                ></path>
                                            </svg>
                                        </button>
                                        <ul className={`${isActiveToggle ? 'transition ease-in' : ''} ac-panel text-black`} style={isActiveToggle ? { height: 'auto', transitionDuration: "200ms" } : { height: "0", transitionDuration: "200ms" }}>
                                            <li>
                                                <Link to="/blog-ideas"
                                                    className="nav-link flex items-center justify-between p-2 text-xs+ tracking-wide outline-none transition-[color,padding-right] duration-300 ease-in-out hover:pr-4">
                                                    <div className="flex items-center space-x-2 space-x-reverse">
                                                        <div className="h-1.5 w-1.5 rounded-full border border-current opacity-40 dark:border-white"></div>
                                                        <span className="dark:text-white">יצירת רעיונות לתוכן ובלוג</span>
                                                    </div>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/blog-introduction"
                                                    className="nav-link flex items-center justify-between p-2 text-xs+ tracking-wide outline-none transition-[color,padding-right] duration-300 ease-in-out hover:pr-4">
                                                    <div className="flex items-center space-x-2 space-x-reverse">
                                                        <div className="h-1.5 w-1.5 rounded-full border border-current opacity-40"></div>
                                                        <span className="dark:text-white">יצירת מבוא לבלוג</span>
                                                    </div>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/blog-outlines"
                                                    className="nav-link flex items-center justify-between p-2 text-xs+ tracking-wide outline-none transition-[color,padding-right] duration-300 ease-in-out hover:pr-4">
                                                    <div className="flex items-center space-x-2 space-x-reverse">
                                                        <div className="h-1.5 w-1.5 rounded-full border border-current opacity-40"></div>
                                                        <span className="dark:text-white"> יצירת קווי מתאר לבלוג</span>
                                                    </div>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/blog-content"
                                                    className="nav-link flex items-center justify-between p-2 text-xs+ tracking-wide outline-none transition-[color,padding-right] duration-300 ease-in-out hover:pr-4">
                                                    <div className="flex items-center space-x-2 space-x-reverse">
                                                        <div className="h-1.5 w-1.5 rounded-full border border-current opacity-40"></div>
                                                        <span className="dark:text-white">יצירת תוכן לבלוג</span>
                                                    </div>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/blog-conclusion"
                                                    className="nav-link flex items-center justify-between p-2 text-xs+ tracking-wide outline-none transition-[color,padding-right] duration-300 ease-in-out hover:pr-4">
                                                    <div className="flex items-center space-x-2 space-x-reverse">
                                                        <div className="h-1.5 w-1.5 rounded-full border border-current opacity-40"></div>
                                                        <span className="dark:text-white">יצירת סיכום לבלוג</span>
                                                    </div>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/freestyle"
                                                    className="nav-link flex items-center justify-between p-2 text-xs+ tracking-wide outline-none transition-[color,padding-right] duration-300 ease-in-out hover:pr-4">
                                                    <div className="flex items-center space-x-2 space-x-reverse">
                                                        <div className="h-1.5 w-1.5 rounded-full border border-current opacity-40"></div>
                                                        <span className="dark:text-white">פריסטייל</span>
                                                    </div>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/video-scripts"
                                                    className="nav-link flex items-center justify-between p-2 text-xs+ tracking-wide outline-none transition-[color,padding-right] duration-300 ease-in-out hover:pr-4">
                                                    <div className="flex items-center space-x-2 space-x-reverse" >
                                                        <div className="h-1.5 w-1.5 rounded-full border border-current opacity-40"></div>
                                                        <span className="dark:text-white">תסריט לסרטון תוכן</span>
                                                    </div>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/product-description"
                                                    className="nav-link flex items-center justify-between p-2 text-xs+ tracking-wide outline-none transition-[color,padding-right] duration-300 ease-in-out hover:pr-4">
                                                    <div className="flex items-center space-x-2 space-x-reverse">
                                                        <div className="h-1.5 w-1.5 rounded-full border border-current opacity-40"></div>
                                                        <span className="dark:text-white">תיאור מוצר</span>
                                                    </div>
                                                </Link>
                                            </li>
                                        </ul>
                                    </li>

                                </ul>
                                <div className="my-3 mx-4 h-px bg-slate-200 dark:bg-navy-500"></div>
                                <ul className="flex flex-1 flex-col px-4 font-inter text-lg">
                                <li>
                                        <NavLink to="/projects" 
                                            className={`cursor-pointer flex py-4 items-center tracking-wide outline-none transition-colors duration-300 ease-in-out hover:text-slate-800 dark:hover:text-navy-50`}>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1.5">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z"></path>
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z"></path>
                                            </svg>  פרויקטים</NavLink>
                                    </li>
                                    
                                    <li>
                                        <NavLink to="/billing" 
                                            className={` cursor-pointer flex py-4 items-center tracking-wide outline-none transition-colors duration-300 ease-in-out hover:text-slate-800 dark:hover:text-navy-50`}>
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                className="h-5.5 w-5.5 text-slate-500 ml-2 dark:text-navy-100"
                                                fill="none" viewBox="0 0 24 24" stroke="currentColor" >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="1.5"
                                                    d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                                                />
                                            </svg>הגדרות</NavLink>
                                    </li>
                                </ul>
                            </div>
                            {/* <Link to="/billing" className="flex py-10 items-center justify-between cursor-pointer h-20 z-30 relative">
                                <button className="cursor-pointer flex items-center h-full text-left w-full focus:outline-none focus:ring-0" type="button">
                                    <img src={`https://ui-avatars.com/api/?name=${user.username}&amp;color=5E7682&amp;background=F8FBFC&amp;size=100`} alt="avatar" className="rounded-full border border-green-800 shadow h-8 w-8 mr-2" />
                                    <div className="flex flex-col flex-grow">
                                        <span className="text-md font-bold text-secondary line-clamp-1">{user.username}</span>
                                        <span className="text-sm font-light text-black line-clamp-1">{user.plan}</span>
                                    </div>
                                </button>
                            </Link> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Sidebar