const Spinner = () => {
    return (
        <div className="flex flex-col justify-center items-center">
            {/* <div className="spinner is-grow relative h-16 w-16 mt-4">
                <span className="absolute inline-block h-full w-full rounded-full bg-secondary opacity-75 dark:bg-accent"
                ></span>
                <span className="absolute inline-block h-full w-full rounded-full bg-secondary opacity-75 dark:bg-accent"
                ></span>
            </div> */}
            <img alt="loading gif" src="/assets/images/loading.gif" />
            <p className="text-sm text-gray/50 mt-4 mb-12">בבקשה להמתין</p>
        </div>
    )
}
export default Spinner