import { Link } from "react-router-dom";

const Features = () => {
  return (
    <>
      <section className="bg-gradient-to-b from-white/[55%] to-transparent py-14 dark:bg-none lg:py-20">
        <div className="container">
          <div className="mt-16 grid gap-x-7 gap-y-12 text-lg font-semibold sm:grid-cols-1 lg:grid-cols-2 lg:gap-y-20">
            <div
              className="flex gap-5"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <div className="flex-1">
                <img src="/assets/gif/writings.gif" alt="gif item" />
              </div>
            </div>

            <div
              className="flex gap-5"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <div className="flex-1 rounded-lg p-4 border border-gray/20">
                <div className="grid grid-cols-2 gap-2">
                  <div className="px-2 flex flex-col justify-between">
                    <ul>
                      <li>
                        <h3 className="mb-6 text-[22px] font-extrabold text-black dark:text-white">
                          עוזר הכתיבה הפרטי שלך
                        </h3>
                      </li>
                      <li>
                        <h3 className="mb-6 text-[22px] font-extrabold text-black dark:text-white my-6">
                          תבניות ליצירת תוכן מגבש ומעניין
                        </h3>
                      </li>
                      <li>
                        <h3 className="mb-6 text-[22px] font-extrabold text-black dark:text-white mb-6">
                          ידע רב תחומי
                        </h3>
                      </li>
                      <li>
                        <h3 className="mb-6 text-[22px] font-extrabold text-black dark:text-white">
                          אלפי פעולות כתיבה לבקשתך
                        </h3>
                      </li>
                    </ul>
                    <Link
                      to="/sign-up"
                      className="w-full admin-btn bg-secondary font-medium text-white hover:bg-secondary-focus focus:bg-secondary-focus active:bg-secondary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                    >
                      נסה/י עכשיו!
                    </Link>
                  </div>
                  <div className="px-2 border-r border-gray/20">
                    <p className="">
                      תוכנן במטרה ראשונה על מנת לספק תוכן איכותי מבוסס AI בעברית
                    </p>
                    <p className="my-6">
                      מנוע ידע מתקדם בעל ידע רב-תחומי ליצירת תוכן איכותי, עדכני
                      ומרתק
                    </p>
                    <p className="">
                      תוכנן כדי להיות יצירתי. המודל של דודה יכול לספק תוכן בכל
                      נושא, רעיונות לתוכן או טקסט מאורגן ומסודר, תוכן לפרסומות
                      ועוד אלפי אפשרויות תוכן כתוב על פי בקשתך לעסק שלך.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Features;
