import axios from 'axios'
axios.defaults.withCredentials = true;

// Create new goal
const saveHistory = async (historyData, token) => {
    const response = await axios.post(process.env.REACT_APP_BACKEND_API, historyData)
    return response.data
}

// Get user history
const getHistories = async (token) => {
    const response = await axios.get(process.env.REACT_APP_BACKEND_API + "history")
    return response.data
}

// Get one history
export const getOneHistory = async (id, token) => {
    const response = await axios.get(process.env.REACT_APP_BACKEND_API + "history/" + id)
    return response.data
}
// update history
const updateHistory = async (token, id, data) => {
    const response = await axios.put(process.env.REACT_APP_BACKEND_API + id, data);
    console.log(response.data)
}
// Delete user goal
const deleteHistory = async (id, token) => {
    const response = await axios.delete(process.env.REACT_APP_BACKEND_API + id)
    console.log(response.data)
    return response.data
}

const historyService = {
    saveHistory, getHistories,
    updateHistory, deleteHistory
}

export default historyService