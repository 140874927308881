import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import historyService from './historyService'

const initialState = {
    histories: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',
}

// Create new history
export const saveHistory = createAsyncThunk(
    'history/create',
    async (historyData, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await historyService.saveHistory(historyData, token)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)
// updateHistory
export const putHistory = createAsyncThunk(
    'history/PUT',
    async (historyData, id, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await historyService.updateHistory(token, id, historyData)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Get user histories
export const getHistories = createAsyncThunk(
    'history/getAll',
    async (_, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await historyService.getHistories(token)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Delete user goal
export const deleteHistory = createAsyncThunk(
    'history/delete',
    async (id, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await historyService.deleteHistory(id, token)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const historySlice = createSlice({
    name: 'history',
    initialState,
    reducers: {
        reset: (state) => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(saveHistory.pending, (state) => {
                state.isLoading = true
            })
            .addCase(saveHistory.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.histories.push(action.payload)
            })
            .addCase(saveHistory.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(putHistory.pending, (state) => {
                state.isLoading = true
            })
            .addCase(putHistory.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.histories.push(action.payload)
            })
            .addCase(putHistory.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(getHistories.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getHistories.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.histories = action.payload
            })
            .addCase(getHistories.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(deleteHistory.pending, (state) => {
                state.isLoading = true
            })
            .addCase(deleteHistory.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.histories = state.histories.filter(
                    (history) => history._id !== action.payload.id
                )
                state.message = action.payload.message
            })
            .addCase(deleteHistory.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
    },
})

export const { reset } = historySlice.actions
export default historySlice.reducer