import { useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getResult, reset } from "../../redux/openai/openaiSlice";
import { Helmet } from "react-helmet-async";

import Layout from "../components/layouts/Layout"
import Results from "../components/sections/Results";
import { RemainingWordsContext } from "../../App";
import Spinner from "../components/layouts/Spinner";


const ExplainToChild = () => {
    const { setUserWords } = useContext(RemainingWordsContext);

    const [formData, setFormData] = useState({
        plot: "", age: 8
    });
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }
    const dispatch = useDispatch();
    const { plot, age } = formData;

    useEffect(() => {
        dispatch(reset());
    }, [dispatch])

    // const { user } = useSelector((state) => state.auth)
    const { data, isLoading } = useSelector(
        (state) => state.openai
    )

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        let prompt = `Please Explain the following text [${plot}] to a [${age}] year old. `;
        let Newprompt = { ...formData, prompt: prompt, project: "Bab" }
        dispatch(getResult(Newprompt));
        setUserWords(data.remainingWords);
    }
    return (
        <>
            <Helmet>
                <title>להסביר את זה לילד</title>
            </Helmet>
            <Layout>
                <div className="min-h-100vh flex grow bg-white dark:bg-navy-900">
                    <main className="main-content w-full px-[var(--margin-x)] pb-8">
                        <div className="flex flex-col items-center justify-between space-y-4 py-5 sm:flex-row sm:space-y-0 lg:py-6"
                        >
                            <div className="flex items-center space-x-1 space-x-reverse">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6"
                                    fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor">
                                    <path
                                        strokeLinecap="round" strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                                    />
                                </svg>
                                <h2 className="text-xl font-medium text-slate-700 line-clamp-1 dark:text-navy-50">
                                    להסביר את זה לילד
                                </h2>
                            </div>
                        </div>
                        <div className="grid grid-cols-12 gap-4 sm:gap-5 lg:gap-6">
                            <div className="col-span-12 lg:col-span-6">
                                <div className="card">
                                    <div className="tabs flex flex-col">
                                        <div className="is-scrollbar-hidden overflow-x-auto">
                                            <div className="border-b-2 border-slate-150 dark:border-navy-500">
                                                <div className="tabs-list -mb-0.5 flex">
                                                    <button className="admin-btn h-14 shrink-0 space-x-2 space-x-reverse rounded-none border-b-2 border-secondary px-4 font-medium text-secondary dark:border-accent dark:text-accent-light sm:px-5">
                                                        <i className="fa-solid fa-layer-group text-base"></i>
                                                        <span>כללי</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <form onSubmit={handleSubmit}>
                                            <div className="p-4 sm:p-5">
                                                <div className="space-y-5">
                                                    <label className="block">
                                                        <span className="font-medium text-slate-600 dark:text-navy-100">טקסט:</span>
                                                        <textarea rows="8" placeholder='הזרם העובר בתוך סלילי הרוטור מכוון כך, שסביב הסלילים המסתובבים ייווצר שדה אלקטרומגנטי בעל קוטביות משתנה, כך שאותו קוטב (למשל דרום) מכוון לכיוון המגנטים שבסטטור. במצב זה המגנט "הדרומי" דוחה את הסליל הקרוב אליו (דרום דוחה דרום) ואילו המגנט "הצפוני" מושך את הסליל הקרוב אליו.' name="plot" value={plot} onChange={handleChange}
                                                            className="form-textarea w-full resize-none rounded-lg border border-slate-300 bg-transparent p-2.5 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-secondary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                                            required></textarea>
                                                    </label>
                                                    <label className="block">
                                                        <span className="font-medium text-slate-600 dark:text-navy-100">:ילד בגיל</span>
                                                        <input type="number" placeholder="8" name="age" value={age} onChange={handleChange}
                                                            className="form-textarea w-full resize-none rounded-lg border border-slate-300 bg-transparent p-2.5 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-secondary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                                            required />
                                                    </label>
                                                    {<div className="flex justify-center space-x-2 space-x-reverse ">
                                                        <button className="w-full admin-btn bg-secondary font-medium text-white hover:bg-secondary-focus focus:bg-secondary-focus active:bg-secondary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                                                        > ליצור </button>
                                                    </div>}
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                {isLoading && <div className="flex justify-center items-center mt-6 w-full">
                                    <Spinner /> </div>}
                            </div>
                            <Results isLoading={isLoading} data={data} />
                        </div>
                    </main>
                </div>
            </Layout>
        </>
    )
}
export default ExplainToChild