import { Link } from "react-router-dom"
import Layout from "../components/layouts/Layout"
import { useSelector } from "react-redux"
import { useState } from "react";
import PayementForm from "../components/essentials/PayementForm";

const Pricing = () => {
    const { user } = useSelector((state) => state.auth);
    const [showModal, setShowModal] = useState(false);
    const [data, setData] = useState({});

    const handleOpen = (dt) => {
        setData(dt);
        setShowModal(true);
    }
    return (
        <>
        <Layout>
            <div className="min-h-100vh flex relative grow bg-white dark:bg-navy-900">
                <main className="main-content w-full px-[var(--margin-x)] pb-8">
                    <div className="flex items-center space-x-4 space-x-reverse py-5 lg:py-6">
                        <h2 className="text-xl font-medium text-slate-800 dark:text-navy-50 lg:text-2xl">
                            הגדרות </h2>
                        <div className="hidden h-full py-1 sm:flex">
                            <div className="h-full w-px bg-slate-300 dark:bg-navy-600"></div>
                        </div>
                    </div>
                    <div className="grid grid-cols-12 gap-4 sm:gap-5 lg:gap-6">
                        <div className="col-span-12 lg:col-span-4">
                            <div className="card p-4 sm:p-5">
                                <div className="flex items-center space-x-4 space-x-reverse">
                                    <div>
                                        <p className="text-md">{user.email}</p>
                                    </div>
                                </div>
                                <ul className="mt-6 space-y-1.5 font-inter font-medium">
                                    <li>
                                        <Link to="/billing" className=" flex items-center space-x-2 space-x-reverse rounded-lg bg-secondary px-4 py-2.5 tracking-wide text-white outline-none transition-all dark:bg-accent">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-slate-400 transition-colors group-hover:text-slate-500 group-focus:text-slate-500 dark:text-navy-300 dark:group-hover:text-navy-200 dark:group-focus:text-navy-200" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1.5">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"></path>
                                            </svg>
                                            <span>תשלום</span>
                                        </Link>
                                    </li>

                                    <li>
                                        <Link to="/privacy-policy" className="group flex space-x-2 space-x-reverse rounded-lg px-4 py-2.5 tracking-wide outline-none transition-all hover:bg-slate-100 hover:text-slate-800 focus:bg-slate-100 focus:text-slate-800 dark:hover:bg-navy-600 dark:hover:text-navy-100 dark:focus:bg-navy-600 dark:focus:text-navy-100" href="#">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-slate-400 transition-colors group-hover:text-slate-500 group-focus:text-slate-500 dark:text-navy-300 dark:group-hover:text-navy-200 dark:group-focus:text-navy-200" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1.5">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"></path>
                                            </svg>
                                            <span>שימוש תנאי </span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-span-12 lg:col-span-8">
                            <div className="card">
                                <div className="flex flex-col items-center space-y-4 border-b border-slate-200 p-4 dark:border-navy-500 sm:flex-row sm:justify-between sm:space-y-0 sm:px-5">
                                    <h2 className="text-lg font-medium tracking-wide text-slate-700 dark:text-navy-100">
                                        לשדרוג התכנית
                                    </h2>
                                </div>
                                <div className="p-4 sm:p-5">
                                    <div className="grid max-w-4xl grid-cols-1 gap-4 md:grid-cols-2 sm:gap-5 lg:gap-6"
                                    >
                                        <div className="rounded-xl bg-gradient-to-br from-pink-500 to-rose-500 p-1"
                                        >
                                            <div className="rounded-xl bg-slate-50 p-4 text-center dark:bg-navy-900 sm:p-5"
                                            >
                                                <div className="mt-8">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="inline h-16 w-16 text-secondary dark:text-secondary-light"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        stroke="currentColor"
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth="1"
                                                            d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                                                        />
                                                    </svg>
                                                </div>
                                                <div className="mt-5">
                                                    <h4 className="text-xl font-semibold text-slate-700 dark:text-navy-100"
                                                    > פרו
                                                    </h4>
                                                </div>
                                                <div className="mt-5">
                                                    <span className="text-4xl tracking-tight text-secondary dark:text-secondary-light"
                                                    >$5</span>/month
                                                </div>
                                                <div className="mt-8 space-y-4 text-right">
                                                    <div className="flex items-start space-x-3 space-x-reverse">
                                                        <div className="flex h-6 w-6 shrink-0 items-center justify-center rounded-full">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                className="h-4.5 w-4.5"
                                                                viewBox="0 0 20 20"
                                                                fill="currentColor"
                                                            >
                                                                <path
                                                                    fillRule="evenodd"
                                                                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                                    clipRule="evenodd"
                                                                />
                                                            </svg>
                                                        </div>
                                                        <span className="font-medium"> מושלם לפרילאנסרים ובעלי מותגי פרסום קטנים</span>
                                                    </div>
                                                    <div className="flex items-start space-x-3 space-x-reverse">
                                                        <div className="flex h-6 w-6 shrink-0 items-center justify-center rounded-full"
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                className="h-4.5 w-4.5"
                                                                viewBox="0 0 20 20"
                                                                fill="currentColor"
                                                            >
                                                                <path
                                                                    fillRule="evenodd"
                                                                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                                    clipRule="evenodd"
                                                                />
                                                            </svg>
                                                        </div>
                                                        <span className="font-medium">ליצור מודעות טקסט לפייסבוק, אינסטגרם וטיקטוק במספר רגעים
                                                        </span>
                                                    </div>
                                                    <div className="flex items-start space-x-3 space-x-reverse">
                                                        <div className="flex h-6 w-6 shrink-0 items-center justify-center rounded-full"
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                className="h-4.5 w-4.5"
                                                                viewBox="0 0 20 20"
                                                                fill="currentColor"
                                                            >
                                                                <path
                                                                    fillRule="evenodd"
                                                                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                                    clipRule="evenodd"
                                                                />
                                                            </svg>
                                                        </div>
                                                        <span className="font-medium">צור בלוגים ותכני טקסט מתקדמים</span>
                                                    </div>
                                                </div>
                                                <div className="mt-8">
                                                    <button onClick={()=>handleOpen({amount:25})} className="admin-btn rounded-full bg-secondary font-medium text-white hover:bg-secondary-focus focus:bg-secondary-focus active:bg-secondary-focus/90">  79 ש״ח בחודש
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="rounded-xl bg-gradient-to-br from-amber-400 to-orange-600 p-1"
                                        >
                                            <div className="rounded-xl bg-slate-50 p-4 text-center dark:bg-navy-900 sm:p-5"
                                            >
                                                <div className="mt-8">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="inline h-16 w-16 text-warning"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        stroke="currentColor"
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth="1"
                                                            d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                                                        />
                                                    </svg>
                                                </div>
                                                <div className="mt-5">
                                                    <h4 className="text-xl font-semibold text-slate-700 dark:text-navy-100"
                                                    > לעסקים</h4>
                                                </div>
                                                <div className="mt-5">
                                                    <span className="text-4xl tracking-tight text-warning">$25</span
                                                    >/month
                                                </div>
                                                <div className="mt-8 space-y-4 text-left">
                                                    <div className="flex items-start space-x-3 space-x-reverse">
                                                        <div
                                                            className="flex h-6 w-6 shrink-0 items-center justify-center rounded-full"
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                className="h-4.5 w-4.5"
                                                                viewBox="0 0 20 20"
                                                                fill="currentColor"
                                                            >
                                                                <path
                                                                    fillRule="evenodd"
                                                                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                                    clipRule="evenodd"
                                                                />
                                                            </svg>
                                                        </div>
                                                        <span className="font-medium">כל התכונות מה-פרו כלולות</span>
                                                    </div>

                                                    <div className="flex items-start space-x-3 space-x-reverse">
                                                        <div
                                                            className="flex h-6 w-6 shrink-0 items-center justify-center rounded-full"
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                className="h-4.5 w-4.5"
                                                                viewBox="0 0 20 20"
                                                                fill="currentColor"
                                                            >
                                                                <path
                                                                    fillRule="evenodd"
                                                                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                                    clipRule="evenodd"
                                                                />
                                                            </svg>
                                                        </div>
                                                        <span className="font-medium"> גישה למספר משתמשים</span>
                                                    </div>

                                                    <div className="flex items-start space-x-3 space-x-reverse">
                                                        <div
                                                            className="flex h-6 w-6 shrink-0 items-center justify-center rounded-full"
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                className="h-4.5 w-4.5"
                                                                viewBox="0 0 20 20"
                                                                fill="currentColor"
                                                            >
                                                                <path
                                                                    fillRule="evenodd"
                                                                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                                    clipRule="evenodd"
                                                                />
                                                            </svg>
                                                        </div>
                                                        <span className="font-medium">מושלם עבור סוכנויות, צוותי שיווק ועסקים גדולים</span>
                                                    </div>
                                                </div>
                                                <div className="mt-8">
                                                    <button onClick={()=>handleOpen({amount:40})}  className="admin-btn rounded-full bg-warning font-medium text-white hover:bg-warning-focus focus:bg-warning-focus active:bg-warning-focus/90"
                                                    > לקבלת הצעת מחיר
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="my-7 h-px bg-slate-200 dark:bg-navy-500"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </Layout>
        <PayementForm showModal={showModal} setShowModal={setShowModal} data={data} />
        </>
        
    )
}
export default Pricing