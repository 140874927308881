import Header from './Header';
import Footer from './Footer';
import Sidebar from './Sidebar';
import { useEffect, useState } from 'react';


function Layout({ children }) {
    const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth > 768);

    useEffect(() => {
        const handleResize = () => {
            setIsSidebarOpen(window.innerWidth > 768);
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    function toggleSidebar() {
        setIsSidebarOpen((prevIsSidebarOpen) => !prevIsSidebarOpen);
    }
    return (
        <div className={`${isSidebarOpen ? 'is-sidebar-open' : ''}`}>
            <Header toggleSidebar={toggleSidebar} />
            <Sidebar toggleSidebar={toggleSidebar} />
            {children}
            {/* <Footer /> */}
        </div>
    );
}

export default Layout;
