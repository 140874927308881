import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import chatService from './chatService'

const initialState = {
    conversation: null,
    conversations: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',
}

// Create new history
export const saveChat = createAsyncThunk(
    'chat/create',
    async ({ chatData, conversId }, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await chatService.saveChat(chatData, conversId, token)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Get user conversations
export const getUserChat = createAsyncThunk(
    'chat/getAll',
    async (_, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await chatService.getChat(token)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Delete user goal
export const deleteChat = createAsyncThunk(
    'chat/delete',
    async (id, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await chatService.deleteChat(id, token)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const chatSlice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        reset: (state) => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(saveChat.pending, (state) => {
                state.isLoading = true
            })
            .addCase(saveChat.fulfilled, (state, action) => {
                state.conversation = action.payload;
                state.isLoading = false
                state.isSuccess = true
                // state.conversations.push(action.payload)
            })
            .addCase(saveChat.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })

            .addCase(getUserChat.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getUserChat.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.conversations = action.payload
            })
            .addCase(getUserChat.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(deleteChat.pending, (state) => {
                state.isLoading = true
            })
            .addCase(deleteChat.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.conversations = state.conversations.filter(
                    (history) => history._id !== action.payload.id
                )
                state.message = action.payload.message
            })
            .addCase(deleteChat.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
    },
})

export const { reset } = chatSlice.actions
export default chatSlice.reducer