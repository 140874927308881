import { useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getResult, reset } from "../../redux/openai/openaiSlice";
import { Helmet } from "react-helmet-async";

import Layout from "../components/layouts/Layout"
import Results from "../components/sections/Results";
import { RemainingWordsContext } from "../../App";
import Spinner from "../components/layouts/Spinner";
import { fetchAuthUser } from "../../redux/auth/authService";
import MainTitle from "../components/layouts/MainTitle";


const Policies = () => {
    const { setUserWords } = useContext(RemainingWordsContext);

    const [formData, setFormData] = useState({
        brand: "", item: "", type: "מדיניות פרטיות",
    });
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }
    const dispatch = useDispatch();
    const { brand, item, type } = formData;

    useEffect(() => {
        dispatch(reset());
    }, [dispatch])


    const getUserWords = async () => {
        const currentUser = await fetchAuthUser();
         setUserWords(currentUser.words);
       }
    const { data, isLoading } = useSelector(
        (state) => state.openai
    )
    useEffect(() => {
        if (!isLoading) { 
            getUserWords();
        }
    }, [data, isLoading]); 

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        let prompt = `Generate a ${type} for my brand "${brand}". The item I'm selling is: ${item}`;
        let Newprompt = { ...formData, prompt: prompt, project: "מדיניות" }
        dispatch(getResult(Newprompt));
    }
    return (
        <>
            <Helmet>
                <title>Doda.ai | מדיניות</title>
            </Helmet>
            <Layout>
                <div className="min-h-100vh flex grow bg-white dark:bg-navy-900">
                    <main className="main-content w-full px-[var(--margin-x)] pb-8">
                        <MainTitle title="מדיניות" />
                        <div className="grid grid-cols-12 gap-4 sm:gap-5 lg:gap-6">
                            <div className="col-span-12 lg:col-span-6">
                                <div className="card">
                                    <div className="tabs flex flex-col">
                                        <div className="is-scrollbar-hidden overflow-x-auto">
                                            <div className="border-b-2 border-slate-150 dark:border-navy-500">
                                                <div className="tabs-list -mb-0.5 flex">
                                                    <button className="admin-btn h-14 shrink-0 space-x-2 space-x-reverse rounded-none border-b-2 border-secondary px-4 font-medium text-secondary dark:border-accent dark:text-accent-light sm:px-5">
                                                        <i className="fa-solid fa-layer-group text-base"></i>
                                                        <span>כללי</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <form onSubmit={handleSubmit}>
                                            <div className="p-4 sm:p-5">
                                                <div className="space-y-5">
                                                    <label className="block">
                                                        <span className="font-medium text-slate-600 dark:text-navy-100">שם</span>
                                                        <input type="text" placeholder="מותג" name="brand"
                                                         value={brand} onChange={handleChange}
                                                            className="form-textarea w-full resize-none rounded-lg border border-slate-300 bg-transparent p-2.5 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-secondary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                                            required />
                                                    </label>
                                                    <label className="block">
                                                        <span className="font-medium text-slate-600 dark:text-navy-100">מה העסק מוכר?</span>
                                                        <input type="text" placeholder="משקה אנרגיה" name="item" value={item} onChange={handleChange}
                                                            className="form-textarea w-full resize-none rounded-lg border border-slate-300 bg-transparent p-2.5 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-secondary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                                            required />
                                                    </label>
                                                    <label className="block">
                                                        <span className="font-medium text-slate-600 dark:text-navy-100">סוג מדיניות</span>
                                                        <select name="type" value={type} onChange={handleChange} className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-secondary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent">
                                                            <option value="מדיניות פרטיות">מדיניות פרטיות</option>
                                                            <option value="תנאי שימוש">תנאי שימוש</option>
                                                        </select>
                                                    </label>
                                                    {<div className="flex justify-center space-x-2 space-x-reverse ">
                                                        <button className="w-full admin-btn bg-secondary font-medium text-white hover:bg-secondary-focus focus:bg-secondary-focus active:bg-secondary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                                                        > ליצור </button>
                                                    </div>}
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                {isLoading && <div className="flex justify-center items-center mt-6 w-full">
                                    <Spinner /> </div>}
                            </div>
                            <Results isLoading={isLoading} data={data} />
                        </div>
                    </main>
                </div>
            </Layout>
        </>
    )
}
export default Policies