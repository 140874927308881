import { Link } from "react-router-dom";
import Header from "../components/layouts/Header";
import Footer from "../components/layouts/Footer";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from "react";

const AboutUs = () => {

    useEffect(() => {
        AOS.init({
            once: true,
        });
    }, []);
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, []);
    return (
        <>
            <div className="font-assistant bg-white font-mulish text-base text-gray antialiased">
                <Header className='sticky-header' />
                <div style={{ backgroundImage: "url(/assets/images/inner-page-hero-bg.png)" }} className=" bg-cover bg-bottom bg-no-repeat pt-[82px] lg:pt-[106px]">
                    <div className="relative">
                        <div className="container">
                            <div className="items-center justify-between py-10 md:flex md:h-[400px] md:py-0">
                                <div className="heading relative mb-0 text-center ltr:md:text-left rtl:md:text-right">
                                    <h6>על דודה</h6>
                                    <h4 className="!text-white">על דודה</h4>
                                </div>
                                <div className="relative mt-6 md:mt-0" data-aos='fade-right' data-aos-duration="1000">
                                    <svg
                                        width="374"
                                        height="282"
                                        viewBox="0 0 374 282"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="mx-auto w-[90%] rtl:rotate-y-180 sm:w-auto md:mx-auto"
                                    >
                                        <path
                                            d="M337.158 118.514C332.708 118.514 331.033 96.8514 335.485 96.5051C311.417 99.0664 286.706 103.003 269.907 100.334C264.009 107.607 259.198 115.697 255.623 124.351C253.353 116.205 252.527 107.724 253.183 99.2934C249.987 99.0332 215.114 75.9104 200.431 67.3223C197.247 69.4759 194.535 72.2536 192.459 75.4871C190.381 78.7205 188.984 82.3422 188.348 86.1324L239.81 117.44L243.863 111.603C242.916 113.934 242.385 116.413 242.297 118.927C242.149 123.984 243.785 131.38 249.88 144.365C255.828 146.149 265.534 143.398 272.93 139.123C272.596 134.476 274.863 128.787 276.574 124.03C275.261 123.069 273.881 122.199 272.447 121.428C294.257 122.864 314.779 121.238 337.158 118.514Z"
                                            fill="#B476E5"
                                        />
                                        <path
                                            d="M155.851 152.679C144.547 151.795 133.207 151.484 121.872 151.75C122.58 149.341 123.004 146.858 123.136 144.352C113.842 146.768 104.844 146.842 92.875 145.134V144.986C87.314 148.664 82.4593 161.708 85.9462 171.296L76.2937 191.681L48.7839 174.129C40.4932 179.297 36.3292 185.915 33.2806 196.064L67.9658 219.819C70.2925 221.416 72.931 222.502 75.7074 223.005C78.484 223.509 81.3358 223.419 84.075 222.74C86.8142 222.063 89.3789 220.813 91.6002 219.072C93.8215 217.332 95.6491 215.14 96.9627 212.644L108.042 191.566L94.0589 180.886C96.1097 182.083 98.3116 182.999 100.606 183.61C112.02 186.77 128.711 185.506 139.937 184.13C136.635 197.264 134.054 210.568 132.205 223.983C139.284 228.746 147.916 230.62 156.332 229.225C162.62 211.603 167.933 193.648 172.246 175.44C172.89 172.849 172.963 170.149 172.458 167.527C171.953 164.907 170.883 162.426 169.323 160.26C167.762 158.094 165.749 156.294 163.423 154.984C161.096 153.675 158.512 152.889 155.851 152.679Z"
                                            fill="#B476E5"
                                        />
                                        <path
                                            d="M318.645 37.3581C318.365 37.0759 318.033 36.8521 317.665 36.6993C317.299 36.5464 316.905 36.4678 316.506 36.4678C316.109 36.4678 315.716 36.5464 315.35 36.6993C314.982 36.8521 314.649 37.0759 314.369 37.3581L313.587 38.1408L312.804 37.3581C312.237 36.7912 311.468 36.4727 310.665 36.4729C309.863 36.4731 309.094 36.7918 308.527 37.3591C307.961 37.9263 307.642 38.6955 307.642 39.4976C307.642 40.2995 307.961 41.0686 308.529 41.6355L313.616 46.6917L318.635 41.6355C318.919 41.3563 319.144 41.0238 319.299 40.657C319.451 40.2903 319.531 39.8966 319.531 39.4988C319.533 39.1012 319.455 38.7071 319.302 38.3396C319.15 37.9721 318.927 37.6387 318.645 37.3581Z"
                                            fill="#B476E5"
                                        />
                                        <path
                                            d="M105.774 254.84L100.606 254.059L98.3011 249.412L95.9961 254.059L90.8303 254.84L94.5853 258.446L93.693 263.577L98.3011 261.16L102.911 263.577L102.019 258.446L105.774 254.84Z"
                                            fill="#47BDFF"
                                        />
                                        <path
                                            d="M261.058 91.8887L257.145 95.6065C257.507 94.5745 257.732 93.4997 257.814 92.4092C258.912 92.3565 260 92.182 261.058 91.8887Z"
                                            fill="#47BDFF"
                                        />
                                        <path
                                            d="M277.343 121.676C277.083 122.42 276.823 123.201 276.525 124.024C275.212 123.062 273.833 122.193 272.398 121.422C274.071 121.49 275.707 121.602 277.343 121.676Z"
                                            fill="#47BDFF"
                                        />
                                        <path
                                            d="M307.604 59.9633C301.514 54.9071 285.001 69.1091 285.001 69.1091C288.048 54.0892 265.743 66.9567 258.233 74.2769C262.1 71.1911 265.185 77.8812 264.851 81.6734C264.851 82.5676 265.334 82.7535 266.71 81.7868C269.572 79.7049 271.617 84.6496 266.226 86.9526C265.864 87.3442 265.598 87.8124 265.444 88.3224C264.456 91.1281 264.391 94.1765 265.258 97.022C290.279 91.8993 313.664 64.9824 307.604 59.9633Z"
                                            fill="#47BDFF"
                                        />
                                        <path d="M264.628 174.019L261.653 176.584L259.051 165.542L264.628 174.019Z" fill="#47BDFF" />
                                        <path
                                            d="M182.952 1.00002C179.972 1.00002 177.059 1.88368 174.581 3.53928C172.103 5.19488 170.172 7.54786 169.032 10.301C167.891 13.0541 167.593 16.0835 168.175 19.0063C168.756 21.9289 170.191 24.6137 172.298 26.7208C174.405 28.828 177.09 30.2628 180.012 30.8442C182.935 31.4257 185.965 31.1273 188.718 29.9869C191.471 28.8466 193.823 26.9153 195.479 24.4376C197.134 21.9598 198.019 19.0468 198.019 16.0669C198.023 14.0873 197.635 12.1264 196.88 10.2969C196.123 8.46714 195.013 6.80469 193.614 5.40503C192.213 4.00518 190.552 2.89551 188.722 2.13963C186.893 1.38374 184.931 0.996502 182.952 1.00002ZM183.324 25.2401C183.324 25.3387 183.284 25.4332 183.215 25.5031C183.145 25.5727 183.05 25.6119 182.952 25.6119C182.902 25.6148 182.853 25.607 182.806 25.5894C182.76 25.5718 182.718 25.5444 182.683 25.5093C182.648 25.4741 182.62 25.432 182.603 25.3857C182.585 25.3393 182.577 25.2896 182.58 25.2401V13.5662C182.581 13.4678 182.62 13.3735 182.69 13.3038C182.759 13.2343 182.853 13.195 182.952 13.1944C183.001 13.1915 183.051 13.1993 183.097 13.2169C183.144 13.2345 183.186 13.2619 183.221 13.297C183.256 13.3322 183.284 13.3742 183.301 13.4206C183.319 13.467 183.327 13.5167 183.324 13.5662V25.2401ZM181.911 7.91709C181.923 7.64393 182.039 7.38584 182.237 7.19682C182.434 7.0076 182.697 6.90213 182.97 6.90213C183.244 6.90213 183.507 7.0076 183.704 7.19682C183.901 7.38584 184.018 7.64393 184.03 7.91709C184.018 8.19025 183.901 8.44835 183.704 8.63737C183.507 8.82658 183.244 8.93205 182.97 8.93205C182.697 8.93205 182.434 8.82658 182.237 8.63737C182.039 8.44835 181.923 8.19025 181.911 7.91709Z"
                                            fill="#47BDFF"
                                        />
                                        <path d="M93.8395 180.749L108.041 191.568L111.35 185.322C105.239 185.165 99.2471 183.6 93.8395 180.749Z" fill="#47BDFF" />
                                        <path
                                            d="M106.109 98.8112L101.647 95.8018C102.93 95.9559 104.223 96.0058 105.514 95.9505C105.616 96.9216 105.815 97.88 106.109 98.8112Z"
                                            fill="#47BDFF"
                                        />
                                        <path d="M243.81 111.602C242.863 113.932 242.333 116.411 242.245 118.926L239.754 117.401L243.81 111.602Z" fill="#47BDFF" />
                                        <path
                                            d="M125.143 111.861C125.475 113.482 125.711 115.12 125.85 116.769C126.556 116.434 127.337 116.025 128.118 115.616L125.143 111.861Z"
                                            fill="#47BDFF"
                                        />
                                        <path
                                            d="M135.292 227.032C134.438 229.486 133.358 231.94 132.617 234.094C133.194 236.567 134.093 238.955 135.292 241.195C139.047 248.147 142.802 261.792 145.776 265.212C147.412 260.342 149.159 248.112 150.311 241.232C144.509 237.419 139.424 232.613 135.292 227.032Z"
                                            fill="#47BDFF"
                                        />
                                        <path
                                            d="M36.3294 171.194C33.7933 171.082 31.2538 171.321 28.7823 171.9C21.0492 173.685 6.88639 173.685 2.83398 175.655C7.07228 178.555 18.4096 183.5 24.7299 186.399C29.9547 181.791 35.9602 178.152 42.4638 175.655C40.2703 174.168 38.1883 172.495 36.3294 171.194Z"
                                            fill="#47BDFF"
                                        />
                                        <path
                                            d="M102.391 75.061C101.164 73.0534 98.4774 73.1649 98.7475 74.6892C97.0764 73.2021 94.1765 73.9065 94.5092 75.6187C92.5035 73.9828 90.1613 75.284 90.6427 76.8455C88.8972 75.5071 87.0012 76.8455 87.596 78.4442C85.1051 77.366 83.0231 79.2269 85.9602 82.162C87.2242 83.426 92.7246 87.5137 95.8104 92.6814C96.0002 91.938 95.9826 91.1571 95.7599 90.4229C95.537 89.689 95.1173 89.0299 94.5463 88.5175C91.4977 86.2124 93.5797 83.4632 95.513 85.3573C97.2232 87.0303 96.6675 84.5746 95.4386 82.3831C95.6932 82.6026 95.999 82.7547 96.3278 82.8249C96.6565 82.8954 96.9977 82.8819 97.3198 82.7858C97.6421 82.6897 97.9348 82.5142 98.1714 82.2751C98.4078 82.0362 98.5804 81.7417 98.6731 81.4184C98.9169 81.6026 99.1979 81.7315 99.4965 81.7959C99.7953 81.8603 100.104 81.8589 100.402 81.7916C100.7 81.7243 100.98 81.593 101.222 81.4065C101.464 81.22 101.663 80.9831 101.804 80.712C103.174 81.6767 105.038 81.0838 105.113 79.6339C104.287 78.2172 103.358 76.6557 102.391 75.061Z"
                                            fill="#47BDFF"
                                        />
                                        <path
                                            d="M92.73 124.721L90.018 128.216C89.6462 126.768 89.2744 125.353 88.8655 123.979C90.1237 124.203 92.73 124.721 92.73 124.721Z"
                                            fill="#47BDFF"
                                        />
                                        <path
                                            d="M54.805 115.541C54.792 115.867 54.6535 116.176 54.4179 116.402C54.1825 116.628 53.8685 116.755 53.5419 116.755C53.2153 116.755 52.9013 116.628 52.6659 116.402C52.4305 116.176 52.2917 115.867 52.2788 115.541C52.2917 115.214 52.4305 114.905 52.6659 114.679C52.9013 114.453 53.2153 114.326 53.5419 114.326C53.8685 114.326 54.1825 114.453 54.4179 114.679C54.6535 114.905 54.792 115.214 54.805 115.541Z"
                                            fill="#47BDFF"
                                        />
                                        <path
                                            d="M71.7583 104.389C71.7583 104.724 71.6253 105.046 71.3881 105.283C71.1512 105.52 70.8295 105.653 70.4943 105.653C70.1591 105.653 69.8376 105.52 69.6004 105.283C69.3635 105.046 69.2302 104.724 69.2302 104.389C69.2302 104.054 69.3635 103.732 69.6004 103.495C69.8376 103.258 70.1591 103.125 70.4943 103.125C70.8295 103.125 71.1512 103.258 71.3881 103.495C71.6253 103.732 71.7583 104.054 71.7583 104.389Z"
                                            fill="#47BDFF"
                                        />
                                        <path
                                            d="M88.0044 116.806C88.0044 117.141 87.8712 117.463 87.6342 117.7C87.3972 117.937 87.0756 118.07 86.7404 118.07C86.4052 118.07 86.0837 117.937 85.8465 117.7C85.6096 117.463 85.4763 117.141 85.4763 116.806C85.4763 116.471 85.6096 116.149 85.8465 115.912C86.0837 115.675 86.4052 115.542 86.7404 115.542C87.0756 115.542 87.3972 115.675 87.6342 115.912C87.8712 116.149 88.0044 116.471 88.0044 116.806Z"
                                            fill="#47BDFF"
                                        />
                                        <path
                                            d="M110.161 120.337C110.161 120.672 110.028 120.994 109.791 121.231C109.554 121.468 109.232 121.601 108.897 121.601C108.562 121.601 108.24 121.468 108.003 121.231C107.766 120.994 107.633 120.672 107.633 120.337C107.633 120.002 107.766 119.681 108.003 119.443C108.24 119.206 108.562 119.073 108.897 119.073C109.232 119.073 109.554 119.206 109.791 119.443C110.028 119.681 110.161 120.002 110.161 120.337Z"
                                            fill="#47BDFF"
                                        />
                                        <path
                                            d="M121.69 101.004C121.69 101.339 121.557 101.661 121.32 101.898C121.083 102.135 120.762 102.268 120.426 102.268C120.091 102.268 119.77 102.135 119.533 101.898C119.296 101.661 119.162 101.339 119.162 101.004C119.162 100.669 119.296 100.348 119.533 100.11C119.77 99.8735 120.091 99.7402 120.426 99.7402C120.762 99.7402 121.083 99.8735 121.32 100.11C121.557 100.348 121.69 100.669 121.69 101.004Z"
                                            fill="#47BDFF"
                                        />
                                        <path
                                            d="M143.247 100.745C143.247 101.08 143.114 101.401 142.877 101.638C142.64 101.875 142.318 102.009 141.983 102.009C141.648 102.009 141.326 101.875 141.089 101.638C140.852 101.401 140.719 101.08 140.719 100.745C140.719 100.409 140.852 100.088 141.089 99.8507C141.326 99.6137 141.648 99.4805 141.983 99.4805C142.318 99.4805 142.64 99.6137 142.877 99.8507C143.114 100.088 143.247 100.409 143.247 100.745Z"
                                            fill="#47BDFF"
                                        />
                                        <path
                                            d="M150.534 83.6088C150.534 83.944 150.401 84.2655 150.164 84.5026C149.927 84.7396 149.605 84.8728 149.27 84.8728C148.935 84.8728 148.613 84.7396 148.376 84.5026C148.139 84.2655 148.006 83.944 148.006 83.6088C148.006 83.2736 148.139 82.9521 148.376 82.7149C148.613 82.478 148.935 82.3447 149.27 82.3447C149.605 82.3447 149.927 82.478 150.164 82.7149C150.401 82.9521 150.534 83.2736 150.534 83.6088Z"
                                            fill="#47BDFF"
                                        />
                                        <path
                                            d="M100.829 137.697C100.829 138.032 100.696 138.354 100.459 138.591C100.222 138.827 99.9006 138.961 99.5654 138.961C99.2303 138.961 98.9088 138.827 98.6716 138.591C98.4346 138.354 98.3014 138.032 98.3014 137.697C98.3014 137.361 98.4346 137.04 98.6716 136.803C98.9088 136.566 99.2303 136.433 99.5654 136.433C99.9006 136.433 100.222 136.566 100.459 136.803C100.696 137.04 100.829 137.361 100.829 137.697Z"
                                            fill="#47BDFF"
                                        />
                                        <path
                                            d="M120.422 136.435C120.422 136.77 120.289 137.092 120.052 137.329C119.815 137.566 119.493 137.699 119.158 137.699C118.823 137.699 118.501 137.566 118.264 137.329C118.027 137.092 117.894 136.77 117.894 136.435C117.894 136.1 118.027 135.778 118.264 135.541C118.501 135.304 118.823 135.171 119.158 135.171C119.493 135.171 119.815 135.304 120.052 135.541C120.289 135.778 120.422 136.1 120.422 136.435Z"
                                            fill="#47BDFF"
                                        />
                                        <path
                                            d="M337.79 98.6627C336.489 98.7371 334.184 98.8486 333.663 98.8858C334.63 95.802 336.344 95.7628 337.79 98.6627Z"
                                            fill="#47BDFF"
                                        />
                                        <path
                                            d="M338.98 116.509C338.05 119.037 336.526 119.148 335.225 116.806C336.303 116.74 337.604 116.62 338.98 116.509Z"
                                            fill="#47BDFF"
                                        />
                                        <path
                                            d="M202.21 74.2777C202.21 74.6129 202.077 74.9344 201.84 75.1716C201.603 75.4085 201.281 75.5418 200.946 75.5418C200.611 75.5418 200.288 75.4085 200.052 75.1716C199.815 74.9344 199.682 74.6129 199.682 74.2777C199.682 73.9425 199.815 73.621 200.052 73.3839C200.288 73.1469 200.611 73.0137 200.946 73.0137C201.281 73.0137 201.603 73.1469 201.84 73.3839C202.077 73.621 202.21 73.9425 202.21 74.2777Z"
                                            fill="#47BDFF"
                                        />
                                        <path
                                            d="M329.09 103.533C329.077 103.859 328.94 104.168 328.703 104.394C328.468 104.621 328.153 104.747 327.828 104.747C327.501 104.747 327.186 104.621 326.952 104.394C326.715 104.168 326.578 103.859 326.564 103.533C326.578 103.206 326.715 102.898 326.952 102.671C327.186 102.445 327.501 102.318 327.828 102.318C328.153 102.318 328.468 102.445 328.703 102.671C328.94 102.898 329.077 103.206 329.09 103.533Z"
                                            fill="#47BDFF"
                                        />
                                        <path d="M299.388 186.882C299.376 184.745 299.253 182.61 299.016 180.487L309.76 170.523L299.388 186.882Z" fill="#47BDFF" />
                                        <path
                                            d="M194.849 237.443C180.326 239.257 165.58 237.692 151.761 232.87"
                                            stroke="white"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M269.313 181.115C260.353 202.099 244.077 219.115 223.511 228.999"
                                            stroke="white"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M275.26 127.511C276.084 131.954 276.568 136.453 276.71 140.969"
                                            stroke="white"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M197.637 51.6729C219.335 55.0833 239.141 66.0202 253.586 82.5678"
                                            stroke="white"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M175.069 50.8529C179.3 50.513 183.549 50.4508 187.788 50.667C188.532 50.7061 189.312 50.7413 190.056 50.8157"
                                            stroke="white"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M108.748 86.8763C122.591 69.0045 142.418 56.7281 164.587 52.3027"
                                            stroke="white"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M89.232 148.963C89.0802 145.816 89.0925 142.664 89.2692 139.519C89.4477 136.17 89.795 132.832 90.3102 129.519"
                                            stroke="white"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M132.391 223.426C121.629 216.524 112.383 207.508 105.216 196.92"
                                            stroke="white"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M257.824 92.3798C258.906 92.3471 259.979 92.1849 261.021 91.8965L257.155 95.5771"
                                            stroke="white"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M354.706 102.456L362.03 99.1097C366.676 96.9905 366.726 94.7305 361.175 96.5072C360.839 96.6148 352.587 99.0686 351.286 99.2584C350.282 99.4051 348.546 99.5539 348.351 98.8866C347.605 96.3428 350.699 89.4708 348.537 88.7742C347.126 88.3222 344.715 92.7561 343.183 95.9495C342.111 98.1841 340.248 98.552 337.792 98.6635"
                                            stroke="white"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M353.368 102.604C353.368 102.604 364.521 101.234 365.265 101.117C371.061 100.273 370.656 102.461 365.711 103.831C360.989 105.138 355.301 106.766 355.301 106.766"
                                            stroke="white"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M353.368 106.732C357.027 106.841 360.688 106.742 364.335 106.434C370.17 105.978 369.538 107.921 364.596 109.148C361.547 109.981 358.44 110.59 355.301 110.97"
                                            stroke="white"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M338.979 116.509C338.979 116.509 347.047 115.981 348.609 115.84C351.618 115.566 354.71 115.087 357.717 114.688C358.758 114.549 359.985 114.359 361.398 114.1C366.607 113.122 367.382 111.189 361.472 111.349C358.3 111.425 355.13 111.125 352.029 110.457"
                                            stroke="white"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M353.144 100.819C353.71 105.45 352.7 110.136 350.281 114.125"
                                            stroke="white"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M348.758 100.744C347.885 103.051 346.543 105.151 344.813 106.912C343.085 108.672 341.011 110.053 338.72 110.968"
                                            stroke="white"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M277.367 121.691C277.106 122.434 276.846 123.215 276.549 124.039C274.616 122.738 272.976 121.896 272.422 121.437C274.095 121.505 275.731 121.617 277.367 121.691Z"
                                            stroke="white"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M239.757 117.439L243.81 111.602C242.757 113.901 242.209 116.4 242.205 118.93"
                                            stroke="white"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M253.183 99.295C254.527 99.295 256.265 98.1406 257.161 95.5773C257.521 94.5451 257.746 93.4702 257.83 92.3799C254.335 92.4171 249.847 90.6189 254.93 79.1837C256.024 76.726 258.147 72.9672 260.785 73.5424C263.35 74.104 265.111 78.6456 264.894 81.5651C264.806 82.8193 265.677 82.5121 266.708 81.7783C269.588 79.7199 271.615 84.6391 266.225 86.9441C265.867 87.3374 265.598 87.8051 265.442 88.3138C264.051 94.1841 264.002 99.0191 269.903 100.36"
                                            stroke="white"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M258.27 74.2772C265.78 66.9532 288.087 54.0896 285.038 69.1095C285.038 69.1095 301.541 54.9094 307.64 59.9637C313.706 64.9847 290.353 91.8976 265.334 97.0282"
                                            stroke="white"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M337.158 118.514C332.757 118.514 331.032 96.8514 335.485 96.5051C311.613 99.0488 286.856 103.031 269.907 100.334C264.009 107.607 259.198 115.697 255.623 124.351C253.353 116.205 252.527 107.724 253.183 99.2934C249.987 99.0332 215.114 75.9104 200.431 67.3223C197.247 69.4759 194.535 72.2536 192.459 75.4871C190.381 78.7205 188.984 82.3422 188.348 86.1324L242.211 118.927C242.198 119.528 242.211 120.158 242.245 120.831C242.52 125.817 244.428 132.918 249.8 144.351C256.443 146.333 266.739 142.684 272.924 139.109C272.533 133.675 275.699 126.821 277.372 121.694C297.372 122.608 316.471 121.023 337.158 118.514Z"
                                            stroke="white"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M249.831 144.353L217.711 178.741C211.909 184.938 207.677 192.434 205.37 200.602L193.361 242.572C196.676 245.499 200.648 247.581 204.943 248.638C209.236 249.694 213.723 249.694 218.016 248.638L230.344 203.981C266.823 172.051 260.573 177.508 264.62 174.018L284.51 204.316C285.678 206.092 287.218 207.593 289.022 208.718C290.828 209.842 292.854 210.562 294.965 210.826C297.074 211.092 299.215 210.896 301.242 210.252C303.269 209.611 305.132 208.536 306.703 207.104L344.288 172.791C343.3 168.529 341.322 164.561 338.514 161.207C335.708 157.853 332.149 155.207 328.128 153.486L299.007 180.489C299.007 180.489 293.988 147.365 272.873 139.111"
                                            stroke="white"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M261.654 176.584L259.051 165.542L264.628 174.019"
                                            stroke="white"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M195.704 244.355C194.238 252.241 191.996 259.96 189.012 267.406C186.18 273.797 183.961 279.52 184.773 280.493C187.618 283.895 197.297 269.196 204.478 265.435C206.188 264.586 207.718 263.412 208.978 261.978C211.5 259 210.092 255.546 213.029 249.337"
                                            stroke="white"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M342.772 168.219C348.928 162.979 355.383 158.101 362.105 153.61C367.94 149.742 372.884 146.1 372.736 144.873C372.209 140.486 356.084 147.595 348.051 146.918C346.141 146.719 344.212 146.883 342.363 147.401C338.645 148.525 338.162 152.049 332.325 155.729"
                                            stroke="white"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M187.562 63.0838C187.562 54.8655 193.953 39.5443 196.447 38.288C196.514 38.2579 196.586 38.2452 196.657 38.2516C196.729 38.2581 196.798 38.2831 196.858 38.3244C196.917 38.3659 196.964 38.4219 196.995 38.4872C197.027 38.5526 197.04 38.625 197.034 38.697C196.179 47.4749 190.485 62.4674 198.422 68.7818"
                                            stroke="white"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M189.309 82.3803C186.446 80.447 182.407 77.0971 182.542 73.8293C182.916 64.797 182.859 60.5568 182.877 56.9877C182.891 54.3481 184.967 54.2718 186.372 58.8466C187.604 62.8599 188.9 68.5129 188.9 68.5129"
                                            stroke="white"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M195.295 49.1067C196.078 47.8055 196.925 46.4651 197.265 45.9113C199.75 41.8687 200.748 42.8588 200.537 43.6063C199.709 46.5297 197.711 50.9284 197.042 53.6424C196.044 57.6361 194.346 63.2109 197.512 67.954"
                                            stroke="white"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M157.338 181.312C157.338 181.312 149.94 182.911 139.939 184.138"
                                            stroke="white"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M89.1204 149.148C90.0866 147.538 91.3622 146.135 92.8734 145.022V145.17C104.845 146.881 113.842 146.806 123.136 144.388C123.004 146.894 122.58 149.377 121.872 151.786C133.172 151.526 144.498 151.835 155.851 152.716C158.509 152.921 161.091 153.704 163.415 155.011C165.74 156.316 167.751 158.114 169.308 160.278C170.866 162.443 171.932 164.922 172.433 167.54C172.934 170.158 172.857 172.854 172.207 175.441C167.902 193.652 162.59 211.611 156.297 229.236C147.88 230.631 139.248 228.756 132.169 223.994C134.019 210.578 136.6 197.274 139.902 184.141C128.676 185.51 111.987 186.769 100.571 183.62C93.8068 181.756 88.2927 177.862 85.8957 171.24C83.1582 163.726 85.6237 154.612 89.1204 149.148Z"
                                            stroke="white"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M121.909 151.825C120.05 151.862 118.228 151.937 116.406 152.011"
                                            stroke="white"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M111.319 185.319L96.9564 212.645C95.6429 215.142 93.8153 217.333 91.594 219.073C89.3727 220.814 86.808 222.065 84.0688 222.742C81.3295 223.421 78.4778 223.511 75.7012 223.006C72.9248 222.503 70.2863 221.417 67.9595 219.82L33.2803 196.065C36.3328 185.904 40.5026 179.282 48.8129 174.113L76.2561 191.682L85.9224 171.234"
                                            stroke="white"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path d="M93.8772 180.749L108.042 191.568" stroke="white" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round" />
                                        <path
                                            d="M106.108 98.8103L101.647 95.7617C102.932 95.9055 104.226 95.9427 105.517 95.8733"
                                            stroke="white"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M114.437 99.7413C113.767 100.15 113.098 100.524 112.429 100.931C111.702 101.502 110.803 101.809 109.879 101.803C108.954 101.796 108.06 101.475 107.341 100.894C106.202 99.4652 105.563 97.7018 105.524 95.8748C111.503 95.5245 111.869 91.5034 105.111 79.5928C105.04 79.9395 104.879 80.2614 104.644 80.5256C104.408 80.7895 104.107 80.9866 103.771 81.0961C103.434 81.2057 103.075 81.2241 102.729 81.1494C102.383 81.0748 102.063 80.9097 101.802 80.6709C101.668 80.9492 101.473 81.1936 101.232 81.3859C100.99 81.5783 100.708 81.7135 100.407 81.7814C100.106 81.8493 99.7937 81.8483 99.4929 81.7783C99.1921 81.7084 98.9114 81.5712 98.6711 81.3773C98.5781 81.7004 98.4055 81.9947 98.169 82.2336C97.9326 82.4725 97.6399 82.648 97.318 82.7443C96.9959 82.8406 96.6548 82.8543 96.3261 82.7844C95.9974 82.7144 95.6915 82.5629 95.4366 82.344C96.7006 84.5355 97.2211 86.9892 95.5109 85.3162C93.5777 83.4221 91.4957 86.2085 94.5463 88.4783C95.1172 88.9902 95.537 89.6489 95.7598 90.3826C95.9827 91.1164 96.0001 91.8973 95.8103 92.6403C97.5741 95.2132 98.2791 98.3665 97.7788 101.446C97.4442 103.011 95.2135 104.234 92.4956 103.528C95.7379 109.353 102.41 114.72 108.899 112.427C116.039 109.912 114.437 99.7413 114.437 99.7413Z"
                                            stroke="white"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M160.629 69.1902C169.812 62.2457 170.174 47.5624 170.174 37.0332C170.174 36.6986 170.655 36.2505 170.916 36.4461C173.238 37.9567 176.328 53.6126 175.584 61.8505"
                                            stroke="white"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M171.166 81.6745C173.801 80.6135 175.983 78.6679 177.338 76.1721C180.169 69.8714 181.16 62.8986 180.197 56.0588C179.692 53.0885 178.15 53.3448 176.814 57.8434C175.622 61.8586 173.765 67.2494 173.765 67.2494"
                                            stroke="white"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M160.474 69.0912C163.034 65.9604 165.442 60.4033 165.331 57.4701C165.03 54.1817 164.458 50.9237 163.621 47.7295C163.452 46.9468 164.429 45.9058 167.264 51.2516"
                                            stroke="white"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M125.85 116.769C125.711 115.12 125.476 113.482 125.144 111.861L128.075 115.565"
                                            stroke="white"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M123.173 144.389H123.21C124.809 134.946 126.556 125.391 125.85 116.768C140.696 108.98 159.565 93.8838 171.246 83.607C172.571 82.2823 167.932 76.416 166.656 74.9112C165.347 73.3674 160.228 67.683 158.422 68.5871C144.775 80.3185 130.062 90.7483 114.474 99.7403"
                                            stroke="white"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M152.542 229.711C151.584 233.505 150.852 237.354 150.349 241.236C144.531 237.426 139.444 232.603 135.329 226.997C135.478 226.672 135.602 226.338 135.701 225.993"
                                            stroke="white"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M135.291 241.188C134.093 238.947 133.193 236.56 132.616 234.087C131.984 235.582 131.651 237.186 131.638 238.81C132.106 241.125 132.92 243.356 134.055 245.426C137.884 252.564 141.713 267.51 144.464 267.324C144.873 267.287 145.319 266.506 145.766 265.205C142.838 261.792 139.083 248.148 135.291 241.188Z"
                                            stroke="white"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M135.291 227.032C134.438 229.486 133.358 231.94 132.616 234.094C133.193 236.567 134.093 238.955 135.291 241.195C139.046 248.147 142.801 261.792 145.775 265.212C147.411 260.342 149.159 248.112 150.311 241.232C144.508 237.419 139.424 232.613 135.291 227.032Z"
                                            stroke="white"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M34.8788 191.343C31.598 189.492 28.2087 187.841 24.7292 186.399C29.9395 181.798 35.9335 178.173 42.426 175.691C43.4298 176.361 43.5785 176.621 44.5451 177.179"
                                            stroke="white"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M32.0539 169.038C29.6969 168.861 27.3267 169.049 25.0273 169.597C17.1456 171.417 1.68144 171.12 1.15507 173.834C1.08072 174.243 1.71274 174.875 2.82808 175.655C6.88049 173.685 21.0434 173.685 28.8136 171.937C31.2847 171.356 33.8246 171.118 36.3607 171.233C35.0884 170.218 33.6232 169.47 32.0539 169.038Z"
                                            stroke="white"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M36.3291 171.194C33.7929 171.082 31.2535 171.321 28.7819 171.9C21.0489 173.685 6.88602 173.685 2.83362 175.655C7.07191 178.555 18.4093 183.5 24.7295 186.399C29.9544 181.791 35.9598 178.152 42.4635 175.655C40.2699 174.168 38.188 172.495 36.3291 171.194Z"
                                            stroke="white"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M12.7228 117.996C11.31 118.479 9.48634 119.033 7.63527 119.595C4.21294 120.628 2.95084 120.004 6.37122 117.661C9.60516 115.466 13.1734 113.808 16.9376 112.754"
                                            stroke="white"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M20.3422 108.441C19.3099 109.45 18.3755 110.554 17.5519 111.739C16.0738 113.953 15.0741 116.452 14.6168 119.074"
                                            stroke="white"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M11.422 114.799C-0.958346 119.149 -2.51199 117.119 7.85284 112.382C11.852 110.67 16.0152 109.371 20.2781 108.504"
                                            stroke="white"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M35.6681 118.067C30.4886 117.777 19.4369 119.709 11.4221 122.372C7.92737 123.524 6.05868 122.763 9.93498 119.844C11.5681 118.623 13.3182 117.567 15.1595 116.691"
                                            stroke="white"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M14.2079 110.001C10.0459 110.968 0.559597 111.324 15.3232 106.544C17.6282 105.798 20.4107 105.174 23.0191 104.611C23.1664 103.641 22.9967 102.65 22.5357 101.785C20.9703 99.1631 21.4987 98.0986 24.06 99.6659C27.3108 101.878 31.1706 103.022 35.1019 102.938"
                                            stroke="white"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M23.0192 104.607C23.0028 105.589 23.189 106.563 23.5665 107.47C23.9439 108.376 24.5045 109.195 25.2131 109.874C25.9214 110.554 26.7626 111.08 27.6839 111.419C28.6049 111.759 29.5862 111.904 30.5663 111.847"
                                            stroke="white"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M105.105 79.6285C105.03 81.0784 103.171 81.6732 101.796 80.7066C101.655 80.9778 101.456 81.2152 101.215 81.4017C100.972 81.5883 100.692 81.72 100.394 81.7871C100.096 81.8544 99.7871 81.8558 99.4883 81.7912C99.1895 81.7265 98.9085 81.5975 98.6649 81.413C98.5718 81.7359 98.399 82.03 98.1624 82.2687C97.9259 82.5074 97.6331 82.6827 97.311 82.7788C96.9892 82.8749 96.6481 82.8884 96.3196 82.8183C95.991 82.7483 95.6852 82.5966 95.4304 82.3777C96.6573 84.5712 97.215 87.0269 95.5048 85.3519C93.5715 83.4578 91.4974 86.1992 94.5382 88.5121C95.1099 89.0237 95.5302 89.6828 95.7533 90.4169C95.9762 91.1513 95.993 91.9326 95.8022 92.676C92.7184 87.5102 87.216 83.4206 85.952 82.1566C83.0169 79.2215 85.0969 77.3606 87.5878 78.4388C87.0008 76.8401 88.889 75.5037 90.6345 76.8401C90.1511 75.2747 92.4953 73.9774 94.501 75.6132C94.1664 73.903 97.0663 73.1967 98.7393 74.6838C98.479 73.1595 101.162 73.044 102.383 75.0556C103.361 76.6503 105.105 79.6285 105.105 79.6285Z"
                                            stroke="white"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M90.0126 128.216L92.7305 124.721C91.3941 124.463 90.13 124.201 88.866 123.979"
                                            stroke="white"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M92.5033 103.534C92.02 103.348 68.6311 97.2508 35.7716 100.403C33.9896 107.058 34.1937 114.088 36.3587 120.628C53.929 119.199 71.6138 120.329 88.8599 123.98C90.9544 130.828 92.3 137.883 92.8732 145.021"
                                            stroke="white"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M69.4896 60.9299C69.5766 61.9373 69.3632 62.9477 68.8761 63.8337C68.3889 64.7196 67.6502 65.4412 66.7531 65.9075C65.8559 66.3736 64.8406 66.5632 63.8358 66.4526C62.8308 66.3419 61.8812 65.9357 61.1071 65.2854C60.3328 64.635 59.7689 63.7699 59.4863 62.7992C59.2038 61.8285 59.2153 60.7957 59.5196 59.8316C59.8241 58.8673 60.4072 58.0152 61.1957 57.3824C61.9843 56.7498 62.9429 56.3649 63.9501 56.2768C65.3008 56.1633 66.6415 56.5889 67.6796 57.4608C68.7174 58.3328 69.3683 59.5798 69.4896 60.9299Z"
                                            stroke="white"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path d="M273.29 238.186H257.192" stroke="white" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M265.242 246.251V230.118" stroke="white" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round" />
                                        <path
                                            d="M47.5301 99.6289C45.712 106.416 46.05 113.601 48.4967 120.188"
                                            stroke="white"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M151.433 74.4619C157.021 78.1987 161.57 83.2917 164.655 89.2646"
                                            stroke="white"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M337.79 98.6627C336.489 98.7371 334.184 98.8486 333.664 98.8858C334.63 95.802 336.344 95.7628 337.79 98.6627Z"
                                            stroke="white"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M338.98 116.509C338.05 119.037 336.526 119.148 335.225 116.806C336.303 116.74 337.604 116.62 338.98 116.509Z"
                                            stroke="white"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M267.454 141.785C282.511 152.352 286.63 165.395 292.956 188.551C293.177 189.359 293.602 190.097 294.189 190.696C294.778 191.293 295.507 191.729 296.312 191.966C297.116 192.201 297.967 192.226 298.783 192.04C299.601 191.852 300.356 191.461 300.978 190.899L336.344 158.852"
                                            stroke="white"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M208.976 249.301L221.8 200.155L259.497 167.401"
                                            stroke="white"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M112.837 150.301C111.415 152.71 109.496 154.788 107.207 156.398C104.919 158.006 102.313 159.11 99.5649 159.632"
                                            stroke="white"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M134.213 211.306C137.976 214.02 142.263 215.922 146.801 216.89C151.339 217.857 156.029 217.869 160.572 216.923"
                                            stroke="white"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M58.8202 180.528C51.5353 185.436 46.8841 193.392 44.0625 203.422"
                                            stroke="white"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M309.76 170.523L299.389 186.882C299.378 184.745 299.254 182.61 299.018 180.487"
                                            stroke="white"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M160.641 69.1903C165.967 65.175 167.264 59.2109 167.45 53.7849C167.069 49.99 166.423 46.2266 165.517 42.5219C165.399 41.7783 166.446 40.8861 168.565 45.1615L169.792 47.8305"
                                            stroke="white"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M182.951 1.00002C179.971 1.00002 177.058 1.88368 174.581 3.53928C172.103 5.19488 170.172 7.54786 169.031 10.301C167.891 13.0541 167.593 16.0835 168.174 19.0063C168.755 21.9289 170.19 24.6137 172.297 26.7208C174.405 28.828 177.089 30.2628 180.012 30.8442C182.935 31.4257 185.964 31.1273 188.718 29.9869C191.471 28.8466 193.823 26.9153 195.478 24.4376C197.134 21.9598 198.018 19.0468 198.018 16.0669C198.022 14.0873 197.635 12.1264 196.879 10.2969C196.122 8.46714 195.013 6.80469 193.613 5.40503C192.212 4.00518 190.551 2.89551 188.722 2.13963C186.892 1.38374 184.931 0.996502 182.951 1.00002Z"
                                            stroke="white"
                                            strokeWidth="0.75"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M182.952 9.35015C183.762 9.35015 184.419 8.6931 184.419 7.88259C184.419 7.07209 183.762 6.41504 182.952 6.41504C182.141 6.41504 181.484 7.07209 181.484 7.88259C181.484 8.6931 182.141 9.35015 182.952 9.35015Z"
                                            stroke="white"
                                            strokeWidth="0.75"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M183.669 13.3256C183.669 12.9203 183.341 12.5918 182.936 12.5918C182.53 12.5918 182.202 12.9203 182.202 13.3256H183.669ZM182.202 25.4329C182.202 25.8381 182.53 26.1667 182.936 26.1667C183.341 26.1667 183.669 25.8381 183.669 25.4329H182.202ZM182.202 13.3256V25.4329H183.669V13.3256H182.202Z"
                                            fill="white"
                                        />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="relative pt-14 lg:pb-48 lg:pt-[100px]">
                    <div className="container relative z-[1]">
                        <div className="text-center lg:w-full">
                            <div className="heading mb-5 text-center lg:text-right xl:w-[95%]">
                                <h4 className="text-primary-200">על דודה</h4>
                            </div>
                            <p className="pb-6 text-xl font-bold leading-[30px]">
                                פלטפורמת דודה מבוססת על מודלי הבינה מלאכותית-יוצרת-שפה המתקדמים בעולם ועל טכנולוגיות ML(machine learning) מתקדמות.
                            </p>
                            <p className="pb-10 text-xl font-semibold leading-[30px]">
                                דרך הפלטפורמה שלנו הינך יכול/ה ליצור כתיבה שיווקית במגוון רחב של סוגים לעסקים, אירועים, מוצרים. ניתן גם ליצור תכנים כתובים לאינסטגרם ולרשתות החברתיות או לבקש כל סוג תוכן כתוב אחר באמצעות הצ׳אט.
                                פלטפורמת דודה שמה לעצמה כמטרה לשנות את שוק הקופירייטינג בארץ ולסייע ליצור תוכן איכותי, חדש ומעניין לחברה הישראלית.
                                תוך בנייה ופיתוח הפלטפורמה אנו שמים לעצמנו יעדים כדי לשפר את התוצאות ולהוסיף סוגי תוכן נוספים ליצירה ושיפור חווית המשתמש.
                                אנו נשמח לשמוע מכם בכל נושא, ניתן לפנות בדף יצירת הקשר.
                            </p>
                        </div>
                    </div>
                </section>

                <section className="relative overflow-x-hidden border-t-2 border-transparent py-14 dark:border-gray/20 lg:py-[100px]">
                    <div className="container">
                        <div className="relative">
                            <div className="relative xl:inline-block" data-aos="fade-down" data-aos-duration="1000">
                                <img src="/assets/images/stay-text.png" alt="stay-text" className="left-1/2 mx-auto sm:absolute sm:-translate-x-1/2" />
                                <img src="/assets/images/updated-text.png" alt="updated-text" className="mx-auto sm:pt-4 xl:mx-0" />
                                <div className="top-20 ltr:left-[105%] rtl:right-[105%] xl:absolute">
                                    <svg
                                        width="320"
                                        height="282"
                                        viewBox="0 0 320 282"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="mx-auto my-8 w-[90%] sm:w-auto"
                                    >
                                        <path
                                            d="M254.182 123.52L203.127 119.709C198.183 119.334 193.219 120.151 188.656 122.091L175.076 127.872C173.249 131.184 171.005 134.248 168.397 136.989C161.845 143.977 136.719 156.969 123.457 159.701C120.332 160.256 117.111 159.694 114.361 158.113C108.352 162.928 102.056 167.373 95.5072 171.426C95.5072 171.426 107.911 206.717 154.095 186.768L190.829 170.854L182.782 190.136C183.005 201.158 183.069 215.611 182.337 225.245C190.961 227.868 200.16 227.956 208.832 225.497C212.297 201.991 214.937 164.889 205.968 150.88L249.86 150.467C255.391 140.518 254.904 134.861 254.182 123.52Z"
                                            fill="#B476E5"
                                        />
                                        <path
                                            d="M172.633 109.862L177.977 86.3878C178.096 85.8739 178.003 85.3342 177.724 84.8875C177.443 84.4406 176.996 84.1235 176.482 84.0054C175.968 83.8876 175.427 83.9785 174.98 84.2585C174.532 84.5386 174.215 84.9845 174.097 85.4984L167.799 113.166"
                                            fill="#B476E5"
                                        />
                                        <path
                                            d="M172.633 109.862L177.977 86.3878C178.096 85.8739 178.003 85.3342 177.724 84.8875C177.443 84.4406 176.996 84.1235 176.482 84.0054C175.968 83.8876 175.427 83.9785 174.98 84.2585C174.532 84.5386 174.215 84.9845 174.097 85.4984L167.799 113.166C168.591 116.084 170.719 119.996 172.633 109.862Z"
                                            fill="#B476E5"
                                        />
                                        <path
                                            d="M168.499 20.9169C168.499 24.8561 167.329 28.7067 165.138 31.982C162.946 35.2574 159.832 37.8102 156.189 39.3177C152.545 40.8252 148.535 41.2196 144.667 40.451C140.799 39.6826 137.245 37.7857 134.457 35.0003C131.668 32.2148 129.768 28.6659 128.999 24.8024C128.23 20.9389 128.625 16.9343 130.134 13.295C131.643 9.65572 134.199 6.54505 137.478 4.35659C140.758 2.16813 144.613 1 148.557 1C153.845 1.00318 158.916 3.10253 162.655 6.83696C166.393 10.5714 168.495 15.6356 168.499 20.9169Z"
                                            fill="#B476E5"
                                        />
                                        <path
                                            d="M272.632 126.031C268.973 134.696 263.804 142.644 257.366 149.506C260.896 150.046 264.427 150.843 267.448 151.316C270.595 149.945 273.546 148.163 276.223 146.015C284.593 139.281 301.834 130.926 305.61 126.161C298.772 125.173 282.043 125.84 272.632 126.031Z"
                                            fill="#47BDFF"
                                        />
                                        <path
                                            d="M207.937 244.834C199.124 241.511 190.97 236.649 183.861 230.475C183.447 234.002 182.811 237.56 182.43 240.609C183.921 243.702 185.814 246.586 188.059 249.186C195.089 257.254 204.09 274.154 208.983 277.775C209.75 270.914 208.479 254.237 207.937 244.834Z"
                                            fill="#47BDFF"
                                        />
                                        <path
                                            d="M98.7515 144.136C98.1154 144.454 97.4475 144.739 96.7796 145.025C93.6602 141.364 90.7292 137.548 87.9982 133.59C88.2844 134.259 97.0625 153.221 111.629 160.368C112.583 159.606 113.505 158.875 114.364 158.144C108.922 155.19 103.268 149.472 98.7515 144.136Z"
                                            fill="#47BDFF"
                                        />
                                        <path d="M182.398 174.472C182.398 174.472 182.684 181.174 182.843 190.1L190.826 170.851L182.398 174.472Z" fill="#47BDFF" />
                                        <path
                                            d="M96.7478 60.7848C96.7478 60.7848 91.1787 50.3641 78.0785 56.7189C64.9784 63.0736 67.5194 88.6112 67.5194 88.6112C67.5194 88.6112 55.8772 94.1702 49.6753 103.541C40.0703 118.058 44.9063 139.976 74.7742 139.976C73.3503 133.718 72.8574 127.286 73.3112 120.885C74.1063 109.608 78.2091 99.2844 81.8348 88.7065C86.4582 75.0324 85.4656 76.1258 87.0826 71.1085C83.2325 67.4555 87.8141 63.0719 89.8814 66.628C90.7569 68.0658 90.9661 68.1226 91.6658 68.3416C95.4556 69.527 102.332 70.8494 106.062 69.6156C106.067 69.5186 106.385 60.7848 96.7478 60.7848Z"
                                            fill="#47BDFF"
                                        />
                                        <path
                                            d="M89.6872 93.1215L86.2843 84.6084C87.4194 86.1276 88.757 87.4848 90.2599 88.6426C89.9802 90.1228 89.789 91.6185 89.6872 93.1215Z"
                                            fill="#47BDFF"
                                        />
                                        <path
                                            d="M134.69 237.37C134.911 238.445 134.807 239.56 134.392 240.575C133.978 241.59 133.271 242.459 132.362 243.074C131.452 243.688 130.381 244.019 129.283 244.025C128.185 244.03 127.11 243.711 126.194 243.108C125.278 242.504 124.562 241.641 124.136 240.632C123.71 239.62 123.594 238.507 123.802 237.43C124.01 236.353 124.534 235.362 125.305 234.583C126.077 233.804 127.063 233.27 128.139 233.05C128.853 232.899 129.59 232.893 130.307 233.03C131.025 233.167 131.708 233.443 132.317 233.846C132.926 234.247 133.45 234.765 133.857 235.37C134.264 235.976 134.548 236.654 134.69 237.37Z"
                                            fill="#47BDFF"
                                        />
                                        <path
                                            d="M168.499 20.9169C168.499 24.8561 167.329 28.7067 165.137 31.9821C162.946 35.2574 159.832 37.8101 156.188 39.3177C152.545 40.8252 148.535 41.2196 144.667 40.451C140.799 39.6826 137.245 37.7857 134.457 35.0003C131.668 32.2148 129.768 28.6659 128.999 24.8024C128.229 20.9389 128.624 16.9343 130.134 13.295C131.643 9.65572 134.199 6.54505 137.478 4.35659C140.758 2.16813 144.613 1 148.557 1C153.845 1.00318 158.915 3.10253 162.655 6.83696C166.393 10.5714 168.495 15.6356 168.499 20.9169Z"
                                            stroke="#7780A1"
                                            strokeWidth="0.75"
                                        />
                                        <path
                                            d="M212.103 186.067L228.546 194.357C231.921 196.054 235.833 196.345 239.421 195.167C243.012 193.988 245.987 191.435 247.694 188.068L266.522 150.839"
                                            stroke="#7780A1"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M278.449 127.269L290.058 104.335C291.757 100.964 292.048 97.0577 290.868 93.4729C289.688 89.8879 287.132 86.917 283.76 85.2119L167.571 26.584M101.709 102.88L135.419 36.1878"
                                            stroke="#7780A1"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M290.599 92.5821L193.561 108.211L157.692 38.6367"
                                            stroke="#7780A1"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M140.392 191.371C133.153 199.321 124.384 205.732 114.61 210.223C104.836 214.715 94.2565 217.194 83.5022 217.514C72.7479 217.833 62.0394 215.986 52.0153 212.082C41.9913 208.178 32.8572 202.298 25.1578 194.793C17.4584 187.287 11.3514 178.31 7.20105 168.396C3.05076 158.482 0.942231 147.834 1.0012 137.089C1.06018 126.343 3.28546 115.72 7.54432 105.852C11.8032 95.9836 18.0084 87.0734 25.7897 79.6523"
                                            stroke="#7780A1"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M89.6867 95.7573C89.5973 93.3497 89.802 90.94 90.296 88.5817C94.1728 91.1714 97.3767 89.5614 99.7286 86.211C102.745 81.3349 104.294 75.6948 104.19 69.9639"
                                            stroke="#7780A1"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M87.998 133.591C90.728 137.549 93.6577 141.365 96.7761 145.026C101.788 142.928 106.405 139.991 110.429 136.343C113.409 133.64 116.083 130.619 118.403 127.333C112.915 120.166 107.83 112.7 103.17 104.97C100.901 101.2 97.4947 98.2448 93.4399 96.5273C91.7007 95.8524 89.8227 95.6125 87.9695 95.8285C86.5932 96.1891 85.1515 96.221 83.7604 95.9219C82.3696 95.6228 81.0688 95.0009 79.9631 94.1064"
                                            stroke="#7780A1"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M254.436 125.998C258.762 126.157 267.349 126.125 272.597 126.03C268.948 134.692 263.79 142.64 257.364 149.504C255.742 149.25 252.307 148.901 250.749 148.806"
                                            stroke="#7780A1"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M305.608 126.157C307.421 126.411 308.586 126.826 308.725 127.396C309.606 131.004 290.701 139.768 282.2 146.614C279.728 148.63 276.963 150.259 273.998 151.442C271.835 151.839 269.616 151.797 267.47 151.315C270.622 149.948 273.576 148.166 276.255 146.014C284.601 139.293 301.825 130.954 305.608 126.157Z"
                                            stroke="#7780A1"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M272.632 126.03C282.047 125.839 298.776 125.172 305.609 126.157C301.824 130.922 284.593 139.284 276.221 146.01C273.545 148.162 270.593 149.946 267.443 151.315C264.422 150.839 260.891 150.044 257.361 149.504C263.801 142.643 268.971 134.695 272.632 126.03Z"
                                            stroke="#7780A1"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M207.555 225.869C207.269 230.634 207.555 237.654 207.937 244.865C199.135 241.535 190.995 236.673 183.892 230.507C184.083 228.887 184.21 227.267 184.242 225.742"
                                            stroke="#7780A1"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M208.986 277.775C208.795 279.614 208.449 280.768 207.873 280.952C204.326 282.08 194.828 263.389 187.677 255.158C185.558 252.759 183.82 250.049 182.524 247.122C182.059 244.977 182.026 242.76 182.429 240.602C183.915 243.698 185.809 246.582 188.058 249.178C195.089 257.282 204.088 274.154 208.986 277.775Z"
                                            stroke="#7780A1"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M207.936 244.833C208.477 254.236 209.749 270.914 208.986 277.769C204.088 274.148 195.066 257.267 188.062 249.18C185.814 246.582 183.92 243.698 182.432 240.604C182.814 237.554 183.45 233.995 183.864 230.469C190.971 236.645 199.123 241.508 207.936 244.833Z"
                                            stroke="#7780A1"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M111.632 160.368C97.0654 153.221 88.2873 134.257 87.9977 133.59"
                                            stroke="#7780A1"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M98.7515 144.136C103.271 149.472 108.932 155.217 114.368 158.179C117.125 159.741 120.343 160.291 123.464 159.736C136.738 157.061 161.852 144.012 168.403 137.024C180.268 124.368 179.294 114.726 178.263 98.6194C177.844 92.0757 177.021 92.2329 176.037 95.2756C174.572 99.8164 172.724 110.824 171.202 115.161C171.06 115.597 170.767 115.969 170.375 116.209C169.204 116.878 168.465 115.415 167.864 113.35C167.227 111.158 166.771 108.239 166.179 106.495C165.139 103.421 163.018 100.619 162.68 109.64C162.483 114.945 162.469 124.082 156.701 127.429C152.212 129.984 147.533 132.193 142.707 134.036C137.715 135.989 132.485 137.271 127.155 137.848C124.26 138.002 120.38 134.703 116.691 129.589"
                                            stroke="#7780A1"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M172.633 109.861L177.976 86.3868C178.095 85.8729 178.003 85.3332 177.724 84.8865C177.442 84.4396 176.996 84.1225 176.482 84.0045C175.968 83.8866 175.427 83.9775 174.98 84.2576C174.531 84.5376 174.215 84.9835 174.096 85.4974L167.799 113.165"
                                            stroke="#7780A1"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M182.407 174.502C187.369 172.374 190.834 170.851 190.834 170.851L182.843 190.1"
                                            stroke="#7780A1"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M114.367 158.176C108.359 162.976 102.061 167.401 95.5071 171.426C84.8217 163.977 77.4015 152.721 74.7738 139.975"
                                            stroke="#7780A1"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M175.051 127.872L189.967 121.551C193.661 119.992 197.672 119.328 201.671 119.613L254.182 123.52C255.364 134.496 255.223 140.809 249.857 150.457L206.028 150.87"
                                            stroke="#7780A1"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M96.7477 60.7838C96.7477 60.7838 91.1786 50.3631 78.0784 56.7179C64.9782 63.0726 67.5193 88.6103 67.5193 88.6103C67.5193 88.6103 55.8771 94.1692 49.6752 103.54C40.0702 118.057 44.9062 139.975 74.7741 139.975C73.3502 133.718 72.8573 127.285 73.3111 120.884C74.1062 109.607 78.209 99.2834 81.8347 88.7056C82.6616 86.2596 84.6335 80.2877 85.3014 77.8084C85.9057 75.4912 86.1919 73.8695 87.0825 71.1076C83.2324 67.4546 87.814 63.0709 89.8813 66.627C90.7936 68.1317 90.971 68.0447 91.9703 68.3741C94.4444 69.1866 101.11 71.0307 106.07 69.5444C106.066 69.5494 106.385 60.7838 96.7477 60.7838Z"
                                            stroke="#7780A1"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M33.9636 66.246C22.6885 71.6778 12.08 78.3937 2.34976 86.2598C2.07591 86.43 1.84746 86.6639 1.68402 86.9416C1.52058 87.2193 1.42704 87.5324 1.41141 87.8541C1.39575 88.1757 1.45851 88.4966 1.59425 88.7886C1.72999 89.0809 1.93468 89.3359 2.19074 89.5316L9.44224 95.2811C13.8363 89.5249 19.7938 84.6598 25.7798 79.6342C21.1723 85.5872 17.1371 91.9607 13.7275 98.67L21.5297 104.843C21.7814 105.042 22.0776 105.178 22.3931 105.24C22.7086 105.301 23.0342 105.286 23.3427 105.196C23.6512 105.106 23.9336 104.943 24.1659 104.721C24.3983 104.499 24.5741 104.225 24.6783 103.921L36.9533 68.6066C37.0965 68.2107 37.1116 67.7797 36.9963 67.3748C36.8808 66.9699 36.6407 66.6114 36.3101 66.3505C35.9794 66.0893 35.5748 65.9387 35.1536 65.92C34.7326 65.9012 34.3161 66.0153 33.9636 66.246Z"
                                            stroke="#7780A1"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M249.602 5.70256C243.273 2.55613 236.181 7.04005 237.199 13.6439L234.241 16.374L238.312 16.6917C241.906 22.7589 251.034 23.0147 254.023 17.0411C254.937 14.9513 254.984 12.5853 254.156 10.4605C253.327 8.33574 251.69 6.6251 249.602 5.70256Z"
                                            stroke="#7780A1"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M287.259 189.242C286.298 188.746 285.248 188.443 284.172 188.351C283.094 188.259 282.008 188.38 280.977 188.707C279.947 189.035 278.99 189.562 278.164 190.259C277.337 190.956 276.656 191.809 276.159 192.768C276.159 192.768 270.784 203.252 266.268 202.933L288.722 214.527C285.828 211.033 290.789 200.33 290.789 200.33C291.286 199.37 291.591 198.322 291.681 197.245C291.773 196.169 291.653 195.085 291.325 194.056C290.997 193.026 290.469 192.071 289.771 191.245C289.073 190.419 288.22 189.739 287.259 189.242Z"
                                            stroke="#7780A1"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M281.184 210.653C280.679 211.631 279.803 212.366 278.754 212.701C277.704 213.033 276.566 212.936 275.587 212.432C274.608 211.927 273.871 211.052 273.536 210.004C273.203 208.956 273.3 207.819 273.806 206.841"
                                            stroke="#7780A1"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M311.749 67.8984L305.801 88.3553"
                                            stroke="#7780A1"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path d="M319 81.0817L298.518 75.1416" stroke="#7780A1" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round" />
                                        <path
                                            d="M182.875 214.75C191.909 216.235 201.112 216.403 210.195 215.252"
                                            stroke="#7780A1"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M242.033 122.694C243.466 132.224 241.922 141.963 237.612 150.584"
                                            stroke="#7780A1"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M134.691 237.37C134.911 238.445 134.807 239.56 134.392 240.575C133.978 241.59 133.271 242.459 132.362 243.074C131.452 243.688 130.381 244.019 129.283 244.025C128.185 244.03 127.111 243.711 126.194 243.108C125.278 242.504 124.562 241.641 124.136 240.632C123.71 239.62 123.595 238.507 123.803 237.43C124.011 236.353 124.534 235.362 125.306 234.583C126.078 233.804 127.064 233.27 128.139 233.05C128.853 232.899 129.591 232.893 130.308 233.03C131.025 233.167 131.708 233.443 132.317 233.846C132.926 234.247 133.45 234.765 133.857 235.37C134.265 235.976 134.548 236.654 134.691 237.37Z"
                                            stroke="#7780A1"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M132.178 219.993C134.31 218.002 136.986 216.686 139.867 216.215C142.748 215.742 145.704 216.133 148.362 217.338C151.02 218.544 153.261 220.51 154.8 222.988C156.34 225.464 157.11 228.342 157.012 231.257C156.914 234.171 155.953 236.989 154.251 239.358C152.548 241.727 150.181 243.538 147.448 244.563C144.715 245.588 141.739 245.78 138.896 245.116C136.054 244.452 133.472 242.961 131.478 240.831C128.806 237.974 127.378 234.176 127.51 230.267C127.641 226.36 129.32 222.665 132.178 219.993Z"
                                            stroke="#7780A1"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M95.5071 171.426C95.5071 171.426 107.911 206.717 154.095 186.769L182.407 174.502C182.759 187.847 183.499 210.432 182.374 225.235C190.998 227.858 200.195 227.946 208.867 225.489C212.334 201.981 215.014 164.874 206.037 150.87C194.406 132.726 158.648 144.358 158.648 144.358"
                                            stroke="#7780A1"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M90.2964 88.5822C88.7639 87.4911 87.4176 86.1605 86.3091 84.6416L89.6871 93.1213"
                                            stroke="#7780A1"
                                            strokeWidth="0.75"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </div>
                            </div>
                            <p className="pt-2 text-center text-lg font-bold text-gray-dark dark:text-white ltr:xl:text-left rtl:xl:text-right">
                                Enter your email address to register to our newsletter subscription delivered on a regular basis!
                            </p>
                            <form
                                action=""
                                className="relative z-[1] pt-[50px] text-center ltr:xl:text-left rtl:xl:text-right"
                                data-aos="fade-up"
                                data-aos-duration="1000"
                            >
                                <div className="relative inline-block w-full sm:w-auto">
                                    <input
                                        type="email"
                                        placeholder="Email Address"
                                        className="w-full rounded-3xl py-4 font-bold leading-5 outline-none ltr:pl-4 ltr:pr-[110px] rtl:pr-4 rtl:pl-[110px] dark:bg-gray-dark sm:w-[540px] sm:py-6 ltr:sm:pl-[30px] ltr:sm:pr-[140px] rtl:sm:pr-[30px] rtl:sm:pl-[140px]"
                                    />
                                    <button
                                        type="submit"
                                        className="absolute top-0 h-full bg-secondary px-4 font-extrabold uppercase text-white ltr:right-0 ltr:rounded-r-3xl rtl:left-0 rtl:rounded-l-3xl sm:px-7"
                                    >
                                        submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="absolute bottom-20 ltr:left-0 rtl:right-0">
                        <svg width="171" height="216" viewBox="0 0 171 216" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.06" d="M160.487 10.9038L47.9038 205.904L-64.6795 10.9038L160.487 10.9038Z" stroke="#7780A1" strokeWidth="20" />
                        </svg>
                    </div>
                </section>
                <Footer />
            </div>
        </>
    )
}
export default AboutUs