import { useState, useRef, useEffect, useContext } from "react"
import { getResultChat } from "../../redux/openai/openaiService";
import Layout from "../components/layouts/Layout"
import ChatForm from "../components/sections/ChatForm";
import Prompts from "../components/sections/Prompts"
import { Helmet } from "react-helmet-async";
import { RemainingWordsContext } from "../../App";
import { useDispatch, useSelector } from "react-redux";

import { reset, saveChat } from "../../redux/chat/chatSlice";

import { getOneChat } from "../../redux/chat/chatService";
import { chatPrompts } from "../../config/constant";

const Chat = () => {

    const dispatch = useDispatch();
    const { conversation } = useSelector( (state) => state.chat )

    const { setUserWords } = useContext(RemainingWordsContext);
    const [showJumpButton, setShowJumpButton] = useState(false);
    const [isNewChat, setIsNewChat] = useState(false);
    const [isHidden, setIsHidden] = useState(false);
    const [prompt, setPrompt] = useState("");
    const [chatId, setChatId] = useState(null);
    const [loading, setLoading] = useState(false);

    const lastMessageRef = useRef(null);
    const chatContainerRef = useRef(null);

    useEffect(() => {
        return () => {
            dispatch(reset())
        }
    }, [dispatch])

    const [chatLog, setChatLog] = useState([]);

    const checkScrollPosition = () => {
        const scrollTop = chatContainerRef.current.scrollTop;
        const scrollHeight = chatContainerRef.current.scrollHeight;
        const clientHeight = chatContainerRef.current.clientHeight;

        if (scrollHeight - scrollTop === clientHeight) {
            setShowJumpButton(false);
        } else {
            setShowJumpButton(true);
        }
    };

    const handleScroll = () => {
        checkScrollPosition();
    };

    useEffect(() => {
        checkScrollPosition();

        const chatContainer = chatContainerRef.current;
        chatContainer.addEventListener("scroll", handleScroll);

        return () => {
            chatContainer.removeEventListener("scroll", handleScroll);
        };
    }, [chatContainerRef]);

    useEffect(() => {
        const timer = setTimeout(() => {
            checkScrollPosition();
        }, 100);

        return () => {
            clearTimeout(timer);
        };
    }, [chatLog]);

    const jumpToLast = () => {
        chatContainerRef.current.scrollTo({
            top: lastMessageRef.current.offsetTop,
            behavior: 'smooth',
        });
    }

    const fetchChatById = async (id) => {
        try {
            const response = await getOneChat(id);
            setChatLog([...response.conversation]);
        } catch (error) {
            console.error("Error fetching chat data:", error);
        }
    };

    useEffect(() => {
        if (chatId) {
            fetchChatById(chatId);
        }
    }, [chatId]);


    const handleConversationClick = (conversation) => {
        console.log("chat", conversation._id)
        setChatId(conversation._id);
        setIsNewChat(false);
    };

    useEffect(() => {
        if (chatLog.length > 0 && !chatId) {
            dispatch(saveChat({ chatData: chatLog, conversId: conversation ? conversation._id : null }));
        }
    }, [chatLog]);

    const handleSubmit = async () => {
        setLoading(true);
        let chatLogNew = [...chatLog, { role: 'user', content: `${prompt}` }];
        setPrompt("");
        setChatLog(chatLogNew);
        const result = await getResultChat(chatLogNew);
        console.log("result", result)
        setChatLog([...chatLogNew, result.result]);
        setUserWords(result.remainingWords);
        setLoading(false);
    }
    const handleNewChatClick = () => {
        setIsNewChat(true);
    };
    return (
        <>
            <Helmet>
                <title>Chat with Doda.ai</title>
            </Helmet>
            <Layout>
                <div className="h-screen flex grow bg-white dark:bg-navy-900">
                    <main className="min-h-screen main-content w-full px-[var(--margin-x)] pb-8">
                        <div className="flex flex-col items-center justify-between space-y-4 py-5 sm:flex-row sm:space-y-0 lg:py-6">
                        </div>
                        <div className="flex">
                            <div className="w-full">
                                <div ref={chatContainerRef} className="relative overflow-y-scroll hide-scrollbar" style={{ height: '400px' }}>
                                    <div className="flex flex-col">
                                        <div className="flex-grow">
                                            <div className="p-4">
                                                <div className="flex flex-col space-y-4 items-start md:items-center justify-center md:mx-12">
                                                    <h1 className="w-full md:text-center text-black text-2xl md:text-3xl font-bold">
                                                        <span className="text-secondary font-bold">ברוכים הבאים לדודה</span>
                                                    </h1>
                                                    <p className="md:text-center text-gray/50 text-sm md:text-md w-full">
                                                        התחילו באמצעות כתיבת מטלת כתיבה ודודה תעשה את השאר. לא בטוחים איפה להתחיל? לחצו על ספריית הבקשות כדי לקבל השראה
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="h-full px-2">
                                                <ChatForm loading={loading} chatLog={chatLog} isNewChat={isNewChat} setChatLog={setChatLog} />
                                            </div>
                                            <div ref={lastMessageRef}></div>
                                        </div>
                                    </div>
                                </div>
                                <form onSubmit={handleSubmit} className="relative w-full">
                                    {showJumpButton && <div onClick={jumpToLast} className="absolute w-full flex items-center justify-center" style={{ zIndex: '3', top: '-3rem' }}>
                                        <div className="text-white bg-secondary btn translate-y-0 opacity-100 cursor-pointer flex items-center justify-center rounded-full px-8 py-2 text-sm text-light shadow-xl duration-200">
                                            <span className="mr-3 font-bold text-silver-150">↓</span>
                                            <span>ירידה לסוף</span>
                                        </div>
                                    </div>}
                                    <div className="m-5 mt-4">
                                        <div className="space-y-5">
                                            <div className="flex flex-col" style={{color:"rgb(180 118 229)"}}>
                                                <div onClick={()=>setIsHidden(!isHidden)} className="flex items-center cursor-pointer ">
                                                    <span className="ml-2 text-lg font-semibold mb-2">Hide prompts</span>
                                                    <ArrowDown isHidden={isHidden}/>
                                                </div>
                                              <div className={`${isHidden ? 'h-0 opacity-0 overflow-hidden' : 'h-auto opacity-100'} transition transition-opacity delay-200 flex flex-wrap`}>
                                              {chatPrompts.map((prompt, index) => {
                                                    let shortenedPrompt = prompt.substring(0, Math.floor(prompt.length * 0.20)) + "...";
                                                    return (
                                                        <div
                                                        onClick={()=>{setPrompt(prompt)}}
                                                            style={{backgroundColor:"rgb(180, 118, 229, 0.2)"}}
                                                            key={index}
                                                            className="border-2 font-semibold mb-2 border-secondary rounded-lg p-2 cursor-pointer ml-2 transition duration-200 hover:scale-90"
                                                        >
                                                            {shortenedPrompt}
                                                        </div>
                                                    );
                                                })}
                                                </div>
                                            </div>
                                            <div className="">
                                                <textarea
                                                    placeholder="כתוב על כל דבר"
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter' && !e.shiftKey) {
                                                            e.preventDefault();
                                                            handleSubmit();
                                                        }
                                                    }}
                                                    rows='4'
                                                    value={prompt}
                                                    autoComplete="off"
                                                    onChange={(e) => { setPrompt(e.target.value) }} name="prompt" 
                                                    className="relative resize-none font-semibold outline-0 block w-full p-4 text-md md:text-lg pl-10 text-gray-900 border border-gray/20 rounded-md  focus:ring-secondary focus:border-secondary" />
                                                {/* <p onClick={() => setShowModal(!showModal)} className="cursor-pointer pl-3 bg-secondary-600 btn text-white  hover:bg-secondary-700 focus:ring-4 focus:outline-none focus:ring-secondary font-medium rounded-md text-sm mt-2 px-4 py-2"><span>ספריית בקשות</span></p> */}
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </main>
                </div>
            </Layout>
            {/* <Prompts setPrompt={setPrompt} showModal={showModal} setShowModal={setShowModal} /> */}
        </>

    )
}
export default Chat



const ArrowDown = ({isHidden}) => {
    return (
      <svg
      className={`${isHidden ? '' : 'rotate-180'}  mb-2`}
        width="12"
        height="12"
        fill="rgb(180 118 229)"
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15 4.99623C15.0007 5.12789 14.9754 5.25841 14.9254 5.3803C14.8755 5.50219 14.8018 5.61305 14.7087 5.70653L8.68312 11.7091C8.49496 11.8954 8.24043 12 7.97511 12C7.7098 12 7.45527 11.8954 7.2671 11.7091L1.24148 5.70653C1.07696 5.51515 0.990986 5.26897 1.00075 5.01719C1.01051 4.76541 1.11529 4.52656 1.29414 4.34839C1.473 4.17022 1.71276 4.06585 1.96551 4.05612C2.21826 4.0464 2.46539 4.13204 2.6575 4.29593L7.97009 9.58818L13.2827 4.29593C13.4225 4.15545 13.6011 4.05944 13.7957 4.02004C13.9904 3.98064 14.1924 3.99961 14.3762 4.07457C14.56 4.14953 14.7174 4.2771 14.8284 4.44115C14.9394 4.60521 14.9991 4.79837 15 4.99623Z"
        />
      </svg>
    );
  };