import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { register, reset, signupGoogle } from "../../redux/auth/authSlice";
// import dashboardMeet from "/assets/images/illustrations/dashboard-meet.svg"
// import googleLogo from "/assets/images/googleLogo.png";

const Register = () => {
  const [formData, setFormDate] = useState({
    email: "",
    password: "", cpassword: ""
  })
  const { email, password, cpassword } = formData;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, isLoading, isError, isSuccess, message } = useSelector((state) => state.auth);

  useEffect(() => {
    if (isError) {
      toast.error(message)
    }
    if (isSuccess || user) {
      navigate('/projects')
    }
    dispatch(reset())
  }, [user, navigate, dispatch, isError, isSuccess, message])

  const handleChange = (e) => {
    setFormDate({ ...formData, [e.target.name]: e.target.value })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== cpassword) {
      toast.error("Password do not match");
    } else {
      const userData = { email, password };
      dispatch(register(userData))
    }
  }

  useEffect(() => {
    /* global google */
    if (window.google) {
      google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        callback: (response) => dispatch(signupGoogle(response.credential)),
      });

      google.accounts.id.renderButton(document.getElementById("signUpDiv"), {
        theme: "filled_black",
        text: "continue_with",
        shape: "pill",
      });
    }
  }, [dispatch]);



  if (isLoading) {
    return <div className="app-preloader fixed z-50 grid h-full w-full place-content-center bg-slate-50 dark:bg-navy-900">
      <div className="app-preloader-inner relative inline-block h-48 w-48"></div>
    </div>
  }
  return (
    <>
      <div className="min-h-100vh flex grow bg-[#181627] flex items-center justify-center">
        <main className="flex w-full flex-col border border-gray/20 rounded-md items-center bg-navy-700 lg:max-w-xl">
          <div className="flex w-full max-w-sm grow flex-col justify-center p-5">
          <div className="flex w-full mb-2 justify-center">
               <img className="w-20" src="/assets/images/newWhite.png" />
          </div>
               
            <div className="text-center">
              <div className="mt-4">
                <h2 className="text-2xl font-semibold text-navy-100">
                  ברוכים הבאים לדודה
                </h2>
                <p className="text-navy-300"> להירשם כדי להמשיך </p>
              </div>
            </div>

            <div className="mt-10 flex justify-center space-x-4 space-x-reverse">
            <div id="signUpDiv" data-text="signup_with"></div>
            </div>
            <div className="my-7 flex items-center space-x-3 space-x-reverse">
              <div className="h-px flex-1 bg-navy-500"></div>
              <p className="text-tiny+ uppercase">להירשם עם דוא״ל</p>
              <div className="h-px flex-1 bg-navy-500"></div>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="mt-4 space-y-4 text-[18px]">
                <label className="relative flex">
                  <input
                    className="form-input peer w-full rounded-lg bg-slate-150 px-8 py-2 pr-9 ring-primary/50 placeholder:text-slate-400 hover:bg-slate-200 focus:ring dark:bg-navy-900/90 dark:ring-accent/50 dark:placeholder:text-navy-300 dark:hover:bg-navy-900 dark:focus:bg-navy-900"
                    placeholder=" דוא״ל" value={email} name="email"
                    type="email" onChange={handleChange} required />
                  <span className="pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 transition-colors duration-200"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                      />
                    </svg>
                  </span>
                </label>
                <label className="relative flex">
                  <input
                    className="form-input peer w-full rounded-lg bg-slate-150 px-8 py-2 pr-9 ring-primary/50 placeholder:text-slate-400 hover:bg-slate-200 focus:ring dark:bg-navy-900/90 dark:ring-accent/50 dark:placeholder:text-navy-300 dark:hover:bg-navy-900 dark:focus:bg-navy-900"
                    placeholder="סיסמה" onChange={handleChange} value={password}
                    type="password" name="password" required />
                  <span
                    className="pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 transition-colors duration-200"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
                      />
                    </svg>
                  </span>
                </label>
                <label className="relative flex">
                  <input
                    className="form-input peer w-full rounded-lg bg-slate-150 px-8 py-2 pr-9 ring-primary/50 placeholder:text-slate-400 hover:bg-slate-200 focus:ring dark:bg-navy-900/90 dark:ring-accent/50 dark:placeholder:text-navy-300 dark:hover:bg-navy-900 dark:focus:bg-navy-900"
                    placeholder="אישור סיסמה" onChange={handleChange} value={cpassword}
                    type="password" name="cpassword"
                  />
                  <span
                    className="pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 transition-colors duration-200"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
                      />
                    </svg>
                  </span>
                </label>
                <div className="mt-4 flex items-center space-x-2 space-x-reverse">
                  <input
                    className="form-checkbox is-basic h-5 w-5 rounded border-slate-400/70 checked:border-primary checked:bg-primary hover:border-primary focus:border-primary dark:border-navy-400 dark:checked:border-accent dark:checked:bg-accent dark:hover:border-accent dark:focus:border-accent"
                    type="checkbox" required
                  />
                  <p className="line-clamp-1 text-slate-400 hover:underline dark:text-navy-300">
                    מסכימ/ה למדיניות הפרטיות
                  </p>
                </div>
              </div>
              <button className="admin-btn mt-10 h-10 w-full bg-secondary font-medium text-white hover:bg-secondary-focus focus:bg-secondary-focus active:bg-secondary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
              > להירשם
              </button>
            </form>
            <div className="mt-4 text-center text-xs+">
              <p className="line-clamp-1">
                <span> ?יש לך חשבון </span>
                <Link className="text-secondary transition-colors hover:text-secondary-focus dark:text-accent-light dark:hover:text-accent"
                  to="/login"
                >להיכנס</Link>
              </p>
            </div>
          </div>
        </main>
      </div>
    </>
  )
}
export default Register