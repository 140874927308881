import { Trans } from "react-i18next";
import { environment } from "../environment/conf";

const errMessage = (msgs, c) => {
  let message = msgs.find((x) => x === c);

  if (message) {
    return message;
  }
  return "";
};

const ErrorMsg = (props) => {
  const err = props.msg;

  if (!err || err === "") {
    return "";
  }

  const errorMessage = "error_" + err;
  return (
    <div className="cross-validation-error-message alert alert-danger">
      <span>
        <Trans>{errorMessage}</Trans>
      </span>
    </div>
  );
};

const PaymentForm = (props) => {
  const responseMsgs = props.msg;
  const imageSrc = process.env.PUBLIC_URL + "/assets/cards/" + props.img + ".png";
  return (
    <form
    id="payment_form"
    onSubmit={props.handleSubmit}
  >
        <div className="px-8 py-6 bg-white">
        <div className="flex items-center justify-between mb-10">
            <h1 className="text-center font-bold text-xl uppercase text-black">
              <Trans>header</Trans>
            </h1>
            <span onClick={()=>props.setShowModal(false)} className="text-black text-lg opacity-50 cursor-pointer px-2">X</span>
        </div>
        
          <div className="flex pt-4">
            <div className="md:w-1/3">
              <label htmlFor="payment_amount" className="h-auto">
                <Trans>lbl_amount</Trans>
              </label>
              <div className="flex">
                <input
                  type="text"
                  className="form-input p-2 text-right"
                  style={{backgroundColor: "rgba(119, 128, 161, .1)"}}
                  name="amount"
                  id="payment_amount"
                  value={props.data.amount}
                  disabled
                />
                <div className="flex items-center justify-center" style={{backgroundColor: "rgba(119, 128, 161, .1)"}}>
                  <span className="px-2 text-center">
                    {environment.currency_code}
                  </span>
                </div>
              </div>
              <ErrorMsg msg={errMessage(responseMsgs, "amount")}></ErrorMsg>
            </div>
          </div>
          <div className="flex pt-8">
            <div className="md:w-5/6 ml-2">
              <label htmlFor="credit_card_num">
                <Trans>lbl_card_number</Trans>
              </label>
              <div className="h-[40px] mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-secondary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent" id="credit_card_num"></div>
              <div id="errors_for_number" className="text-red-500"></div>
              <ErrorMsg msg={errMessage(responseMsgs, "credit_card_number")}></ErrorMsg>
            </div>
            <div className="md:w-1/6 pt-4">
              <img alt="Card" src={imageSrc} />
            </div>
          </div>
          <div className="flex pt-8">
            <div className="md:w-full">
              <label htmlFor="card_holder_id_number" className="h-auto">
                <Trans>lbl_card_holder_id</Trans>
              </label>
              <div className="h-[40px] mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-secondary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent" id="card_holder_id_number"></div>
              <div id="errors_for_id" className="text-red-500"></div>
              <ErrorMsg msg={errMessage(responseMsgs, "card_holder_id_number")}></ErrorMsg>
            </div>
          </div>
          <div className="flex pt-12">
            <div className="md:w-7/12 ml-2">
              <label htmlFor="expiry">
                <Trans>lbl_expiry</Trans>
              </label>
              <div className="h-[40px] mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-secondary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent" id="expiry"></div>
              <div id="errors_for_expiry" className="text-red-500"></div>
              <ErrorMsg msg={errMessage(responseMsgs, "expiry")}></ErrorMsg>
            </div>
            <div className="md:w-5/12">
              <label htmlFor="cvv">
                <Trans>lbl_cvv</Trans>
              </label>
              <div className="h-[40px] mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-secondary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent" id="cvv"></div>
              <div id="errors_for_cvv" className="text-red-500"></div>
              <ErrorMsg msg={errMessage(responseMsgs, "cvv")}></ErrorMsg>
            </div>
          </div>
  
          <div className="flex pt-8">
            <div className="md:w-full">
              <button
                type="submit"
                className="text-white text-lg mb-2 w-full px-4 py-2 rounded-md"
                style={{backgroundColor:"#22c55e"}} 
              >
                <Trans>submit</Trans>
              </button>
            </div>
          </div>
      </div>
    <div className="flex h-full justify-center align-middle py-4">
      <span>
        <Trans i18nKey="copyright_message"> </Trans>
      </span>
    </div>
  </form>  
  );
};

export default PaymentForm;
