import { useState } from "react";

import { Link } from "react-router-dom"
import { tools } from "../../../tools";

const ProjectTools = () => {
    const [searchQuery, setSearchQuery] = useState("");

    return (
        <>
            <div className="mt-12">
                <div className="flex mb-4">
                    <input type="text" placeholder="Search here..." onChange={(e) => setSearchQuery(e.target.value)}
                        className="form-input peer h-10 w-full
                    rounded-lg bg-slate-150 px-4 pl-9 text-xs+ text-slate-800 ring-primary/50 
                    hover:bg-slate-200 focus:ring dark:bg-navy-900/90 dark:text-navy-100 
                    dark:placeholder-navy-300 dark:ring-accent/50 dark:hover:bg-navy-900 dark:focus:bg-navy-900 w-80" />
                </div>
                <div className="flex justify-between mb-12">
                    <div>
                        <p className="text-xl font-medium text-secondary lg:text-2xl"> תבניות</p>
                    </div>
                    <div>
                    </div>
                </div>
                <div className="grid lg:grid-cols-4 grid-cols-1 gap-4">
                    {tools
                        .filter((tool) =>
                            tool.title.toLowerCase().includes(searchQuery.toLowerCase())
                        ).map((tool) => {
                            return <Link key={tool.id} to={tool.url} className="h-[400px] flex justify-center px-12 py-4 flex-col border border-gray/20 rounded-xl">
                                <span>
                                    <img className="w-[90px]" src={tool.image} alt={tool.title} />
                                </span>
                                <h3 className="my-6 whitespace-nowrap text-[22px] font-extrabold text-black dark:text-white">{tool.title}</h3>
                                <p className="text-gray/20">{tool.description}</p>
                            </Link>
                        })}

                </div>
            </div>
        </>
    )
}
export default ProjectTools