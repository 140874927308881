import { useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getResult, reset } from "../../redux/openai/openaiSlice";
import { Helmet } from "react-helmet-async";

import Layout from "../components/layouts/Layout"
import Results from "../components/sections/Results";
import SharedForm from "../components/sections/SharedForm";
import { RemainingWordsContext } from "../../App";


const Faq = () => {
    const { setUserWords } = useContext(RemainingWordsContext);

    const [formData, setFormData] = useState({
        title: "", description: "", tone: "משפיע ומרשים"
    });

    const dispatch = useDispatch();
    const { title, tone, description } = formData;

    useEffect(() => {
        dispatch(reset());
    }, [dispatch])

    // const { user } = useSelector((state) => state.auth)
    const { data, isLoading } = useSelector(
        (state) => state.openai
    )
    const handleSubmit = async (e) => {
        e.preventDefault();
        let prompt = `Write a ${tone ? `${tone}` : '' } 5 of clear and concise FAQ questions and answers related to ["${title}"], Here is more information about the topic [${description}].`;
        let Newprompt = { ...formData, prompt: prompt, project: "Bab" }
        dispatch(getResult(Newprompt));
        setUserWords(data.remainingWords);
    }
    return (
        <>
            <Helmet>
                <title>FAQ</title>
            </Helmet>
            <Layout>
                <div className="min-h-100vh flex grow bg-white dark:bg-navy-900">
                    <main className="main-content w-full px-[var(--margin-x)] pb-8">
                        <div className="flex flex-col items-center justify-between space-y-4 py-5 sm:flex-row sm:space-y-0 lg:py-6"
                        >
                            <div className="flex items-center space-x-1 space-x-reverse">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6"
                                    fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor">
                                    <path
                                        strokeLinecap="round" strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                                    />
                                </svg>
                                <h2 className="text-xl font-medium text-slate-700 line-clamp-1 dark:text-navy-50">
                                    FAQ
                                </h2>
                            </div>
                        </div>
                        <div className="grid grid-cols-12 gap-4 sm:gap-5 lg:gap-6">
                            <SharedForm titleLabel="Company/Product Name" placeHolderTitle="A Brand of jewelery" descLabel="Content description" placeHolderLabel="We create classic made jewelery" formData={formData} setFormData={setFormData} handleSubmit={handleSubmit} isLoading={isLoading} />
                            <Results isLoading={isLoading} data={data} />
                        </div>
                    </main>
                </div>
            </Layout>
        </>
    )
}
export default Faq