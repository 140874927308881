import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom";
import { logout, reset } from "../../../redux/auth/authSlice";
import { RemainingWordsContext, ThemeContext } from "../../../App";

const Header = ({ toggleSidebar }) => {
    const { user } = useSelector((state) => state.auth);
    // const { remainingWords, message } = useSelector((state) => state.openai);
    const { darkMode, setDarkMode } = useContext(ThemeContext);
    const { userWords } = useContext(RemainingWordsContext);
    const dispatch = useDispatch();


    const onLogout = () => {
        dispatch(logout(user));
        dispatch(reset())
    }

    return (
        <nav className="header print:hidden">
            <div className="header-container relative flex w-full bg-white dark:bg-navy-700 print:hidden">
                <div className="flex w-full items-center justify-between">
                    <div>
                        <button
                            onClick={toggleSidebar}
                            className="admin-btn h-7 w-7 rounded-full p-0 text-secondary hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:text-accent-light/80 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25 ">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                                stroke="currentColor" strokeWidth="2">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
                            </svg>
                        </button>
                    </div>
                    <div className="relative ml-4 flex">
                        {userWords > 0 && <p className="px-4 py-2 rounded-lg text-black text-md hover:scale-90 transition ease-in delay-300">מילים : <span className="text-secondary">{userWords ? userWords : user.words}</span></p>}
                        {(user.words < 0 || userWords < 0) && <p className="lg:block hidden px-4 py-2 rounded-lg text-primary font-bold text-md hover:scale-90 transition ease-in delay-300">Please upgrade your plan</p>}
                    </div>
                    <div className="-mr-1.5 flex items-center">
                        <div id="searchbar-wrapper" className="flex">
                            <div className="relative ml-4 flex">
                                <Link to="/billing" className="px-4 py-2 rounded-lg text-white bg-purple-500 text-md hover:scale-90 transition ease-in delay-300" style={{ backgroundColor: '#a855f7' }}> לשדרוג התכנית</Link>
                            </div>
                        </div>
                        <button onClick={() => setDarkMode(!darkMode)} className="darkmode-toggle admin-btn h-8 w-8 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25">
                            <svg className="darkmode-moon h-6 w-6 text-amber-400" fill="currentColor" viewBox="0 0 24 24">
                                <path d="M11.75 3.412a.818.818 0 01-.07.917 6.332 6.332 0 00-1.4 3.971c0 3.564 2.98 6.494 6.706 6.494a6.86 6.86 0 002.856-.617.818.818 0 011.1 1.047C19.593 18.614 16.218 21 12.283 21 7.18 21 3 16.973 3 11.956c0-4.563 3.46-8.31 7.925-8.948a.818.818 0 01.826.404z"></path>
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" className="darkmode-sun h-6 w-6 text-amber-400" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z" clipRule="evenodd"></path>
                            </svg>
                        </button>
                        {user && <p onClick={onLogout} className="cursor-pointer font-bold text-md mx-4">להתנתק</p>}
                    </div>
                </div>
            </div>
        </nav>
    )
}
export default Header