import { Link } from "react-router-dom"

const Templates = ({ showModal, setShowModal }) => {
    return (
        <>
            {showModal && <div className={`${showModal ? 'show' : ''} modal modal-scale fixed inset-0 z-[100] flex flex-col items-center overflow-hidden px-4 py-6 sm:px-5`}>
                <div className="modal-overlay  absolute inset-0 bg-slate-900/60"></div>
                <div className="modal-content relative flex w-full max-w-4xl mt-12 origin-bottom overflow-hidden rounded-lg bg-white dark:bg-navy-700">
                    <div className="lg:flex hidden rounded-t-lg bg-slate-200 px-4 py-3 dark:bg-navy-800 sm:px-5">
                        <div className=" px-4 py-2" style={{ width: '200px' }}>
                            <div className="flex flex-col">
                                <ul>
                                    <li><Link to="/" className="text-lg font-semibold">תבניות</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="w-full p-6">
                        <div className="flex justify-between">
                            <div className="">
                                <input type="text" className="border border-gray/20 rounded-md bg-white outline-0 px-4 py-2" placeholder="חיפוש" />
                            </div>
                            <button onClick={() => setShowModal(!showModal)} className="admin-btn -ml-1.5 h-7 w-7 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25" >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-4.5 w-4.5"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </button>
                        </div>
                        <div className="p-2">
                            <div className="grid lg:grid-cols-3 grid-cols-1 gap-4 mt-4">
                                <Link to="/video-scripts" className=" border border-secondary rounded-lg p-4 hover:border-secondary/50">
                                    <div className="pt-2 pb-4">
                                        <span><svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-slate-400 transition-colors group-hover:text-slate-500 group-focus:text-slate-500 dark:text-navy-300 dark:group-hover:text-navy-200 dark:group-focus:text-navy-200" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1.5">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"></path>
                                        </svg></span>
                                        <h3 className="text-lg font-bold text-dudu">תסריט לסרטון תוכן</h3>
                                        <p className="pb-4 text-sm text-gray-300"> להפוך לאוטוריתה בתחומך באמצעות תסריטים מעניינים, מגבשים ומניעים לפעולה.</p>
                                    </div>
                                </Link>
                                <Link to="/product-description" className=" border border-secondary rounded-lg p-4 hover:border-secondary/50">
                                    <span><svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1.5">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4"></path>
                                    </svg></span>
                                    <h3 className="text-lg font-bold text-dudu">תיאור מוצר</h3>
                                    <p className="pb-4 text-sm text-gray-300">ליצור תיאורים חדשים למוצר שלך - כאלה שיגרמו לקהל שלך להבין ולהתקדם לרכישה.</p>
                                </Link>
                                <Link to="/freestyle" className=" border border-secondary rounded-lg p-4 hover:border-secondary/50">
                                    <span><svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 rotate-45" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 11l5-5m0 0l5 5m-5-5v12"></path>
                                    </svg></span>
                                    <h3 className="text-lg font-bold text-dudu"> פריסטייל</h3>
                                    <p className="pb-4 text-sm text-gray-300">יצירת כל סוג תוכן טקסטואלי באמצעות הנחייה פשוטה לבינה מלאכותית גמישה</p>
                                </Link>
                                <Link to="/blog-introduction" className=" border border-secondary rounded-lg p-4 hover:border-secondary/50">
                                    <div className="pt-2 pb-4">
                                        <span><svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                        </svg></span>
                                        <h3 className="text-lg font-bold text-dudu">פסקת פתיחה למאמר</h3>
                                        <p className="pb-4 text-sm text-gray-300">כיצד מתחילים לכתוב מאמר? מכותרת! מקלידים כותרת ומקבלים פסקת פתיחה איכותית למאמר החדש שלכם.</p>
                                    </div>
                                </Link>
                                <Link to="/blog-outlines" className=" border border-secondary rounded-lg p-4 hover:border-secondary/50">
                                    <span><svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"></path>
                                    </svg></span>
                                    <h3 className="text-lg font-bold text-dudu">יצירת שלד מאמר</h3>
                                    <p className="pb-4 text-sm text-gray-300">ליצור שלד למאמר שלך ובסיס איכותי לתוכן</p>
                                </Link>
                                <Link to="/blog-conclusion" className=" border border-secondary rounded-lg p-4 hover:border-secondary/50">
                                    <span>Icon</span>
                                    <h3 className="text-lg font-bold text-dudu">יצירת סיכום למאמר</h3>
                                    <p className="pb-4 text-sm text-gray-300">הלקוחות שמגיעים לסוף המאמר - הם הלקוחות המתעניינים ביותר, צרו סיכום מושך למאמר שלכם בתוך שניות.</p>
                                </Link>

                            </div>
                        </div>
                    </div>
                    {/* <div className="text-center">
                        <button
                            data-close-modal
                            className="btn mt-4 border border-secondary/30 bg-dudu/10 font-medium text-dudu hover:bg-dudu/20 focus:bg-dudu/20 active:bg-dudu/25 dark:border-accent-light/30 dark:bg-accent-light/10 dark:text-accent-light dark:hover:bg-accent-light/20 dark:focus:bg-accent-light/20 dark:active:bg-accent-light/25"
                        >
                            Show All
                        </button>
                    </div> */}
                </div>
            </div>}
        </>
    )
}
export default Templates