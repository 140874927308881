export const chatPrompts = [
    "הסבר מדוע [כדאי לספק תוכן איכותי לעוקבים שלך באינסטגרם] הרחב על הנושא הבא [עשרת סוגים של שיווק דיגיטלי]",
    "צור קאפשנים לאינסטגרם או לרשתות חברתיות אחרות למותג [שם המותג] שמוכר [שם מוצר] ספק תוכן נוסף על היתרונות הבאים \n[יתרון 1] \n[יתרון 2] \n[יתרון 3]",
    "צור תסריטי וידאו עבור [מוצר או מותג ספציפי] שיעבירו ביעילות את התכונות וההיתרונות הבאים ללקוחות פוטנציאליים: [יתרון 1], [יתרון 2], [יתרון 3]",
    "צור תיאור למוצר שמעביר בצורה מדויקת ויעילה את ההיתרונות והתכונות של המוצר [שם המוצר] \n[תיאור המוצר (תאר/י את המוצר) ] \n[יתרון 1], [יתרון 2], [יתרון 3]",
    "כתוב מבוא בלוג מרתק על [כותרת הבלוג].",
    "כתוב קטע בבלוג על הנושא הבא [מתאר בלוג].",
    "צור סיכום למאמר על [נושא המאמר] והרחב על הסוגיות הבאות \n[סוגיה 1] \n[סוגיה 2] \nהרחב על [נושא]",
    "כתוב אימייל ל[קהל יעד] המקדם [מוצר או מותג ספציפי], תוך הדגשת הערך והיתרונות שלו. ",
    "צור עותק מודעה מרתק עבור [מוצר או מותג ספציפי] לשימוש בפלטפורמות מקוונות שונות, כולל Google Ads, Instagram, Taboola ורשתות חברתיות נוספות \n[שם המוצר] \n[פרטי המוצר] \n[תיאור המוצר]\n"
]

export const toolsRoutes = [
    {
        id: 'video-scripts',
        name: 'תסריט לסרטון תוכן',
        title: "כותרת לסרטון",
        titlePlaceholder:"שיווק דיגיטלי בtaboola",
        description: "פרטים נוספים על הסרטון",
        descriptionPlaceholder:""
      },
    {
        id: 'product-description',
        name: 'תיאור מוצר',
        title: "כותרת לסרטון:",
        titlePlaceholder:"משקה האנרגיה הבריא 'Energized'",
        description: "מידע על המוצר",
        descriptionPlaceholder:"משקה האנרגיה שלנו מועשר בקפאין איכותי מפולי קפה ובתמציות ג׳ינסנג ואשווגאנדה וללא חומרים משמרים"
      },
    {
        id: 'freestyle',
        name: ' הגדרת פקודה ',
        title: "כלי",
        titlePlaceholder:'תכתוב סיפור על ארנב החלל של המותג שלי ״ANDA" ואיך הוא עוזר לאנשים לפרסם את המותג שלהם בשלטי חוצות בכל העולם',
        description: "מידע נוסף על הנושא",
        descriptionPlaceholder:'הארנב "Ady" הוא הקמע של החברה'
      },
    {
        id: 'blog-ideas',
        name: 'יצירת רעיונות לתוכן ובלוג',
        title: "נושא המאמר",
        titlePlaceholder:" אוכל ומסעדנות, אתרי אינטרנט, בריאות, לייפסטייל, שיפור איכות החיים, מוצרים, כל דברe.g Food, health, covid19",
        description: "מידע נוסף על המאמר (מילות מפתח)",
        descriptionPlaceholder:"ככל שנקבל יותר נתונים, כך נספק תוצאה מקיפה וטובה יותר"
      },
    {
        id: 'blog-content',
        name: ' יצירת תוכן לבלוג',
        title: "מתאר בלוג",
        titlePlaceholder:"היתרונות של שיווק דיגיטלי",
        description: "מידע נוסף על המאמר (מילות מפתח)",
        descriptionPlaceholder:"ככל שנקבל יותר נתונים, כך נספק תוצאה מקיפה וטובה יותר"
      },
    {
        id: 'blog-introduction',
        name: 'יצירת מבוא למאמר',
        title: "כותרת למאמר",
        titlePlaceholder:"״שר המשקאות״ החלו במשלוחים בכל ישראל",
        description: "מידע נוסף על המאמר (מילות מפתח)",
        descriptionPlaceholder:"משקאות אלכוהוליים וערבובים במשלוחים לכל הארץ, גרייגוס פינלנדיה ועוד"
      },
    {
        id: 'blog-conclusion',
        name: 'יצירת סיכום למאמר',
        title: "קריאה לפעולה",
        titlePlaceholder:"היתרונות של מנועי מימן ברכבים חשמליים",
        description: "מהן הנקודות העיקריות בבלוג שלך?",
        descriptionPlaceholder:"״טויוטה משיקה את ״מיראי״ רכב שמונע באמצעות תא מימן"
      },
    {
        id: 'blog-outlines',
        name: 'יצירת קווי מתאר למאמר',
        title: "בניית תבנית למאמר (נקודות מנחה) ",
        titlePlaceholder:"פרסום לעסקים גדולים",
        description: "מידע נוסף על המאמר (מילות מפתח)",
        descriptionPlaceholder:"מדוע פרסום בשלטי חוצות דיגיטליים יכול להקפיץ את העסק שלך"
      },
    {
        id: 'before-after-bridge',
        name: 'מסגרת לפני אחרי גשר',
        title: "שם חברה/מוצר",
        titlePlaceholder:"מותג תכשיטים",
        description: "תיאור תוכן",
        descriptionPlaceholder:"אנו יוצרים תכשיטים קלאסיים"
      },
    {
        id: 'google-headline',
        name: 'כותרת גוגל',
        title: "שם חברה/מוצר",
        titlePlaceholder:"Pushpress",
        description: "תיאור תוכן",
        descriptionPlaceholder:""
      },
    {
        id: 'aida-framework',
        name: 'מסגרת aida',
        title: "שם חברה/מוצר",
        titlePlaceholder:"",
        description: "תיאור תוכן",
        descriptionPlaceholder:""
      },
      {
        id: 'facebook-ad',
        name: 'כותרת גוגל',
        title: "שם חברה/מוצר",
        titlePlaceholder:"Pushpress",
        description: "תיאור תוכן",
        descriptionPlaceholder:""
      },
    {
        id: 'marketing-angles',
        name: 'זוויות שיווק',
        title: "שם חברה/מוצר",
        titlePlaceholder:"Pushpress",
        description: "תיאור תוכן",
        descriptionPlaceholder:""
      },
    {
        id: 'headline',
        name: 'כותרת מושלמת',
        title: "שם חברה/מוצר",
        titlePlaceholder:"",
        description: "תיאור תוכן",
        descriptionPlaceholder:""
      },
    {
        id: 'email',
        name: 'אימייל',
        title: "קהל יעד",
        titlePlaceholder:"",
        description: "נושא האימייל",
        descriptionPlaceholder:""
      },
    {
        id: 'shopify-product-description',
        name: 'תיאור המוצר של Shopify',
        title: "שם חברה/מוצר",
        titlePlaceholder:"",
        description: "תיאור תוכן",
        descriptionPlaceholder:""
      },
   
]

// export const TextAreaTools = [
//   {
//       id: 'video-scripts',
//       name: 'תסריט לסרטון תוכן',
//       title: "כותרת לסרטון",
//       titlePlaceholder:"שיווק דיגיטלי בtaboola",
//       description: "פרטים נוספים על הסרטון",
//       descriptionPlaceholder:""
//     },

//   ]


// creative story + Text Summarizer +  Improve Existing Ad
