import { useState } from "react";
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from "react-redux";
import { forgotPasswordEmail } from "../../redux/auth/authSlice";

const ForgotPassForm = ({ showModal, setShowModal }) => {
    const [email, setEmail] = useState("");
    const [success, setSuccess] = useState(false);
    const { isError, message } = useSelector((state) => state.auth);

    const dispatch = useDispatch();
    const handleSubmit = (e) => {
        e.preventDefault();
        if (isError) {
            toast.error(message)
        } else {
            dispatch(forgotPasswordEmail(email))
            setSuccess(true);
            setEmail("");
            setTimeout(() => {
                setShowModal(false);
                setSuccess(false);
            }, 4000);
        }

    }
    return (
        <>
            {showModal ? (
                <>
                    <div className={` ${showModal ? 'show' : ''} modal fixed inset-0 z-[100] flex flex-col items-center justify-center overflow-hidden px-4 py-6 sm:px-5`}>
                        <div className="modal-overlay absolute inset-0 bg-slate-900/60 backdrop-blur"></div>
                        <div className="modal-content scrollbar-sm relative flex max-w-2xl flex-col items-center overflow-y-auto rounded-lg bg-white px-4 py-10 text-center dark:bg-navy-700 sm:px-5" style={{ width: "500px" }}>
                            {success && <div className="alert flex overflow-hidden rounded-lg border border-info text-info">
                                <div className="bg-info p-3 text-white">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-5 w-5"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                        />
                                    </svg>
                                </div>
                                <div className="px-4 py-3 sm:px-5">A reset Password email was sent successfully </div>
                            </div>}
                            <div className="mt-4 w-full">
                                <form onSubmit={handleSubmit}>
                                    <p className="text-lg text-black mb-2">דוא״ל</p>
                                    <label className="relative flex">
                                        <input
                                            className="form-input peer w-full rounded-lg bg-slate-150 px-8 py-2 pr-9 ring-secondary/50 placeholder:text-slate-400 hover:bg-slate-200 focus:ring dark:bg-navy-900/90 dark:ring-accent/50 dark:placeholder:text-navy-300 dark:hover:bg-navy-900 dark:focus:bg-navy-900"
                                            placeholder="דוא״ל" value={email} name="email"
                                            type="email" onChange={(e) => setEmail(e.target.value)} required />
                                        <span className="pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-secondary dark:text-navy-300 dark:peer-focus:text-accent">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="h-5 w-5 transition-colors duration-200"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="1.5"
                                                    d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                                                />
                                            </svg>
                                        </span>
                                    </label>
                                    <button className="btn mt-6 bg-success font-medium text-white hover:bg-success-focus focus:bg-success-focus active:bg-success-focus/90">
                                        שלח דוא״ל
                                    </button>
                                </form>
                                <p onClick={() => setShowModal(!showModal)} className="mt-2 cursor-pointer text-sm text-black">לסגור</p>
                            </div>
                        </div>
                    </div>
                </>
            ) : null}

        </>
    )
}
export default ForgotPassForm