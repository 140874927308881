import axios from 'axios';

const register = async (userData) => {
    const response = await axios.post(process.env.REACT_APP_BACKEND_API + '', userData);
    if (response.data) {
        localStorage.setItem('user__doda', JSON.stringify(response.data))
    }

    return response.data
}


const signupGoogleUser = async (credential) => {
    const response = await axios.post(process.env.REACT_APP_BACKEND_API + "auth/google/signup", {credential});
    if (response.data) {
        localStorage.setItem("user__doda", JSON.stringify(response.data));
        return response.data
    }
}

const loginGoogleUser = async (credential) => {
    const response = await axios.post(process.env.REACT_APP_BACKEND_API + "auth/google/login", {credential});
    if (response.data) {
        localStorage.setItem("user__doda", JSON.stringify(response.data));
        return response.data
    }
}


const login = async (userData) => {
    const response = await axios.post(process.env.REACT_APP_BACKEND_API + 'login', userData);
    if (response.data) {
        localStorage.setItem('user__doda', JSON.stringify(response.data))
    }

    return response.data
}
const updateUser = async (userData, token) => {
    const config = { withCredentials: true }
    const response = await axios.put(process.env.REACT_APP_BACKEND_API + 'auth/update', userData, config);
    if (response.data) {
        localStorage.setItem('user__doda', JSON.stringify(response.data))
    }
    return response.data
}
const logout = async (user) => {
    if (user && user.googleId) {
        await axios.get(process.env.REACT_APP_BACKEND_API + "auth/logout", { withCredentials: true });
    }
    localStorage.removeItem("user__doda");
}

export const fetchAuthUser = async () => {
    const response = await axios.get(process.env.REACT_APP_BACKEND_API + "auth/refresh", { withCredentials: true });
    if (response.data) {
        return response.data;
    }
}


const forgotPassword = async (email) => {
    await axios.post(process.env.REACT_APP_BACKEND_API + "forgot-password", { email });
}

export const resetPassword = async (formData) => {
    await axios.post(process.env.REACT_APP_BACKEND_API + "reset-password/" + formData.token, { password: formData.password });
}

const authService = {
    register, updateUser,signupGoogleUser,
    login, loginGoogleUser, resetPassword,
    logout, fetchAuthUser, forgotPassword
}

export default authService;
