import Footer from "../components/layouts/Footer"
import Header from "../components/layouts/Header"
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from "react";

const TermsOfServices = () => {
    useEffect(() => {
        AOS.init({
            once: true,
        });
    }, []);
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, []);
    return (
        <>
            <Header className='sticky-header' />
            <div style={{ backgroundImage: "url(/assets/images/inner-page-hero-bg.png)" }} className="bg-cover bg-bottom bg-no-repeat pt-[82px] lg:pt-[106px]">
                <div className="relative">
                    <div className="container">
                        <div className="items-center justify-between py-10 md:flex md:h-[400px] md:py-0">
                            <div className="heading relative mb-0 text-center rtl:md:text-right">
                                <h4 className="font-assistant !text-white">תנאי השימוש</h4>
                            </div>
                            <div className="relative mt-6 md:mt-0" data-aos='fade-right' data-aos-duration="1000">
                                <svg
                                    width="352"
                                    height="282"
                                    viewBox="0 0 352 282"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="mx-auto w-72 rtl:rotate-y-180 md:mx-0 md:w-auto"
                                >
                                    <path
                                        d="M69.9139 246.929C69.5259 245.765 69.3787 244.532 69.4811 243.309C69.5835 242.086 69.9334 240.896 70.5098 239.813C65.6259 235.638 60.1879 232.159 54.3505 229.473C50.1073 233.911 44.8199 240.295 42.7832 242.442C46.2885 240.129 78.4317 263.158 79.6936 268.907C80.2895 260.985 72.1351 253.891 69.9139 246.929Z"
                                        fill="#B476E5"
                                    />
                                    <path
                                        d="M163.291 233.994C161.893 232.347 161.179 230.227 161.293 228.071C155.237 227.067 149.079 226.818 142.961 227.333C142.882 229.292 142.698 231.247 142.407 233.186C141.741 237.43 140.795 239.11 140.129 243.071C141.145 239.25 180.615 246.331 182.437 250.748C181.028 242.267 168.304 240.164 163.291 233.994Z"
                                        fill="#B476E5"
                                    />
                                    <path
                                        d="M330.284 182.888C328.216 181.412 323.274 178.401 316.817 174.545L286.745 180.89L277.916 139.282L282.858 138.231C278.973 112.176 278.224 108.089 274.936 94.2048C265.369 104.411 242.232 109.646 232.277 106.859C232.539 107.645 232.733 108.15 233.609 110.259C217.374 145.697 214.856 152.182 214.365 158C213.812 164.826 214.54 169.393 227.58 220.044C235.513 222.257 248.772 218.527 253.098 213.944C249.453 198.206 245.737 182.537 241.706 162.032C242.202 160.663 242.789 159.329 243.459 158.036C246.994 147.737 249.276 137.051 250.259 126.208C256.768 155.707 258.681 165.76 263.158 170.76C267.68 175.772 272.936 178.962 316.684 203.598C323.244 201.899 331.417 190.168 330.284 182.888Z"
                                        fill="#B476E5"
                                    />
                                    <path
                                        d="M79.0613 169.568C57.7826 172.193 54.5245 172.569 36.4023 174.931L73.8385 201.143C75.4546 199.508 83.0352 191.837 84.6347 190.171C83.786 187.491 80.4099 174.958 79.0613 169.568Z"
                                        fill="#B476E5"
                                    />
                                    <path
                                        d="M49.2307 165.467C49.2307 165.446 49.3008 158.877 49.3008 158.877C47.6107 159.124 46.0833 160.021 45.0428 161.375C44.0023 162.729 43.5304 164.435 43.7274 166.133L44.6038 172.405L50.072 171.704L49.2307 165.467Z"
                                        fill="#B476E5"
                                    />
                                    <path
                                        d="M72.3987 162.627C72.197 161.032 71.4076 159.57 70.1848 158.526C68.8268 159.006 67.4135 159.312 65.9785 159.438C66.2755 160.729 66.3696 161.187 66.6796 162.733C66.9674 163.02 66.8807 162.958 67.7662 169.559L73.2344 168.858L72.3987 162.627Z"
                                        fill="#B476E5"
                                    />
                                    <path
                                        d="M330.247 186.323C328.784 192.874 324.903 198.632 319.381 202.447C319.934 209.598 322.991 225.897 325.901 230.279C331.62 223.444 339.08 198.837 339.466 189.408C336.475 188.149 333.393 187.118 330.247 186.323Z"
                                        fill="#47BDFF"
                                    />
                                    <path
                                        d="M251.101 215.627C245.364 219.023 238.805 220.78 232.138 220.71C226.885 227.432 222.775 234.974 219.975 243.033C229.014 242.016 249.349 232.902 256.079 225.997C254.791 222.374 253.123 218.898 251.101 215.627Z"
                                        fill="#47BDFF"
                                    />
                                    <path
                                        d="M77.8417 269.829C70.0342 267.927 39.5588 247.805 42.5383 242.653C45.2225 239.431 75.4987 261.194 79.2826 267.992C80.146 269.556 79.7106 270.287 77.8417 269.829Z"
                                        fill="#47BDFF"
                                    />
                                    <path
                                        d="M165.231 244.647C154.199 242.354 141.709 241.016 140.276 242.872C137.206 246.842 159.298 250.62 164.287 251.268C172.533 252.349 180.177 252.63 182.086 251.657C186.017 249.663 169.816 245.604 165.231 244.647Z"
                                        fill="#47BDFF"
                                    />
                                    <path
                                        d="M168.307 232.032C168.237 230.77 166.555 229.157 161.332 228.106C161.2 230.266 161.917 232.391 163.33 234.03C166.87 234.03 168.377 233.083 168.307 232.032Z"
                                        fill="#47BDFF"
                                    />
                                    <path
                                        d="M137.564 230.069C137.424 231.576 139.212 232.627 142.437 233.223C142.717 231.26 142.892 229.333 142.99 227.37C139.282 227.825 137.705 228.816 137.564 230.069Z"
                                        fill="#47BDFF"
                                    />
                                    <path
                                        d="M54.35 229.438C52.8427 230.98 51.0292 233.048 49.1973 235.116C46.4631 232.873 45.5075 230.349 46.4982 229.017L46.7085 228.772C47.725 227.825 50.2138 227.72 54.35 229.438Z"
                                        fill="#47BDFF"
                                    />
                                    <path
                                        d="M70.5531 239.813C74.8996 243.529 76.5527 246.27 75.9863 247.455C75.5011 248.473 73.3924 248.436 69.9222 246.929C69.5189 245.765 69.3667 244.531 69.4755 243.304C69.5842 242.077 69.9515 240.887 70.5531 239.813Z"
                                        fill="#47BDFF"
                                    />
                                    <path
                                        d="M232.902 108.576C230.611 102.167 232.666 108.447 226.838 91.9614C227.792 98.9053 228.155 105.918 227.925 112.923C230.035 111.135 231.753 109.593 232.902 108.576Z"
                                        fill="#47BDFF"
                                    />
                                    <path
                                        d="M273.671 89.0521C265.083 98.8299 240.094 105.176 230.416 101.987C231.082 103.809 231.707 105.492 232.239 106.894C242.408 109.741 265.493 104.237 274.898 94.2049C274.479 92.4522 274.093 90.6996 273.671 89.0521Z"
                                        fill="#47BDFF"
                                    />
                                    <path
                                        d="M250.261 126.208C249.278 137.051 246.996 147.738 243.461 158.036C245.284 154.425 248.333 149.237 253.239 139.703L250.261 126.208Z"
                                        fill="#47BDFF"
                                    />
                                    <path
                                        d="M312.827 108.401C312.133 106.935 311.569 105.41 311.145 103.844C313.266 103.935 314.395 104.457 314.834 105.136C315.64 106.372 314.377 107.898 312.827 108.401Z"
                                        fill="#47BDFF"
                                    />
                                    <path
                                        d="M295.37 107.104C292.846 108.261 291.864 109.418 291.899 110.259C292.004 111.346 293.336 112.047 295.755 111.626C296.32 110.499 295.6 108.346 295.37 107.104Z"
                                        fill="#47BDFF"
                                    />
                                    <path
                                        d="M270.484 76.4682C267.434 76.0476 263.508 75.6269 258.355 75.1362C263.237 77.6583 267.923 80.5406 272.377 83.7591C271.642 80.8848 270.974 78.361 270.484 76.4682Z"
                                        fill="#47BDFF"
                                    />
                                    <path
                                        d="M233.083 38.997C232.42 41.3572 231.426 43.6112 230.131 45.6921C230.201 44.7106 230.201 43.4782 230.306 41.9414C231.083 41.1352 231.994 40.1537 233.083 38.997Z"
                                        fill="#47BDFF"
                                    />
                                    <path
                                        d="M230.281 23.7842C230.071 21.3305 229.264 20.8324 222.184 20.3841C214.682 19.8583 214.122 21.0852 214.893 25.0812C215.419 27.7802 216.821 28.5514 219.66 28.5514C221.413 28.5514 223.719 28.2359 226.635 27.9204C228.879 30.7597 230.351 32.2319 230.351 30.5143C230.351 27.2544 233.751 27.3245 233.821 32.5437C235.083 33.8351 236.485 35.3829 237.992 36.8902C236.45 24.4853 236.135 24.31 230.281 23.7842Z"
                                        fill="#47BDFF"
                                    />
                                    <path
                                        d="M233.713 57.7852C234.547 56.5452 235.241 55.2167 235.781 53.8242C234.73 52.317 233.783 50.7396 232.829 49.0921C232.198 50.568 231.538 52.0716 230.972 53.5438C231.246 55.0131 231.743 56.4316 232.447 57.7501C232.027 61.711 229.854 90.0686 229.854 91.9264C231.036 94.3856 232.494 96.7024 234.2 98.8317C236.102 96.761 237.792 94.5066 239.248 92.1016C238.55 86.8437 234.274 59.678 233.713 57.7852Z"
                                        fill="#47BDFF"
                                    />
                                    <path
                                        d="M189.514 114.22C190.146 113.768 191.442 113.975 193.299 115.201C191.722 116.533 190.811 117.34 190.145 117.9C188.707 116.113 188.674 114.819 189.514 114.22Z"
                                        fill="#47BDFF"
                                    />
                                    <path
                                        d="M204.408 127.68C205.495 129.709 205.7 131.045 205.319 131.711C205.294 131.783 205.244 131.845 205.179 131.886C204.548 132.552 202.901 132.202 201.078 130.624C202.375 129.468 204.408 127.68 204.408 127.68Z"
                                        fill="#47BDFF"
                                    />
                                    <path
                                        d="M48.3902 143.594C48.3201 144.61 48.285 145.732 48.285 146.889C45.7262 146.994 44.5178 146.54 44.4643 145.872C44.4145 145.243 45.6911 144.33 48.3902 143.594Z"
                                        fill="#47BDFF"
                                    />
                                    <path
                                        d="M68.0908 143.524C68.1959 144.155 67.2846 144.926 64.5154 145.347C63.9378 144.372 63.6574 143.253 63.7092 142.122C66.5134 142.291 67.9856 142.893 68.0908 143.524Z"
                                        fill="#47BDFF"
                                    />
                                    <path
                                        d="M109.383 97.3946C108.579 107.23 108.293 117.646 108.092 122.387C92.0746 122.773 78.5461 125.261 72.3418 129.818C72.3768 131.711 72.4119 133.569 72.5171 135.353C81.8429 128.597 102.188 127.435 113.739 127.221C111.852 120.229 110.413 106.901 109.383 97.3946Z"
                                        fill="#47BDFF"
                                    />
                                    <path
                                        d="M73.8395 105.281C73.6292 107.104 73.4539 108.892 73.3137 110.609C71.6312 111.163 69.4229 111.87 67.2496 112.537C64.9712 113.237 62.7279 113.869 61.1855 114.325C65.5537 111.526 69.7771 108.508 73.8395 105.281Z"
                                        fill="#47BDFF"
                                    />
                                    <path
                                        d="M120.773 65.4618C120.662 65.6385 120.533 65.8031 120.388 65.9525C119.528 66.8473 116.787 66.7255 114.333 66.3399C113.808 64.8755 113.521 63.3365 113.482 61.7812C116.497 64.7958 119.582 67.1793 120.773 65.4618Z"
                                        fill="#47BDFF"
                                    />
                                    <path
                                        d="M121.687 41.1352C121.897 37.2794 117.095 38.0506 116.706 40.2239C116.417 39.7173 115.97 39.3193 115.434 39.0911C114.897 38.8627 114.301 38.8168 113.735 38.9599C113.17 39.1031 112.667 39.4276 112.304 39.8838C111.941 40.3401 111.737 40.9028 111.725 41.4857C110.007 39.4564 106.502 40.1943 107.098 43.2734C104.679 40.9249 98.4748 42.327 108.389 61.2905C110.457 59.5378 112.35 57.8202 113.998 56.2779C111.193 51.8964 114.418 49.8209 115.855 52.6675C116.627 54.1748 118.069 54.0346 117.885 49.7932C119.018 49.501 120.121 49.1019 121.18 48.6014C123.269 47.6771 125.047 46.1675 126.297 44.2549C128.137 41.3105 124.596 37.8052 121.687 41.1352Z"
                                        fill="#47BDFF"
                                    />
                                    <path
                                        d="M112.713 167.92C114.852 185.762 115.377 192.317 115.272 196.033C110.993 183.939 106.041 172.095 100.439 160.556C103.81 162.663 107.876 165.046 112.713 167.92Z"
                                        fill="#47BDFF"
                                    />
                                    <path
                                        d="M316.404 123.263L317.766 129.746L323.164 128.59L321.551 121.053C321.269 119.753 320.529 118.597 319.468 117.794C318.408 116.99 317.096 116.592 315.768 116.672C316.253 118.834 316.465 121.048 316.404 123.263Z"
                                        fill="#47BDFF"
                                    />
                                    <path
                                        d="M297.719 120.459L297.368 120.529C296.652 120.676 295.972 120.963 295.367 121.375C294.763 121.787 294.247 122.315 293.848 122.928C293.452 123.541 293.179 124.228 293.049 124.947C292.918 125.667 292.933 126.405 293.092 127.119L294.634 134.655L300.032 133.499C299.97 133.198 297.925 121.075 297.719 120.459Z"
                                        fill="#47BDFF"
                                    />
                                    <path
                                        d="M316.404 123.263L317.978 130.725L323.376 129.568L321.551 121.053C321.269 119.753 320.529 118.597 319.468 117.794C318.408 116.99 317.096 116.592 315.768 116.672C316.253 118.834 316.465 121.048 316.404 123.263Z"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M297.719 120.459L297.368 120.529C296.652 120.676 295.972 120.963 295.367 121.375C294.763 121.787 294.247 122.315 293.848 122.928C293.452 123.541 293.179 124.228 293.049 124.948C292.918 125.667 292.933 126.405 293.092 127.119L294.826 135.677L300.224 134.52C300.161 134.219 297.925 121.075 297.719 120.459Z"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M46.6749 137.88C48.0419 137.251 48.0419 140.264 46.9202 140.614C45.7985 140.965 45.8318 138.268 46.6749 137.88Z"
                                        fill="#47BDFF"
                                    />
                                    <path
                                        d="M157.616 139.423C158.328 138.061 161.221 138.227 160.631 139.563C160.04 140.899 156.753 141.078 157.616 139.423Z"
                                        fill="#47BDFF"
                                    />
                                    <path
                                        d="M207.18 126.033C207.46 127.47 210.04 125.479 209.599 124.28C209.158 123.081 206.865 124.42 207.18 126.033Z"
                                        fill="#47BDFF"
                                    />
                                    <path
                                        d="M309.708 97.009C311.075 96.9924 311.922 100.129 310.234 100.094C308.546 100.059 308.236 97.0256 309.708 97.009Z"
                                        fill="#47BDFF"
                                    />
                                    <path
                                        d="M341.64 128.27L341.991 151.331C341.994 151.446 341.958 151.556 341.887 151.647C341.817 151.735 341.716 151.798 341.605 151.822L333.543 153.54L334.63 158.587L329.407 159.709L328.32 154.661L301.4 160.38L302.487 165.428L297.264 166.55L296.179 161.506L288.117 163.223C288.008 163.238 287.895 163.218 287.798 163.168C287.698 163.118 287.617 163.039 287.563 162.943L278.523 141.727L286.825 180.916L350.025 167.491L341.64 128.27Z"
                                        fill="#47BDFF"
                                    />
                                    <path
                                        d="M340.623 125.927L278.404 139.177L288.219 162.207L295.931 160.559L294.879 155.652L300.102 154.53L301.153 159.438L328.074 153.724L327.057 148.817L332.28 147.695L333.332 152.602L341.008 150.99L340.623 125.927Z"
                                        fill="#47BDFF"
                                    />
                                    <path
                                        d="M286.827 180.916L277.926 139.278L341.116 125.817L350.027 167.491L286.827 180.916Z"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path d="M288.699 136.955L293.653 129.924" stroke="white" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round" />
                                    <path
                                        d="M314.054 100.865L351.526 47.3395L285.346 1L253.834 45.9725"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path d="M248.576 194.385L264.28 171.916" stroke="white" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round" />
                                    <path
                                        d="M100.828 220.044L187.899 281L230.242 220.569"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M57.0491 90.7697L47.3395 83.9695L1 150.149L73.8392 201.15"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M184.993 144.295L168.063 168.481L162.139 164.345"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path d="M180.889 192.212L196.101 189.513" stroke="white" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M187.162 183.238L189.826 198.486" stroke="white" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M275.777 221.13L297.054 217.38" stroke="white" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M284.541 208.616L288.292 229.928" stroke="white" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round" />
                                    <path
                                        d="M108.085 266.033C110.815 266.033 113.027 263.821 113.027 261.09C113.027 258.36 110.815 256.148 108.085 256.148C105.355 256.148 103.143 258.36 103.143 261.09C103.143 263.821 105.355 266.033 108.085 266.033Z"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M62.0276 213.134L34.8268 216.535L29.7441 175.763L79.0982 169.595"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M121.165 48.666C123.286 47.7484 125.089 46.2264 126.349 44.29C128.137 41.3382 124.667 37.8053 121.722 41.1703C121.932 37.2795 117.165 38.0857 116.741 40.2589C116.45 39.7564 116.002 39.3625 115.467 39.1371C114.932 38.9116 114.337 38.8668 113.774 39.0094C113.211 39.1522 112.71 39.4745 112.346 39.9276C111.983 40.3809 111.777 40.9401 111.76 41.5208C110.042 39.4915 106.537 40.2294 107.133 43.3085C104.714 40.96 98.51 42.3621 108.424 61.3255"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M114.335 66.3398C113.82 64.8741 113.544 63.3347 113.52 61.7811C116.287 64.5761 119.163 66.8582 120.519 65.8085"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M75.802 89.5428C71.9813 117.725 71.1234 133.713 74.2246 148.922C77.0491 162.771 82.0764 181.486 84.6703 190.214C72.6122 202.517 48.9868 226.108 46.498 229.052"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M49.1968 235.151C46.4627 232.908 45.5218 230.39 46.4978 229.052C47.8741 227.167 53.2666 227.678 63.8138 234.771C73.1414 241.044 76.7924 245.781 75.977 247.501C75.4918 248.519 73.3831 248.482 69.9129 246.975"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M142.403 233.223C139.178 232.627 137.374 231.574 137.531 230.069C137.754 227.929 142.228 226.597 153.971 227.264C165.04 227.894 168.176 230.277 168.307 232.032C168.385 233.083 166.87 234.03 163.295 234.03"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M75.9414 247.449C112.571 207.629 114.167 205.069 115.053 198.516C115.534 194.974 115.403 190.524 112.704 167.88"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M42.5739 242.688C44.8191 238.832 82.1833 265.822 79.5894 269.713C77.5637 272.763 39.2457 248.403 42.5739 242.688Z"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M140.124 243.178C140.465 238.972 183.131 246.754 182.502 251.135C182.01 254.57 139.62 249.381 140.124 243.178Z"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M54.348 229.438C50.5974 233.328 44.6034 240.507 42.5703 242.652"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M70.5506 239.813C65.1876 249.523 81.2767 258.742 79.5905 269.713"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M140.123 243.178C141.034 237.675 142.507 236.588 142.997 227.37"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M161.331 228.106C160.778 239.638 181.136 240.024 182.538 251.135"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M137.563 230.069C138.019 218.501 136.617 187.304 136.056 182.222C102.195 160.524 89.1559 156.458 87.0527 146.889"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M168.306 232.031C166.168 175.807 165.539 169.289 161.12 162.697C156.805 156.25 153.233 153.514 134.41 141.981"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M44.4639 145.872C42.3607 117.795 42.5729 106.87 47.0578 100.228C53.5148 90.6958 72.2606 80.3516 91.9952 75.138C116.777 68.5813 126.837 69.4945 131.359 73.5606C135.049 76.8925 136.156 82.1171 137.248 116.74C144.03 117.542 159.789 119.443 162.977 120.005C167.498 123.966 168.27 137.532 162.977 142.544C138.23 142.544 126.939 142.269 121.965 139.144C114.283 134.316 112.851 129.715 109.346 97.4314C108.542 107.248 108.226 117.62 108.055 122.374"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path d="M135.215 116.498L137.244 116.738" stroke="white" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round" />
                                    <path
                                        d="M48.2489 146.889C45.69 146.959 44.4946 146.538 44.4281 145.872C44.3193 144.786 47.9685 143.059 55.7852 142.297C63.7772 141.517 67.8783 142.437 68.0536 143.489C68.1587 144.12 67.2474 144.891 64.4782 145.311"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M73.3176 110.609C69.8474 111.766 64.1689 113.484 61.1895 114.299C65.5708 111.533 69.7952 108.525 73.8434 105.291"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M68.0913 143.524C67.7407 136.163 67.25 120.494 67.25 112.53"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M43.7988 136.338C46.8484 131.956 64.7252 130.414 67.6696 133.386"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M152.566 118.601C156.982 122.632 156.562 137.95 150.953 142.507"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M72.5059 135.357C79.7248 130.136 94.8583 127.472 113.707 127.224"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M72.3301 129.818C79.0915 124.837 94.653 122.319 112.617 122.317"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M63.7093 142.087C63.6516 143.352 64.022 144.599 64.7609 145.627C65.3144 146.328 69.0041 149.617 69.8085 150.815C70.694 152.141 71.1405 157.72 70.4394 158.316C69.528 159.089 63.3938 159.718 60.9402 159.718C59.1875 159.718 59.6082 156.739 65.3918 155.266C60.5952 155.547 57.3648 154.145 56.5586 149.973"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M48.3919 143.594C48.1117 148.747 48.2054 153.912 48.6723 159.052"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M53.3327 159.508C53.1482 156.318 56.9486 155.897 57.3287 158.954C57.6128 161.233 57.753 162.845 57.9246 164.673C58.0408 165.227 57.9325 165.802 57.6235 166.277C57.3145 166.749 56.8302 167.081 56.2771 167.197C55.724 167.313 55.1473 167.205 54.6739 166.896C54.2007 166.587 53.8695 166.103 53.7533 165.55C53.5098 163.544 53.3694 161.528 53.3327 159.508Z"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M65.9882 159.367C66.3037 160.843 66.5583 162.03 66.8295 163.328C66.9582 163.854 66.8826 164.41 66.6179 164.88C66.3531 165.352 65.919 165.706 65.4034 165.871C64.8879 166.035 64.3291 165.996 63.8404 165.764C63.3517 165.533 62.9694 165.123 62.7707 164.62C62.3617 163.006 62.0571 161.365 61.8594 159.712"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M49.3042 159.508C49.3042 156.423 52.994 156.213 53.1951 159.192C53.3427 161.406 53.3796 162.978 53.4404 164.727C53.508 165.264 53.3635 165.804 53.0379 166.236C52.7123 166.668 52.2311 166.955 51.6969 167.037C51.1626 167.12 50.6172 166.991 50.1769 166.677C49.7367 166.363 49.4362 165.889 49.3393 165.358C49.1659 163.889 49.3042 162.312 49.3042 159.508Z"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M61.78 159.718C62.0604 161.401 62.2412 162.733 62.446 164.205C62.5699 164.756 62.4749 165.334 62.1807 165.815C61.8864 166.297 61.4162 166.646 60.8693 166.786C60.3227 166.928 59.7425 166.85 59.2518 166.572C58.7608 166.293 58.398 165.834 58.2397 165.291C57.9035 163.26 57.6696 161.212 57.5386 159.157C57.2545 156.423 59.9222 155.582 61.114 157.194"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M180.747 141C181.16 138.089 177.893 137.246 177.172 139.983C176.886 141.068 176.508 142.542 176.26 143.673C176.131 144.171 176.202 144.703 176.46 145.149C176.718 145.596 177.14 145.922 177.637 146.06C178.133 146.199 178.664 146.136 179.114 145.885C179.565 145.636 179.9 145.219 180.046 144.725C180.343 143.496 180.577 142.253 180.747 141Z"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M177.035 140.124C177.79 137.227 174.358 136.159 173.46 138.897C173.104 139.982 172.604 141.522 172.373 142.297C171.552 145.051 175.286 146.26 176.054 143.839C176.476 142.507 176.806 141 177.035 140.124Z"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M173.247 139.282C174.131 136.504 171.081 135.325 169.812 137.88C169.464 138.583 168.927 139.84 168.445 141.105C168.213 141.57 168.175 142.109 168.339 142.601C168.503 143.094 168.857 143.502 169.321 143.734C169.786 143.967 170.324 144.005 170.817 143.841C171.31 143.677 171.718 143.323 171.95 142.858C172.327 142.048 172.958 140.19 173.247 139.282Z"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M184.429 141.526C184.523 138.687 181.441 138.021 180.924 140.72C180.71 141.841 180.389 144.04 180.328 144.645C180.35 145.097 180.531 145.525 180.839 145.858C181.147 146.188 181.562 146.4 182.011 146.453C182.461 146.509 182.915 146.402 183.293 146.155C183.671 145.907 183.95 145.535 184.079 145.101C184.263 143.917 184.379 142.723 184.429 141.526Z"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M156.774 227.51C155.298 179.873 155.828 172.092 152.357 166.939C147.448 159.648 141.552 156.405 114.15 140.159C109.662 137.498 108.331 134.515 108.121 127.429"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M100.439 160.524C106.023 172.082 110.975 183.936 115.272 196.033"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M219.693 28.5162C222.112 42.3528 222.813 44.4282 224.986 44.6755C227.125 44.919 228.456 44.0445 233.118 38.9969C232.596 41.4211 231.604 43.7191 230.196 45.7602"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M230.277 23.7842C230.032 21.3656 229.226 20.8748 222.215 20.3841C214.714 19.8583 214.153 21.0852 214.924 25.0812C215.73 29.4627 218.955 28.7617 226.632 27.9204C228.875 30.7597 230.347 32.2669 230.347 30.5143C230.347 27.2544 233.747 27.3245 233.817 32.5437C235.026 34.2362 236.428 35.7838 237.991 37.1558C236.446 24.7325 236.135 24.31 230.277 23.7842Z"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M230.282 41.9507C230.52 45.087 230.127 48.8874 227.859 50.1788C221.234 53.9995 218.574 55.5437 216.327 58.7316C212.023 64.8363 212.296 68.8969 211.49 96.0276C203.357 103.038 193.225 110.925 189.512 114.255"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M190.145 117.9C188.67 116.113 188.67 114.825 189.514 114.255C190.679 113.462 194.031 114.749 199.154 120.599C204.652 126.872 206.048 130.479 205.323 131.746C204.825 132.619 203.045 132.377 201.08 130.659"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M295.791 111.626C293.373 112.047 292.028 111.347 291.935 110.259C291.814 108.824 294.603 106.593 302.697 104.826C311.779 102.844 314.843 104.088 315.035 105.667C315.026 106.305 314.803 106.921 314.402 107.417C314 107.913 313.443 108.26 312.821 108.401"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M291.936 110.294C286.082 84.7407 285.479 82.1191 283.067 80.3591C279.765 77.9497 277.459 76.994 258.32 75.2063C263.202 77.7285 267.888 80.6107 272.341 83.8293"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M316.788 174.545C323.245 178.436 328.215 181.416 330.283 182.888C331.51 190.636 322.501 202.377 316.683 203.604C272.937 178.962 267.683 175.768 263.157 170.765C258.85 165.994 257.339 158.251 250.223 126.248C248.671 126.156 247.144 125.813 245.701 125.232"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M270.484 76.5033C272.447 84.0045 276.967 101.461 279.072 114.045C280.227 120.95 281.631 130.279 282.858 138.231"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M221.341 63.2183C220.937 67.1104 220.937 71.0337 221.341 74.9258C221.63 77.5935 231.471 105.182 233.609 110.259C217.374 145.627 214.83 152.182 214.365 157.965C213.812 164.791 214.54 169.357 227.58 220.008C235.537 222.357 249.313 218.151 253.063 213.909C249.418 198.171 245.702 182.467 241.671 161.996C243.038 158.071 246.193 153.268 253.203 139.673"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M330.249 186.288C333.4 187.105 336.492 188.135 339.503 189.373C339.118 198.802 331.651 223.409 325.938 230.244C323.028 225.862 319.979 209.563 319.418 202.412"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M251.064 215.592C253.086 218.865 254.756 222.342 256.046 225.967C249.315 232.873 228.985 241.986 219.941 243.003C222.779 234.97 226.885 227.443 232.105 220.71"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M205.283 131.807C212.753 126.016 228.126 112.868 232.942 108.541"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M197.576 107.525C204.131 108.051 213.385 120.214 212.719 125.822"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M289.723 100.584C292.198 95.2139 309.177 91.9596 312.767 94.8652"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M230.035 46.6385C230.806 47.6445 231.786 48.4701 232.909 49.0571C237.018 47.2761 240.881 44.9765 244.405 42.2145"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M246.509 43.5538C245.033 48.391 239.884 55.682 238.657 57.2944C236.362 54.8568 234.425 52.1064 232.901 49.1253C231.588 51.8417 230.553 54.6852 229.817 57.6117C229.139 54.416 229.079 51.1202 229.641 47.9021"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M231.055 53.5531C231.336 55.0209 231.832 56.4385 232.531 57.7594C232.111 61.6853 229.938 90.0447 229.938 91.9356C231.107 94.4021 232.566 96.7203 234.284 98.841C236.168 96.7648 237.846 94.5107 239.297 92.1109C238.596 86.853 234.315 59.6873 233.762 57.7963C234.568 56.5471 235.249 55.2201 235.791 53.8353"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M230.455 101.987C240.059 105.176 265.052 98.8318 273.675 89.0521"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M232.275 106.894C242.406 109.768 265.961 104.126 274.934 94.2398"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M227.966 112.923C228.198 105.918 227.835 98.9052 226.879 91.9614"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M250.224 126.173C249.241 137.016 246.959 147.702 243.424 158"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M242.336 107.49C244.365 116.043 244.474 116.849 247.454 116.673"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M308.689 176.263C318.154 182.257 326.706 187.655 329.441 189.408"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M267.682 99.8133C271.818 117.34 276.515 140.965 277.987 156.598C278.934 157.264 280.371 158.211 282.193 159.403"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M193.299 115.201C189.899 118.041 189.654 118.677 188.181 118.742C184.711 118.895 181.776 117.767 180.575 119.092C178.322 121.706 176.249 124.47 174.371 127.364C172.858 129.578 177 131.115 182.082 125.226C183.58 126.003 185.224 126.457 186.908 126.559C188.593 126.662 190.279 126.411 191.86 125.822"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M164.941 122.736C169.323 123.367 170.231 123.571 171.496 122.841C174.502 121.109 175.739 118.967 177.455 119.476C178.158 119.681 178.857 119.896 179.593 120.142"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M204.373 127.68C202.831 129.047 199.115 132.342 197.547 133.884"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M188.496 126.558C197.61 131.255 199.327 134.27 196.278 137.039"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M190.738 133.113C195.961 135.777 197.564 137.279 195.891 139.57C194.941 140.862 193.647 140.902 188.705 137.467"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M164.66 140.447C166.027 140.587 167.675 140.797 168.551 140.937"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M186.531 140.649C188.214 141.771 192.034 145.417 193.822 140.264"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M184.219 144.12C185.761 144.505 188.565 145.487 189.582 142.644"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M311.145 103.809C312.021 107.63 314.403 110.539 315.941 117.277C316.432 119.901 316.515 122.584 316.187 125.234C316.045 125.59 315.829 125.913 315.552 126.179C315.275 126.446 314.947 126.651 314.585 126.78C314.224 126.909 313.838 126.96 313.456 126.929C313.074 126.898 312.702 126.786 312.366 126.601"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M312.439 121.581C312.58 123.108 312.58 124.645 312.439 126.173C312.264 127.787 310.333 129.189 308.012 127.649"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M308.059 122.843C308.17 124.23 308.194 125.624 308.13 127.014C308.093 127.54 307.903 128.044 307.583 128.464C307.264 128.883 306.829 129.201 306.333 129.377C305.835 129.553 305.298 129.581 304.785 129.456C304.272 129.331 303.807 129.059 303.447 128.674"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M303.362 123.929C303.576 125.285 303.647 126.66 303.573 128.031C303.536 128.626 303.276 129.185 302.842 129.596C302.41 130.006 301.837 130.237 301.241 130.242C300.645 130.246 300.067 130.024 299.628 129.62C299.189 129.216 298.92 128.66 298.876 128.066C298.579 124.738 297.992 121.442 297.123 118.216"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M295.371 107.104C295.77 108.362 295.956 109.676 295.925 110.995C295.784 112.502 292.875 115.552 292.875 117.234C292.967 118.95 293.524 120.608 294.487 122.031"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M301.119 121.826C303.398 121.125 310.934 119.197 313.282 118.777"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M117.724 70.1569C116.186 69.3145 114.987 67.9648 114.333 66.338C116.783 66.7217 119.548 66.8601 120.388 65.9506C121.864 64.4101 122.316 60.7683 121.162 48.6641C120.114 49.1545 119.021 49.5436 117.899 49.8264C118.074 54.0696 116.633 54.2098 115.87 52.7007C114.427 49.8651 111.208 51.9295 114.012 56.3111C106.758 63.0578 95.087 73.0662 82.3574 78.0898"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M314.997 105.667C308.724 73.7341 305.035 66.2679 298.277 60.0285C286.818 49.4076 273.672 48.3874 251.164 45.657C248.642 45.3286 242.477 41.2662 237.986 37.1577"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path d="M301.277 159.925L328.198 154.211" stroke="white" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round" />
                                    <path
                                        d="M300.106 154.53L294.883 155.652L297.266 166.55L302.489 165.428L300.106 154.53Z"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M332.281 147.695L327.059 148.817L329.407 159.709L334.63 158.587L332.281 147.695Z"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M333.441 153.112L341.522 151.385L341.19 126.173"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M296.043 161.069L287.992 162.747L277.998 139.619"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M49.2307 165.467C49.2307 165.446 49.3008 158.877 49.3008 158.877C47.6107 159.124 46.0833 160.021 45.0428 161.375C44.0023 162.729 43.5304 164.435 43.7274 166.133L44.7619 173.829L50.2301 173.128L49.2307 165.467Z"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M72.3987 162.627C72.197 161.032 71.4076 159.57 70.1848 158.526C68.8268 159.006 67.4135 159.312 65.9785 159.438C66.2755 160.729 66.3696 161.187 66.6796 162.733C66.9674 163.02 67.0388 164.336 67.9243 170.937L73.3925 170.236L72.3987 162.627Z"
                                        stroke="white"
                                        strokeWidth="0.75"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="py-14 md:py-[100px]">
                <div className="container">
                    <div className="pb-12">
                        <h4 className="pb-5 text-xl font-bold text-black dark:text-white sm:text-2xl">תנאי שימוש עבור Doda.ai
                        </h4>
                        <h4 className="pb-5 text-lg font-bold text-black dark:text-white sm:text-xl">עדכון אחרון: 22 במאי 2023</h4>
                        <h4 className="pb-5 text-xl font-bold text-black dark:text-white sm:text-2xl">מבוא</h4>
                        <p className="pb-5 text-lg font-medium">
                        ברוכים הבאים ל-Doda.ai, פלטפורמת קופירייטינג עברית חדשנית המופעלת על ידי חברת דודה בע"מ ("דודה", "אנחנו", "אנחנו", או "שלנו"). תנאי שימוש אלה ("תנאים") מסדירים את הגישה והשימוש שלך באתר, בפלטפורמה ובשירותים שלנו (יחד, "שירותים"). על ידי גישה או שימוש בשירותים שלנו, אתה מסכים לציית לתנאים אלה. אם אינך מסכים לתנאים אלה, אנא הימנע משימוש בשירותים שלנו.
                        </p>
                    </div>
                    <div className="pb-12">
                        <h4 className="pb-5 text-xl font-bold text-black dark:text-white sm:text-2xl">זכאות</h4>
                        <p className="pb-5 text-lg font-medium">
                        ברוכים הבאים ל-Doda.ai, פלטפורמת קופירייטינג עברית חדשנית המופעלת על ידי חברת דודה בע"מ ("דודה", "אנחנו", "אנחנו", או "שלנו"). תנאי שימוש אלה ("תנאים") מסדירים את הגישה והשימוש שלך באתר, בפלטפורמה ובשירותים שלנו (יחד, "שירותים"). על ידי גישה או שימוש בשירותים שלנו, אתה מסכים לציית לתנאים אלה. אם אינך מסכים לתנאים אלה, אנא הימנע משימוש בשירותים שלנו.
                        </p>
                    </div>
                    <div className="pb-12">
                        <h4 className="pb-5 text-xl font-bold text-black dark:text-white sm:text-2xl">זכאות</h4>
                        <p className="pb-5 text-lg font-medium">
                        על ידי שימוש בשירותים שלנו, אתה מתחייב שאתה בן 18 לפחות, בעל יכולת משפטית להיכנס לתנאים אלה, ואינו מוגבל מלהשתמש בשירותים שלנו על פי כל החוקים או התקנות החלים.
                        </p>
                    </div>
                    <div className="pb-12">
                        <h4 className="pb-5 text-xl font-bold text-black dark:text-white sm:text-2xl">  רישום חשבון ואבטחה</h4>
                        <p className="pb-5 text-lg font-medium">
                        כדי לגשת לתכונות ספציפיות של השירותים שלנו, ייתכן שתצטרך ליצור חשבון עם Doda.ai. אתה מסכים לספק מידע מדויק, עדכני ומלא במהלך יצירת החשבון ולעדכן את המידע שלך כנדרש. אתה אחראי על שמירת הסודיות של אישורי החשבון שלך ועל כל הפעילויות המתרחשות תחת חשבונך. אתה מסכים ליידע אותנו באופן מיידי על כל גישה או שימוש בלתי מורשית בחשבונך.
                        </p>
                    </div>
                  
                    <div className="pb-12">
                        <h4 className="pb-5 text-xl font-bold text-black dark:text-white sm:text-2xl"> 
                        רישום חשבון ואבטחה
                        </h4>
                        <p className="pb-5 text-lg font-medium">
                        כדי לגשת לתכונות ספציפיות של השירותים שלנו, ייתכן שתצטרך ליצור חשבון עם Doda.ai. אתה מסכים לספק מידע מדויק, עדכני ומלא במהלך יצירת החשבון ולעדכן את המידע שלך כנדרש. אתה אחראי על שמירת הסודיות של אישורי החשבון שלך ועל כל הפעילויות המתרחשות תחת חשבונך. אתה מסכים ליידע אותנו באופן מיידי על כל גישה או שימוש בלתי מורשית בחשבונך.
                        </p>
                        <h6 className="pb-2 font-semibold text-black">
                        אתה מסכים לא לשלוח תוכן משתמש כלשהו ש: 
                        </h6>
                        <ul className="pb-5 text-lg font-medium">
                            <li className="relative before:absolute before:top-3 before:h-1.5 before:w-1.5 before:rounded-full before:bg-gray ltr:pl-4 ltr:before:left-0 rtl:pr-4 rtl:before:right-0">
                            א. מפר את הקניין הרוחני או זכויות אחרות של צד שלישי כלשהו.
                            </li>
                            <li className="relative before:absolute before:top-3 before:h-1.5 before:w-1.5 before:rounded-full before:bg-gray ltr:pl-4 ltr:before:left-0 rtl:pr-4 rtl:before:right-0">
                            ב. האם לשון הרע, מגונה, פוגענית או בלתי חוקית אחרת.
                            </li>
                            <li className="relative before:absolute before:top-3 before:h-1.5 before:w-1.5 before:rounded-full before:bg-gray ltr:pl-4 ltr:before:left-0 rtl:pr-4 rtl:before:right-0">
                            ג. מכיל וירוסים או רכיבים מזיקים אחרים שעלולים להזיק לשירותים שלנו או למכשירים של המשתמשים.
                            </li>
                            <li className="relative before:absolute before:top-3 before:h-1.5 before:w-1.5 before:rounded-full before:bg-gray ltr:pl-4 ltr:before:left-0 rtl:pr-4 rtl:before:right-0">
                            אתה האחראי הבלעדי לתוכן המשתמש ולהתנהלות שלך בזמן השימוש בשירותים שלנו. Doda שומרת לעצמה את הזכות להסיר או לשנות תוכן משתמש לפי שיקול דעתנו וללא הודעה מוקדמת.
                            </li>
                        </ul>
                    </div>
                    <div className="pb-12">
                        <h4 className="pb-5 text-xl font-bold text-black dark:text-white sm:text-2xl"> מחויבות נגישות
                        </h4>
                        <p className="text-lg font-medium">
                        Doda.ai מחויבת לספק אתר אינטרנט ופלטפורמה הנגישים לקהל הרחב ביותר האפשרי, כולל אנשים עם מוגבלויות. אנו שואפים ללא הרף לשפר את הנגישות והשימושיות של השירותים שלנו בהתאם לתקנות שוויון זכויות לאנשים עם מוגבלות (התאמות נגישות לשירות) ולהנחיות נגישות תוכן אינטרנט (WCAG) 2.0.
                        </p>
                        <p className="text-lg font-medium">
                        נקטנו בצעדים שונים כדי להבטיח שהשירותים שלנו יהיו נגישים, כולל שילוב אלמנטים עיצוביים נגישים, אספקת טקסט חלופי לתמונות, הבטחת ניגודיות צבע נכונה והצעת ניווט במקלדת. אם אתה נתקל בקושי כלשהו בשימוש בשירותים שלנו או שיש לך הצעות לשיפור הנגישות, אנא צור איתנו קשר בכתובת [etopalipl@gmail.com]
                        </p>
                    </div>
                    <div className="pb-12">
                        <h4 className="pb-5 text-xl font-bold text-black dark:text-white sm:text-2xl">  קניין רוחני  </h4>
                        <p className="pb-5 text-lg font-medium">
                        כל זכויות הקניין הרוחני בשירותים שלנו, לרבות הפלטפורמה, התוכנה, העיצוב, הטקסט, התמונות וחומרים אחרים, הן בבעלות Doda או של נותני הרישיונות שלנו. אינך רשאי להעתיק, לשכפל, להפיץ או ליצור יצירות נגזרות מהשירותים שלנו ללא הסכמתנו מראש ובכתב.
                        </p>
                    </div>
                    <div className="pb-12">
                        <h4 className="pb-5 text-xl font-bold text-black dark:text-white sm:text-2xl">  מנוי ותשלום  </h4>
                        <p className="pb-5 text-lg font-medium">
                        Doda מציעה תוכניות מנוי לגישה לתכונות הפרימיום של השירותים שלנו. על ידי הרשמה לתוכנית, אתה מסכים לשלם את העמלות והמיסים הרלוונטיים ולציית לכל תנאים והגבלות נוספים החלים על התוכנית שלך. אנו עשויים לשנות את תוכניות המנויים והתמחור שלנו בכל עת; עם זאת, שינויים כאלה לא ישפיעו על המנוי הקיים שלך עד תום מחזור החיוב הנוכחי שלך.
                        </p>
                    </div>
                    <div className="pb-12">
                        <h4 className="pb-5 text-xl font-bold text-black dark:text-white sm:text-2xl">סיום </h4>
                        <p className="pb-5 text-lg font-medium">
                        אנו שומרים לעצמנו את הזכות להפסיק או להשעות את הגישה שלך לשירותים שלנו, עם או בלי הודעה מוקדמת, אם אנו סבורים שהפרת תנאים אלה או מכל סיבה אחרת. אתה יכול גם לבטל את החשבון והמנוי שלך בכל עת על ידי יצירת קשר בכתובת [etopalipl@gmail.com]
                        </p>
                    </div>
                    <div className="pb-12">
                        <h4 className="pb-5 text-xl font-bold text-black dark:text-white sm:text-2xl">כתבי ויתור והגבלת אחריות</h4>
                        <p className="pb-5 text-lg font-medium">
                        השירותים שלנו ניתנים "כפי שהם" ו"כפי שהם זמינים" ללא אחריות מכל סוג שהוא, מפורש או משתמע. איננו מבטיחים שהשירותים שלנו יהיו ללא הפרעה, ללא שגיאות או מאובטחים לחלוטין. במידה המרבית המותרת בחוק, אנו מתנערים מכל אחריות, לרבות אחריות משתמעת לסחירות, התאמה למטרה מסוימת ואי-הפרה.
                        </p>
                        <p className="pb-5 text-lg font-medium">
                        בשום מקרה, דודה, החברות הקשורות לה, או הדירקטורים, נושאי המשרה, העובדים או הסוכנים שלהם, בהתאמה, יהיו אחראים לכל נזק ישיר, עקיף, מקרי, מיוחד, תוצאתי או עונשי, לרבות אובדן רווחים, אובדן נתונים או נזקים אחרים הנובעים מהשימוש שלך או חוסר היכולת להשתמש בשירותים שלנו.
                        </p>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default TermsOfServices