import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SharedForm from "./SharedForm";
import { getOneHistory } from "../../../redux/history/historyService";
import Layout from "../layouts/Layout"
import CKEditorForm from "./CKEditorForm";
import Results from "./Results";

const EditSharedForm = ({ isLoading }) => {

    const [formData, setFormData] = useState({ title: "", description: "", tone: "" })

    const id = useParams();
    const token = JSON.parse(localStorage.getItem("user__doda")).token;

    useEffect(() => {
        let actualId = id.id;
        const setEditedData = async () => {
            if (actualId) {
                const response = await getOneHistory(actualId, token);
                setFormData({ ...formData, ...response })
            }
        }
        setEditedData();
    }, [id, token])

    const handleSubmit = async (e) => {
        e.preventDefault();
        // dispatch(putHistory(token, id, formData));
    }
    return (
        <>
            <Layout>
                <div className="min-h-100vh flex grow bg-white dark:bg-navy-900">
                    <main className="main-content w-full px-[var(--margin-x)] pb-8">
                        <div className="flex flex-col items-center justify-between space-y-4 py-5 sm:flex-row sm:space-y-0 lg:py-6">
                            <div className="flex items-center space-x-1 space-x-reverse">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                        strokeWidth="2" d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                                    />
                                </svg>
                                <h2 className="text-xl font-medium text-slate-700 line-clamp-1 dark:text-navy-50">פריסטייל</h2>
                            </div>
                        </div>
                        <div className="grid grid-cols-12 gap-4 sm:gap-5 lg:gap-6">
                            {id.type === "פריסטייל" && <SharedForm isEdit={true} titleLabel=" כלי" placeHolderTitle="דוא״ל מכירתי (קופי לאימייל), מודעה" descLabel=" מידע נוסף על הנושא" placeHolderLabel="ככל שנקבל יותר נתונים, כך נספק תוצאה מקיפה וטובה יותר" formData={formData} setFormData={setFormData} handleSubmit={handleSubmit} isLoading={isLoading} />}
                            {id.type === "תיאור מוצר" && <SharedForm isEdit={true} titleLabel="שם המוצר" placeHolderTitle="Ladiz, FaceWasher" descLabel="מידע על המוצר" placeHolderLabel="" formData={formData} setFormData={setFormData} handleSubmit={handleSubmit} isLoading={isLoading} />}
                            {id.type === "תסריט לסרטון תוכן" && <SharedForm isEdit={true} titleLabel="Video title:" placeHolderTitle=" שיווק דיגיטלי ב2023" descLabel="Info about the video" placeHolderLabel="" formData={formData} setFormData={setFormData} handleSubmit={handleSubmit} isLoading={isLoading} />}
                            {id.type === "יצירת רעיונות לתוכן ובלוג" && <SharedForm isEdit={true} titleLabel="נושא המאמר" placeHolderTitle="אוכל ומסעדנות, אתרי אינטרנט, בריאות, לייפסטייל, שיפור איכות החיים, מוצרים, כל דבר" descLabel="מידע נוסף על המאמר (מילות מפתח)" placeHolderLabel="ככל שנקבל יותר נתונים, כך נספק תוצאה מקיפה וטובה יותר" formData={formData} setFormData={setFormData} handleSubmit={handleSubmit} isLoading={isLoading} />}
                            {id.type === "יצירת מבוא לבלוג" && <SharedForm isEdit={true} titleLabel="כותרת למאמר" placeHolderTitle=" שיווק דיגיטלי ב2023" descLabel="מידע נוסף על המאמר (מילות מפתח)" placeHolderLabel="ככל שנקבל יותר נתונים, כך נספק תוצאה מקיפה וטובה יותר" formData={formData} setFormData={setFormData} handleSubmit={handleSubmit} isLoading={isLoading} />}
                            {id.type === "יצירת קווי מתאר לבלוג" && <SharedForm isEdit={true} titleLabel="כותרת למאמר" placeHolderTitle=" שיווק דיגיטלי ב2023" descLabel="מידע נוסף על המאמר (מילות מפתח)" placeHolderLabel="ככל שנקבל יותר נתונים, כך נספק תוצאה מקיפה וטובה יותר" formData={formData} setFormData={setFormData} handleSubmit={handleSubmit} isLoading={isLoading} />}
                            {id.type === "יצירת תוכן לבלוג" && <SharedForm isEdit={true} titleLabel="בניית תבנית למאמר (נקודות מנחה) " placeHolderTitle=" המפתח לכתיבת תוכן ברשתות החברתיות" descLabel="מידע נוסף על המאמר (מילות מפתח)" placeHolderLabel="ככל שנקבל יותר נתונים, כך נספק תוצאה מקיפה וטובה יותר" formData={formData} setFormData={setFormData} handleSubmit={handleSubmit} isLoading={isLoading} />}
                            {id.type === "יצירת סיכום לבלוג" && <SharedForm isEdit={true} titleLabel="כותרת למאמר" placeHolderTitle=" שיווק דיגיטלי ב2023" descLabel="מידע נוסף על המאמר (מילות מפתח)" placeHolderLabel="ככל שנקבל יותר נתונים, כך נספק תוצאה מקיפה וטובה יותר" formData={formData} setFormData={setFormData} handleSubmit={handleSubmit} isLoading={isLoading} />}
                            <CKEditorForm />
                        </div>
                        <Results isLoading={isLoading} data={{ result: formData.outputs }} />
                    </main>
                </div>
            </Layout>
        </>
    )
}
export default EditSharedForm