import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom";
import { getHistories, reset } from "../../redux/history/historySlice";

import Layout from "../components/layouts/Layout";
import Spinner from "../components/layouts/Spinner";
import ProjectItems from "../components/sections/ProjectItems";
import ProjectTools from "../components/sections/ProjectTools";

const Projects = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { user } = useSelector((state) => state.auth)
    const { histories, isLoading, isError, message } = useSelector(
        (state) => state.history
    )

    useEffect(() => {
        dispatch(getHistories())
        return () => {
            dispatch(reset())
        }
    }, [user, navigate, dispatch])

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, []);

    return (
        <Layout>
            <div className="min-h-100vh flex grow bg-white dark:bg-navy-900">
                <main className="main-content w-full px-[var(--margin-x)] pb-8">
                    <div className="flex items-center justify-between py-5 lg:py-6">
                        <div className="flex items-center space-x-1 space-x-reverse">
                            <h2 className="text-xl font-medium text-secondary line-clamp-1 dark:text-navy-50 lg:text-2xl">
                                פרויקטים
                            </h2>
                        </div>
                    </div>
                    {histories.length > 0 ? (<>

                        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-5 lg:grid-cols-3 lg:gap-6 xl:grid-cols-4">
                            {histories && histories.map((item) => {
                                return <ProjectItems key={item._id} history={item} />
                            })}
                        </div>

                        {histories.length > 0 && isLoading && <div className="flex justify-center items-center mt-6 w-full">
                            <Spinner /> </div>}
                    </>
                    ) : (
                        <>
                            <div className="justify-center items-center flex my-6">
                                <h3 className="text-xl font-normal text-gray/50">אין לך פרויקטים כרגע</h3>
                            </div>
                        </>
                    )}
                </main>
            </div>
        </Layout>
    )
}
export default Projects