import { Link } from "react-router-dom"

const Publicity = () => {
  return (
    <>
       <section className="bg-[#1E1457] py-14 dark:bg-none lg:py-20">
                <div className="container">
                    <div className="mt-16 grid gap-x-7 gap-y-12 text-lg font-semibold sm:grid-cols-1 lg:grid-cols-3 lg:gap-y-20">
                        <div className="md:col-span-2 flex gap-5" data-aos="fade-up" data-aos-duration="1000">
                            <div className="flex-1">
                                <h3 className="mb-6 text-[60px] lg:text-[100px] leading-[70px] lg:leading-[90px] font-extrabold dark:text-black text-white">צרו טקסטים עשירים ומגוונים</h3>
                                <p className="font-semibold  text-[45px] lg:text-[80px] leading-normal text-[#B2B6F4]">שפרו את הפרודוקטיביות שלכם ואפשרו ל-Doda AI לעשות את הכתיבה.</p>
                                <p className="font-medium text-[20px] lg:text-[30px] leading-[30px] text-[#B2B6F4]">לא משנה מה צרכי הקופירייטינג שלך, יש לנו תבנית לכך. מפוסטים בבלוג ועד לתיאורי מוצרים וקופי לאתר.
בנוף דיגיטלי שבו התוכן הוא המלך, עותק (קופי) משכנע, מרתק ובנוי היטב הוא כבר לא רק אופציה - זה הכרחי. זה המקום שבו Doda AI נכנסת לתמונה. בתור השותף החכם שלך לקופירייטינג, Doda AI נועדה להפוך את יצירת התוכן לא רק לפשוטה יותר, אלא גם לחכמה, מהירה ויעילה יותר.</p>
                            </div>
                        </div>

                        <div className="flex items-center justify-center w-full" data-aos="fade-up" data-aos-duration="1000">
                           <img src="/assets/images/brain.png" alt="Doda AI" className="w-full" />
                        </div>
                    </div>
                </div>
            </section>
    </>
  )
}

export default Publicity