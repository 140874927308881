import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';


const Header = (props) => {
    const [showMenu, setShowMenu] = useState(false);

    const toggleMenu = () => {
        if (window.innerWidth < 1024) {
            setShowMenu(!showMenu);
        } else {
            setShowMenu(false);
        }
    };
    const { user } = useSelector((state) => state.auth)
    
    const [showSearch, setShowSearch] = useState(false);
    const toggleSearch = () => {
        setShowSearch(!showSearch);
    };

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        section.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <header className={`sticky top-0 z-50 bg-black/10 transition duration-300 ${props.className}`}>
            <div className="container text-[22px]">
                <div className="flex items-center py-5 lg:py-0">
                    <Link to="/" className='lg:flex hidden font-bold ml-5'>
                        <img src="/assets/images/lastLogo.png" alt="Doda logo" className="w-full"/>
                        {/* <img src="/assets/images/newWhite.png" alt="Doda logo" className="w-[80px]"/> */}
                    </Link>
                    <div className="flex items-center">
                        <div onClick={() => toggleMenu()} className={`overlay fixed inset-0 z-[51] bg-black/60 ${showMenu ? '' : 'hidden'}`}></div>
                        <div className={`menus ${showMenu ? 'open-menus' : ''}`}>
                            <div className="border-b border-gray/10 text-left lg:hidden">
                                <button onClick={() => toggleMenu()} type="button" className="p-4">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth="1.5"
                                        stroke="currentColor"
                                        className="h-6 w-6 text-black dark:text-white"
                                    >
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </button>
                            </div>
                            <ul onClick={() => toggleMenu()}>

                                {user && <li>
                                    <Link to="/projects">הפלטפורמה</Link>
                                </li>}

                                <li>
                                    <Link to="/blogs">בלוג</Link>
                                </li>
                                <li>
                                    <Link to="/about-us">על דודה</Link>
                                </li>
                                <li>
                                    <Link className='cursor-pointer' onClick={() => scrollToSection('pricing')}>מחירון</Link>
                                </li>
                                <li>
                                    <Link className='cursor-pointer' onClick={() => scrollToSection('contact')}>צור קשר</Link>
                                </li>
                                {!user && <li>
                                    <Link to="/login">להיכנס</Link>
                                </li>}

                                <li className={`${showSearch ? 'w-full' : ''}
                  search-bar absolute hidden w-0 overflow-hidden bg-black transition-all duration-500 left-0 lg:block`}
                                >
                                    <form className="relative">
                                        <input
                                            type="text"
                                            placeholder="Search"
                                            className="w-full border-b border-white bg-transparent py-1 outline-none pr-2 pl-8"
                                        />
                                        <button
                                            type="button"
                                            className="absolute top-1/2 -translate-y-1/2 hover:text-primary left-0"
                                            onClick={() => toggleSearch()}
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                strokeWidth="1.5"
                                                stroke="currentColor"
                                                className="h-6 w-6"
                                            >
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                            </svg>
                                        </button>
                                    </form>
                                </li>
                            </ul>
                        </div>
                        <button
                            type="button"
                            className="flex h-10 w-10 items-center justify-center rounded-full bg-primary lg:hidden"
                            onClick={() => toggleMenu()}
                        >
                            <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg" className="text-white">
                                <path
                                    d="M2 15H11C11.552 15 12 15.447 12 16C12 16.553 11.552 17 11 17H2C1.448 17 1 16.553 1 16C1 15.447 1.448 15 2 15Z"
                                    fill="currentColor"
                                />
                                <path
                                    d="M2 8H20C20.552 8 21 8.447 21 9C21 9.553 20.552 10 20 10H2C1.448 10 1 9.553 1 9C1 8.447 1.448 8 2 8Z"
                                    fill="currentColor"
                                />
                                <path
                                    d="M21 2C21 1.447 20.552 1 20 1H7C6.448 1 6 1.447 6 2C6 2.553 6.448 3 7 3H20C20.552 3 21 2.553 21 2Z"
                                    fill="currentColor"
                                />
                            </svg>
                        </button>
                    </div>
                    <div className='w-full justify-end flex lg:hidden'>
                    <Link to="/" className='font-bold mr-5'>
                        <img src="/assets/images/newWhite.png" alt="Doda logo" className="w-[40px]"/>
                    </Link>
                    </div>

                </div>
            </div>
        </header>
    );
};

export default Header;
