import { Link } from "react-router-dom";
// import { useCountdown } from "../hooks/useCountDown";
import DateTimeDisplay from "../layouts/DateTimeDisplay ";

const Pricing = ({ targetDate }) => {
  // const [days, hours, minutes, seconds] = useCountdown(targetDate);

  return (
    <section
      id="pricing"
      className="bg-gradient-to-b from-white/60 to-transparent py-14 dark:from-white/[0.02] lg:py-[100px]"
    >
      <div className="container">
        <div className="heading  text-center">
          <h4>הצטרף להצלחת השיווק של אלפי ישראלים</h4>
          <div className="w-full my-1 flex justify-center">
            <img
              src="/assets/images/faces.png"
              className="w-[150px]"
              alt="faces"
            />
          </div>
          <h4>
            בנו מסרים שיווקיים ותכני טקסט מתקדמים עם Doda.ai, פלטפורמת
            הקופירייטינג העברית הראשונה בעולם.
          </h4>
        </div>
        <div className="grid grid-cols-1 gap-[30px] md:grid-cols-2 lg:grid-cols-3">
          <div
            className="flex flex-col flex-grow"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div className="flex flex-col justify-between h-full group rounded-3xl border-2 border-primary bg-white p-6 px-4 transition hover:bg-primary hover:drop-shadow-[-10px_30px_70px_rgba(40,38,77,0.25)] dark:border-white/10 dark:bg-transparent dark:bg-gradient-to-b dark:from-white/[0.01] dark:to-transparent dark:drop-shadow-none dark:hover:border-gray-dark dark:hover:bg-gray-dark sm:px-6">
              <div>
                <div className="mb-8">
                  <h3 className="text-[22px] font-black text-black dark:text-white">
                    נסה בחינם
                  </h3>
                </div>
                <ul className="space-y-5 pb-7 text-sm font-bold group-hover:text-white">
                  <li className="flex items-center">
                    <div>
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="8" cy="8" r="8" fill="#45B649" />
                        <path
                          d="M5.11438 8.11438L7 10L10.7712 6.22876"
                          stroke="white"
                          strokeWidth="1.6"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    <p className="pr-3">כתיבה שיווקית מבוססת AI</p>
                  </li>
                  <li className="flex items-center">
                    <div>
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="8" cy="8" r="8" fill="#45B649" />
                        <path
                          d="M5.11438 8.11438L7 10L10.7712 6.22876"
                          stroke="white"
                          strokeWidth="1.6"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    <p className="pr-3">עוזר כתיבה בצ׳אט</p>
                  </li>
                  <li className="flex items-center">
                    <div>
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="8" cy="8" r="8" fill="#45B649" />
                        <path
                          d="M5.11438 8.11438L7 10L10.7712 6.22876"
                          stroke="white"
                          strokeWidth="1.6"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    <p className="pr-3">כולל 3000 מילים בחודש</p>
                  </li>
                  <li className="flex items-center">
                    <div>
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="8" cy="8" r="8" fill="#45B649" />
                        <path
                          d="M5.11438 8.11438L7 10L10.7712 6.22876"
                          stroke="white"
                          strokeWidth="1.6"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    <p className="pr-3">תכונות בסיסיות כלולות</p>
                  </li>
                  <li className="flex items-center">
                    <div>
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="8" cy="8" r="8" fill="#45B649" />
                        <path
                          d="M5.11438 8.11438L7 10L10.7712 6.22876"
                          stroke="white"
                          strokeWidth="1.6"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    <p className="pr-3">
                      שדרג ל-פרו או ביזנס לקבלת תכונות נוספות ומילים נוספות
                    </p>
                  </li>
                </ul>
              </div>
              <div className="border-t-2 border-[#BBC0D0]/50 pt-7">
                <div className="mb-3 flex flex-col items-center justify-center sm:flex-row sm:justify-between">
                  <Link
                    to="/sign-up"
                    className="btn text-xl text-white group-hover:bg-white group-hover:text-primary dark:text-black xl:w-44"
                  >
                    התחל בחינם!
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div
            className="flex flex-col flex-grow"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div className="flex flex-col justify-between h-full group rounded-3xl border-2 border-secondary bg-white p-6 px-4 transition hover:bg-secondary hover:drop-shadow-[-10px_30px_70px_rgba(40,38,77,0.25)] dark:border-white/10 dark:bg-transparent dark:bg-gradient-to-b dark:from-white/[0.01] dark:to-transparent dark:drop-shadow-none dark:hover:border-gray-dark dark:hover:bg-gray-dark sm:px-6">
              <div>
                <div className="relative mb-8">
                  <h3 className="text-[22px] font-black text-black dark:text-white">
                    פרו
                  </h3>
                  <div className="group-hover:white/10 absolute top-1/2 -translate-y-1/2 rounded-xl bg-secondary/10 py-2 px-4 text-xs font-black text-secondary group-hover:bg-white group-hover:text-black ltr:right-0 rtl:left-0">
                    MOST POPULAR
                  </div>
                </div>
                <ul className="space-y-5 pb-7 text-sm font-bold group-hover:text-white">
                  <li className="flex items-center">
                    <div>
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="8" cy="8" r="8" fill="#45B649" />
                        <path
                          d="M5.11438 8.11438L7 10L10.7712 6.22876"
                          stroke="white"
                          strokeWidth="1.6"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    <p className="pr-3">כתיבה שיווקית מבוססת AI ללא הגבלה</p>
                  </li>
                  <li className="flex items-center">
                    <div>
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="8" cy="8" r="8" fill="#45B649" />
                        <path
                          d="M5.11438 8.11438L7 10L10.7712 6.22876"
                          stroke="white"
                          strokeWidth="1.6"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    <p className="pr-3">עוזר כתיבה בצ׳אט</p>
                  </li>
                  <li className="flex items-center">
                    <div>
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="8" cy="8" r="8" fill="#45B649" />
                        <path
                          d="M5.11438 8.11438L7 10L10.7712 6.22876"
                          stroke="white"
                          strokeWidth="1.6"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    <p className="pr-3">
                      מושלם לפרילאנסרים ובעלי מותגי פרסום קטנים
                    </p>
                  </li>
                  <li className="flex items-center">
                    <div>
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="8" cy="8" r="8" fill="#45B649" />
                        <path
                          d="M5.11438 8.11438L7 10L10.7712 6.22876"
                          stroke="white"
                          strokeWidth="1.6"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    <p className="pr-3">
                      ליצור מודעות טקסט לפייסבוק, אינסטגרם וטיקטוק במספר רגעים
                    </p>
                  </li>
                  <li className="flex items-center">
                    <div>
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="8" cy="8" r="8" fill="#45B649" />
                        <path
                          d="M5.11438 8.11438L7 10L10.7712 6.22876"
                          stroke="white"
                          strokeWidth="1.6"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    <p className="pr-3">צור בלוגים ותכני טקסט מתקדמים</p>
                  </li>
                </ul>
              </div>
              <div className="border-t-2 border-[#BBC0D0]/50 pt-7">
                <div className="mb-3 flex flex-col items-center justify-center sm:flex-row sm:justify-between">
                  <a
                    href="!#"
                    className="btn bg-secondary text-xl text-white group-hover:bg-white group-hover:text-secondary dark:text-black xl:w-44"
                  >
                    79 ש״ח בחודש
                  </a>
                  {/* <div className="flex" style={{ direction: "ltr" }}>
                    <DateTimeDisplay value={hours} isDanger={false} />
                    <p> : </p>
                    <DateTimeDisplay value={minutes} isDanger={false} />
                    <p> : </p>
                    <DateTimeDisplay value={seconds} isDanger={false} />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div
            className="flex flex-col flex-grow"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div className="flex flex-col justify-between h-full group rounded-3xl border-2 border-black bg-white p-6 px-4 transition hover:bg-black hover:drop-shadow-[-10px_30px_70px_rgba(40,38,77,0.25)] dark:border-white/10 dark:bg-transparent dark:bg-gradient-to-b dark:from-white/[0.01] dark:to-transparent dark:drop-shadow-none dark:hover:border-gray-dark dark:hover:bg-gray-dark sm:px-6">
              <div>
                <div className="mb-8">
                  <h3 className="text-[22px] font-black text-black group-hover:text-white dark:text-white">
                    לעסקים
                  </h3>
                </div>
                <ul className="space-y-5 pb-7 text-sm font-bold group-hover:text-white">
                  <li className="flex items-center">
                    <div>
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="8" cy="8" r="8" fill="#45B649" />
                        <path
                          d="M5.11438 8.11438L7 10L10.7712 6.22876"
                          stroke="white"
                          strokeWidth="1.6"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    <p className="pr-3">כל התכונות מה-פרו כלולות</p>
                  </li>
                  <li className="flex items-center">
                    <div>
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="8" cy="8" r="8" fill="#45B649" />
                        <path
                          d="M5.11438 8.11438L7 10L10.7712 6.22876"
                          stroke="white"
                          strokeWidth="1.6"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    <p className="pr-3">גישה למספר משתמשים</p>
                  </li>
                  <li className="flex items-center">
                    <div>
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="8" cy="8" r="8" fill="#45B649" />
                        <path
                          d="M5.11438 8.11438L7 10L10.7712 6.22876"
                          stroke="white"
                          strokeWidth="1.6"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    <p className="pr-3">
                      במיוחד עבור סוכנויות, צוותי שיווק ועסקים גדולים
                    </p>
                  </li>
                </ul>
              </div>
              <div className="border-t-2 border-[#BBC0D0]/50 pt-7">
                <div className="mb-3 flex flex-col items-center justify-center sm:flex-row sm:justify-between">
                  <a
                    href="!#"
                    className="btn bg-black text-xl text-white group-hover:bg-white group-hover:text-black "
                  >
                    {" "}
                    לקבלת הצעת מחיר
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Pricing;
