import { useEffect } from 'react';
import Typewriter from 'typewriter-effect';


const ChatForm = ({ loading, chatLog, isNewChat, setChatLog }) => {

    useEffect(() => {
        if (isNewChat) {
            setChatLog([]); 
        }
    }, [isNewChat]);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, []);

    return (
        <>
            <div className='w-1/2' style={{ direction: 'rtl' }}>
                {chatLog.map((item, index) => {
                    return <div key={index} className={`flex items-start ${item.role === 'assistant' ? 'my-2' : ''} space-x-2.5 space-x-reverse sm:space-x-5 sm:space-x-reverse`}>
                        <div className={`flex flex-col items-start space-y-3.5`}>
                            <div className="ml-4 max-w-lg sm:ml-10">
                                <div className={`rounded-2xl rounded-tr-none ${item.role === 'assistant' ? 'bg-info/10 my-2' : 'bg-info/30'} p-3 text-slate-700 shadow-md dark:bg-accent dark:text-white`}>
                                    {item.role === "assistant" ? (
                                        <pre style={{ whiteSpace: 'pre-wrap', fontFamily: 'Assistant', fontWeight: "light" }}>
                                            <Typewriter
                                                key={item.content}
                                                onInit={(typewriter) => {
                                                    typewriter.typeString(item.content).start();
                                                }}
                                                options={{
                                                    delay: 30, // add a 100 millisecond delay between each character
                                                }}
                                            /></pre>
                                    ) : (
                                        <pre style={{ whiteSpace: 'pre-wrap', fontFamily: 'Assistant', fontWeight: "light" }}>{item.content}</pre>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                })}
                {loading && <img className='mt-4' src="/assets/icons/chatdots.gif" alt="loading gif" />}
            </div>
        </>
    )
}
export default ChatForm