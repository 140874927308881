export  const en={
  title: "yo yo welcome",
  header: "Payment by Credit Card",
  lbl_card_number: "Card Number:",
  lbl_expiry: "Expiry date:",
  lbl_cvv: "CVV:",
  lbl_card_holder_id: "Card holder ID:",
  lbl_amount: "Amount:",
  "error_credit_card_number": "Incorrect credit card number.",
  "error_expiry": "Invalid card expiration date.",
  "error_cvv": "Invalid CVV.",
  "error_card_holder_id_number": "Invalid holder ID number",
  "error_amount": "Invalid amount",
  placeholder_card_holder_id: "ID",
  placeholder_card_number: "Credit card number",
  placeholder_expiry: "MM/YYYY",
  placeholder_cvv: "CVV",
  copyright_message: "Tranzila ©Tranzilla Services - All rights reserved",
  submit:"Submit"
}
