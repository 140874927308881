import React, { useEffect } from 'react'
import packageJson from '../../../../package.json';

const useCache = () => {
    useEffect(() => {
        const checkAndUpdateCache = () => {
          let version = localStorage.getItem('doda');
          if (version !== packageJson.version) {
            if ('caches' in window) {
              caches.keys().then((names) => {
                // Delete all the cache files
                names.forEach((name) => {
                  caches.delete(name);
                });
              });
    
              // Makes sure the page reloads. Changes are only visible after you refresh.
              window.location.reload(true);
            }
            localStorage.clear();
            localStorage.setItem('doda', packageJson.version);
          }
        };
    
        checkAndUpdateCache();
      }, []); // Pass an empty dependency array
  return (
    <div>useCache</div>
  )
}

export default useCache