import { useState } from "react";
import Spinner from "../layouts/Spinner";

const Item = ({ item }) => {
    const [copied, setCopied] = useState(false);
  
    const copyToClipboard = (textToCopy) => {
      navigator.clipboard.writeText(textToCopy);
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    };
  
    return (
      <div className="mt-4 card shadow-lg">
        <div className="p-6 text-lg font-normal">
          <pre
            style={{ whiteSpace: "pre-wrap", fontFamily: "Assistant", fontWeight: "light" }}
            className="p-2 hover:bg-gray/10"
          >
            {item.translatedText}
          </pre>
        </div>
        <div className="flex p-2">
          <button
            onClick={() => {
              copyToClipboard(item.translatedText);
            }}
            className="border border-gray/20 px-4 py-2 rounded-md hover:border-gray/50 ml-4"
          >
            להעתיק
            {copied && <span className="block text-secondary text-xs">הועתק!</span>}
          </button>
        </div>
      </div>
    );
  };
  
  const Results = ({ isLoading, data }) => {
    return (
      <>
        {!isLoading && data.length > 0 && (
          <div className="col-span-12 lg:col-span-6">
            {data && data.map((item, index) => <Item key={index} item={item} />)}
          </div>
        )}
         {isLoading && <div className="col-span-12 lg:col-span-6">
                    <Spinner /> </div>}
      </>
    );
  };
export default Results