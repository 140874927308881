import { useState, useEffect, useRef } from "react";
import i18n from "../assets/i18n/i18n";
import PaymentForm from "./PaymentForm";
import PaymentResult from "./PaymentResult";
import { environment } from "../environment/conf";

const Payment = ({setShowModal, data}) => {
  const fieldsRef = useRef(null);

  const [imageSrc, setImageSrc] = useState("cardExample");
  const [messages, setMessages] = useState([]);
  const [success, setSuccess] = useState(false);
  const [resMessage, setResMessage] = useState("");

  useEffect(() => {
    if (fieldsRef.current == null) {
      tzlHostedFields();
    }
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();

    chargeCcData(event);
  };

  const chargeCcData = (e) => {
    fieldsRef.current.charge(
      {
        terminal_name: environment.terminal_name,
        amount: e.target.amount.value,
        currency_code: environment.currency_code,
        response_language: environment.response_language,
      },
      (error, response) => {
        if (response) {
          console.log(response)
          parseResponse(response);
          
        }
        if (error) {
          if (error.messages !== null) {
            handleError(error);
          }
        }
      }
    );
  };

  const handleError = (err) => {
    let tmpArray = [];
    err.messages.forEach((message) => {
      tmpArray.push(message.param);
    });
    setMessages(tmpArray);
    setSuccess(false);
  };

  const parseResponse = (response) => {
    if (response.errors != null) {
      let tmpArray = [];
      response.errors.forEach((message) => {
        tmpArray.push(message.message);
      });
      const str = tmpArray.join();
      setResMessage(str);
      setSuccess(false);
    } else {
      if (response.transaction_response.success === false) {
        setResMessage(response.transaction_response.error);
        setSuccess(true);
      } else {
        setSuccess(true);
      }
    }
  };

  const onCardNumberChanged = (result) => {
    if (result && result.cardType !== "unknown") {
      setImageSrc(result.cardType);
    }
  };

  const validityChange = (result) => {
    const tmpArray = [...messages];
    if (result && result.field) {
      tmpArray.splice(result.field.index, 1);
    }
    setMessages(tmpArray);
  };

  const tzlHostedFields = () => {
    const tzlFields = window.TzlaHostedFields.create({
      sandbox: false,

      fields: {
        card_holder_id_number: {
          selector: "#card_holder_id_number",
          placeholder: i18n.t("placeholder_card_holder_id"),
          tabindex: 2,
        },
        credit_card_number: {
          selector: "#credit_card_num",
          placeholder: i18n.t("placeholder_card_number"),
          tabindex: 1,
        },
        cvv: {
          selector: "#cvv",
          placeholder: i18n.t("placeholder_cvv"),
          tabindex: 4,
        },
        expiry: {
          selector: "#expiry",
          placeholder: i18n.t("placeholder_expiry"),
          tabindex:3,
          version: "1",
        },
      },
    });

    tzlFields.onEvent("cardTypeChange", onCardNumberChanged);
    tzlFields.onEvent("blur", validityChange);

    fieldsRef.current = tzlFields;
  };

  if (resMessage !== "" || success) {
    return <PaymentResult msg={resMessage} success={success}></PaymentResult>;
  }

  return (
      <PaymentForm
        handleSubmit={handleSubmit}
        img={imageSrc}
        msg={messages}
        data={data}
        setShowModal={setShowModal}
      ></PaymentForm>
  );
};

export default Payment;
