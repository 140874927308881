import { useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getResult, reset } from "../../redux/openai/openaiSlice";
import { Helmet } from "react-helmet-async";

import Layout from "../components/layouts/Layout"
import Results from "../components/sections/Results";
import { RemainingWordsContext } from "../../App";
import SharedTextArea from "../components/sections/SharedTextArea";
import MainTitle from "../components/layouts/MainTitle";
import { fetchAuthUser } from "../../redux/auth/authService";


const ImproveAd = () => {
    const { setUserWords } = useContext(RemainingWordsContext);

    const [formData, setFormData] = useState({
        plot: "", tone: "משפיע ומרשים"
    });

    const dispatch = useDispatch();
    const { plot, tone } = formData;

    useEffect(() => {
        dispatch(reset());
    }, [dispatch])

    const getUserWords = async () => {
        const currentUser = await fetchAuthUser();
         setUserWords(currentUser.words);
       }
    const { data, isLoading } = useSelector(
        (state) => state.openai
    )
    useEffect(() => {
        if (!isLoading) { 
            getUserWords();
        }
    }, [data, isLoading]); 

    const handleSubmit = async (e) => {
        e.preventDefault();
        let prompt = `Please improve the following ad for me: [${plot}] make it more engaging and captivating. I want it to be more ["${tone}"].`;
        let Newprompt = { ...formData, prompt: prompt, project: "שפר מודעה קיימת" }
        dispatch(getResult(Newprompt));
    }

    return (
        <>
            <Helmet>
                <title>Doda.ai | שפר מודעה קיימת</title>
            </Helmet>
            <Layout>
                <div className="min-h-100vh flex grow bg-white dark:bg-navy-900">
                    <main className="main-content w-full px-[var(--margin-x)] pb-8">
                       <MainTitle title="שפר מודעה קיימת" />
                        <div className="grid grid-cols-12 gap-4 sm:gap-5 lg:gap-6">
                            <SharedTextArea handleSubmit={handleSubmit} descLabel="מודעה לשיפור" placeholderLabel="שדרג את שגרת הבוקר שלך עם מכונת הקפה המהפכנית שלנו, המספקת את החליטה המושלמת תוך שניות. התעוררו לניחוח העשיר והטעם יוצא הדופן שיעלה את היום שלכם מרגיל ליוצא דופן." formData={formData} setFormData={setFormData} />
                            <Results isLoading={isLoading} data={data} />
                        </div>
                    </main>
                </div>
            </Layout>
        </>
    )
}
export default ImproveAd