import axios from 'axios'
axios.defaults.withCredentials = true;

// Create new goal
const saveChat = async (chatData, conversId, token) => {
   
    const response = await axios.post(process.env.REACT_APP_BACKEND_API + "chat", { chatData, conversId })
    return response.data
}

// Get user history
const getChat = async (token) => {
 
    const response = await axios.get(process.env.REACT_APP_BACKEND_API + "chat",)
    return response.data
}

// Get one history
export const getOneChat = async (id) => {

    const response = await axios.get(process.env.REACT_APP_BACKEND_API + "chat/" + id)
    return response.data
}

// Delete user goal
const deleteChat = async (id, token) => {

    const response = await axios.delete(process.env.REACT_APP_BACKEND_API + id)
    return response.data
}

const chatService = {
    saveChat, getChat, deleteChat
}

export default chatService