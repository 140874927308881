import React, { useEffect } from 'react'
import { fetchAuthUser } from '../../../redux/auth/authService';

const useWords = (user, setUserWords) => {

    useEffect(() => {
        const getUserWords = async () => {
          const currentUser = await fetchAuthUser();
           setUserWords(currentUser.words);
         }
         getUserWords();
       
      }, [user]);

  return (
    <></>
  )
}

export default useWords