import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import openaiService from './openaiService'

const initialState = {
    data: [],
    remainingWords: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',
}


// Get result
export const getResult = createAsyncThunk(
    "openai/getResult",
    async (prompt, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token;
            const response = await openaiService.getResult(prompt, token);

            if (response.status === 402) {
                return thunkAPI.rejectWithValue(response.data.message);
            }

            return response.data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);
// Get result from chatgpt
export const getResultChat = createAsyncThunk(
    'openai/getResultChat',
    async (prompt, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await openaiService.getResultChat(prompt, token)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)


export const openaiSlice = createSlice({
    name: 'openai',
    initialState,
    reducers: {
        reset: (state) => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(getResult.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getResult.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.data = action.payload.result
                state.remainingWords = action.payload.remainingWords
            })
            .addCase(getResult.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                if (action.payload === "Please upgrade your plan") {
                    state.remainingWords = 0;
                }
            })
            .addCase(getResultChat.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getResultChat.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.data = action.payload
            })
            .addCase(getResultChat.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
    },
})

export const { reset } = openaiSlice.actions
export default openaiSlice.reducer