import { useEffect, useContext, useState } from "react";
import Layout from "../components/layouts/Layout"
import Results from "../components/sections/Results";
import { useDispatch, useSelector } from "react-redux";
import { getResult, reset } from "../../redux/openai/openaiSlice";
import { RemainingWordsContext } from "../../App";
import { fetchAuthUser } from "../../redux/auth/authService";
import { Helmet } from "react-helmet-async";
import MainTitle from "../components/layouts/MainTitle";


const Listicle = () => {

    const { setUserWords } = useContext(RemainingWordsContext);
    const [formData, setFormData] = useState({
        topic: "", count: 1, tone: "משפיע ומרשים"
    })
    const { topic, count, tone } = formData;
    const dispatch = useDispatch();

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, []);

    const { data, isLoading } = useSelector(
        (state) => state.openai
    )

    const getUserWords = async () => {
        const currentUser = await fetchAuthUser();
        setUserWords(currentUser.words);
    }
    useEffect(() => {
        if (!isLoading) {
            getUserWords();
        }
    }, [data, isLoading]);


    useEffect(() => {
        dispatch(reset());
    }, [dispatch])



    const handleSubmit = async (e) => {
        e.preventDefault();
        let prompt = `Please generate a ${tone ? `${tone}` : ''} Listicle. The topic is [${topic}]\n The list count is ${count}.`;
        let Newprompt = { ...formData, prompt: prompt, project:"רשימה" }
        dispatch(getResult(Newprompt));
    }
    return (
        <>
            <Helmet>
                <title>Doda.ai | רשימה</title>
            </Helmet>
            <Layout>
                <div className="min-h-100vh flex grow bg-white dark:bg-navy-900">
                    <main className="main-content w-full px-[var(--margin-x)] pb-8">
                        <MainTitle title="רשימה" />
                        <div className="grid grid-cols-12 gap-4 sm:gap-5 lg:gap-6">
                            <div className="col-span-12 lg:col-span-6">
                                <div className="card py-6 border border-gray/20">
                                    <div className="tabs flex flex-col">
                                        <div className="is-scrollbar-hidden overflow-x-auto">
                                            <div className="border-b-2 border-slate-150 dark:border-navy-500">
                                                <div className="tabs-list -mb-0.5 flex">
                                                    <button className="admin-btn h-14 shrink-0 space-x-2 space-x-reverse rounded-none border-b-2 border-secondary px-4 font-medium text-secondary dark:border-accent dark:text-accent-light sm:px-5">
                                                        <i className="fa-solid fa-layer-group text-base"></i>
                                                        <span>כללי</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <form onSubmit={handleSubmit}>
                                            <div className="p-4 sm:p-5">
                                                <div className="space-y-5">
                                                    <label className="block">
                                                        <span className="font-medium text-slate-600 dark:text-navy-100">נוֹשֵׂא</span>
                                                        <input name="topic" value={topic} onChange={handleChange}
                                                            className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-secondary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                                            placeholder="נוֹשֵׂא" type="text" required />
                                                    </label>
                                                    <label className="block">
                                                        <span className="font-medium text-slate-600 dark:text-navy-100">ספירת רשימה</span>
                                                        <input name="count" value={count} onChange={handleChange}
                                                            className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-secondary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                                            placeholder="ספירת רשימה" type="number" required />
                                                    </label>
                                                    <label className="block">
                                                        <span className="font-medium text-slate-600 dark:text-navy-100">בחירת טונציה:</span>
                                                        <select name="tone" value={tone} onChange={handleChange} className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-secondary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent">
                                                            <option value="">ללא</option>
                                                            <option value="משפיע ומרשים">משפיע ומרשים</option>
                                                            <option value="ידידותי ומזמין ">ידידותי ומזמין </option>
                                                            <option value="יוקרתי ואקסקלוסיבי">יוקרתי ואקסקלוסיבי</option>
                                                            <option value="מרגש וסיפורי ">מרגש וסיפורי </option>
                                                            <option value="מפורט והסברתי">מפורט והסברתי</option>
                                                            <option value="פשוט וכללי">פשוט וכללי</option>
                                                            <option value="משפטים קצרים">משפטים קצרים</option>
                                                            <option value="מכירתי ודוחף">מכירתי ודוחף</option>
                                                            <option value="רגוע">רגוע</option>
                                                            <option value="משכנע ">משכנע </option>
                                                            <option value="מעורר מחשבה ">מעורר מחשבה </option>
                                                            <option value="בולט">בולט </option>
                                                            <option value="רשמי">רשמי </option>
                                                            <option value="ידידותי ">ידידותי </option>
                                                            <option value="מקצועי ">מקצועי </option>
                                                            <option value="חצוף">חצוף </option>
                                                            <option value="שנון">שנון </option>
                                                        </select>
                                                    </label>
                                                    <div className="flex justify-center space-x-2 space-x-reverse ">
                                                        <button className="w-full admin-btn bg-secondary font-medium text-white hover:bg-secondary-focus focus:bg-secondary-focus active:bg-secondary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                                                        > ליצור </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            
                            </div>
                            <Results isLoading={isLoading} data={data} />
                            {/* <CKEditorForm /> */}
                        </div>
                    </main>
                </div>
            </Layout>
        </>
    )
}
export default Listicle