import { Link } from "react-router-dom"
import { homePageTools } from "../../../tools"

const HelpSection = () => {
    return (
        <section style={{ backgroundImage: "/assets/images/service-bg.png" }}
            className="bg-cover bg-center bg-no-repeat py-14 dark:bg-none lg:py-[100px]">
            <div className="container">
                <div className="heading text-center">
                    <h6>יכולות מערכת</h6>
                    <h4>חכם, מהיר, חלק. גלו את הסטנדרט החדש ביצירת תוכן.</h4>
                </div>
                <div className="grid gap-6 grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
                   {homePageTools.map((tool) => {
                    return  <Link to={tool.url} key={tool.id} className="flex flex-col flex-grow" data-aos="fade-up" data-aos-duration="1000">
                    <div className="flex flex-col flex-grow group rounded-3xl border-2 border-white bg-white p-6 transition duration-500 hover:border-secondary hover:bg-secondary/20 dark:border-white/10 dark:bg-transparent dark:bg-gradient-to-b dark:from-white/[0.04] dark:to-transparent dark:!shadow-none dark:hover:bg-secondary"
                        style={{ boxShadow: "-20px 30px 70px rgba(219, 222, 225, 0.4)" }}>
                        <div className="flex h-14 w-14 items-center justify-center rounded-full transition"
                           >
                            {tool.image ? <img src={tool.image} alt={tool.title} /> : <p className="text-[30px]">🚀</p>}
                        </div>
                        <a href="services-detail.html" className="my-8 inline-block text-[22px] font-extrabold text-black dark:text-white dark:group-hover:text-black">{tool.title}</a>
                        <p className="mb-10 text-lg font-semibold transition line-clamp-3 dark:group-hover:text-black">
                        {tool.description}
                        </p>
                        <a href="services-detail.html"
                            className="flex h-11 w-11 items-center justify-center rounded-full bg-[#F3F4F6] transition group-hover:bg-black rtl:rotate-180 dark:bg-gray-dark">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="text-black transition group-hover:text-white dark:text-white">
                                <path d="M9.41083 14.4109L10.5892 15.5892L16.1783 10.0001L10.5892 4.41089L9.41083 5.58922L12.9883 9.16672H5V10.8334H12.9883L9.41083 14.4109Z"
                                    fill="currentColor" />
                            </svg>
                        </a>
                    </div>
                </Link>
                   })}
                   
                </div>
                <div className="flex justify-center w-full mt-4">
                    <Link to="/tools" className=" admin-btn bg-secondary rounded-full font-medium text-white hover:bg-secondary-focus focus:bg-secondary-focus active:bg-secondary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90">תבניות נוספות</Link>
                </div>
            </div>
        </section >
    )
}
export default HelpSection