export const homePageTools = [
    {
        id: 1,
        title: "תיאור מוצר",
        url: "/product-description",
        image: "/assets/icons/produc.png",
        description: "תבנית זו נועדה לעזור לך להדגיש את התכונות והיתרונות העיקריים של המוצר שלך באופן שיהדהד עם קהל היעד שלך. פשוט ספק את פרטי המוצר הדרושים, ואפשר ל-Doda AI ליצור נרטיב משכנע שלא רק מתאר את המוצר שלך אלא גם מציג את נקודות המכירה הייחודיות שלו."
    },
    {
        id: 2,
        title: "תסריט לסרטון תוכן",
        url: "/video-scripts",
        image: "/assets/icons/video.png",
        description: "מושלם עבור מותגים, משפיענים ויוצרי תוכן ב-TikTok."
    },
 
    {
        id: 3,
        title: "פריסטייל",
        url: "/freestyle",
        image: "/assets/icons/free.png",
        description: "אידיאלי לסיעור מוחות, לסיפור סיפורים או לחקר אופקי תוכן חדשים. ליצור טקסט טוב יותר באמצעות יצירתיות ללא גבולות עם תבנית ה'Freestyle' שלנו. הזן כל רעיון ואפשר ל-Doda AI לייצר תוכן ייחודי ומשכנע."
    },
    {
        id: 4,
        title: "רעיונות לתוכן",
        url: "/blog-ideas",
        image: "/assets/icons/ideas.png",
        description: "ספק את הנושא או המתווה שלך, ואפשר ל-Doda AI ליצור פוסטים מרתקים, מובנים היטב. מושלם עבור בלוגרים, משווקי תוכן ומובילי מחשבה."
    },
    {
        id: 5,
        title: "בונה המאמרים",
        url: "/blog-content",
        image: "/assets/icons/content.png",
        description: "ספק את הנושא או המתווה שלך, ואפשר ל-Doda AI ליצור פוסטים מרתקים, מובנים היטב. מושלם עבור בלוגרים, משווקי תוכן ומובילי מחשבה."
    },
    {
        id: 6,
        title: "מודעת נדל״ן מותאמת",
        url: "/real-estate-listing",
        image: "/assets/icons/estate.png",
       description:"תבנית זו מסייעת לך בתיאור הנכס שלך באופן שמושך קונים או שוכרים פוטנציאליים. ספק את פרטי הנכס שלך ואפשר ל-Doda AI ליצור רישום מפורט ומעניין. אידיאלי עבור סוכני נדל'ן ובעלי נכסים."
    },
    {
        id: 7,
        title: "שיפור פרסומת קיימת",
        url: "/improving-ads",
       description:"תבנית זו מסייעת לך בתיאור הנכס שלך באופן שמושך קונים או שוכרים פוטנציאליים. ספק את פרטי הנכס שלך ואפשר ל-Doda AI ליצור רישום מפורט ומעניין. אידיאלי עבור סוכני נדל'ן ובעלי נכסים."
    },
    {
        id: 8,
        title: "כותרות מושלמות",
        url: "/headline",
        image: "/assets/icons/headline.png",
       description:"צור כותרות שובות לב עם תבנית 'הכותרת המושלמת' של Doda AI"
    },

]




export const tools = [
    {
        id: 1,
        title: "תסריט לסרטון תוכן",
        url: "/video-scripts",
        image: "/assets/icons/video.png",
        description: "מושלם למותגים, למשפיעים, וליוצרי תוכן ב-TikTok ליצירת תסריטים מרתקים לסרטונים."
    },
    {
        id: 2,
        title: "תיאור מוצר",
        url: "/product-description",
        image: "/assets/icons/produc.png",
        "description": "תבנית זו מסייעת להדגיש את התכונות המרכזיות של המוצר שלך, מעוצבת להתאים לקהל היעד שלך."
    },
    {
        id: 3,
        title: "פריסטייל",
        url: "/freestyle",
        image: "/assets/icons/free.png",
        "description": "מושלם להפקת רעיונות, סיפורים, וחקירת אופקים חדשים של תוכן. צור תוכן טוב יותר עם יצירתיות בלתי מוגבלת."
    },
    {
        id: 4,
        title: "רעיונות לתוכן",
        url: "/blog-ideas",
        image: "/assets/icons/ideas.png",
        "description": "ספק את הנושא או הערך שלך, ותן ל-Doda AI ליצור פוסטים מרתקים וממוקמים היטב. מושלם לבלוגרים."
    },
    {
        id: 5,
        title: "בונה המאמרים",
        url: "/blog-content",
        image: "/assets/icons/content.png",
        "description": "ספק את הנושא או הערך שלך, ואפשר ל-Doda AI ליצור פוסטים מרתקים ומובנים היטב. מושלם לשיווק תוכן."
    },
    {
        id: 6,
        title: "הקדמת מאמר",
        url: "/blog-introduction",
        image: "/assets/icons/intro.png",
        "description": "מושלם להקדמות מאמרים. ספק את הנושא, ואפשר ל-Doda AI ליצור הקדמה מרתקת שתעורר עניין."
    },
    {
        id: 7,
        title: "מסקנה וסיומת למאמר",
        url: "/blog-conclusion",
        image: "/assets/icons/conclusion.png",
        "description": "יצירת סיכום תמציתי למאמר שלך שמחזק את הנקודות העיקריות. משאיר רושם מתמשך על הקוראים. אידיאלי לבלוגרים."
    },
    {
        id: 8,
        title: "יצירת קווי מתאר למאמר",
        url: "/blog-outlines",
        image: "/assets/icons/outline.png",
        "description": "תבנית ליצירת מתווה מקיף שמבנה את המחשבות שלך. פשוט ספק את הנושא ונקודות המפתח, ואפשר ל-Doda AI לעזור."
    },
    {
        id: 9,
        title: "מודעת ׳לפני אחרי׳",
        url: "/before-after-bridge",
        image: "/assets/icons/bridge.png",
        "description": "הצג טרנספורמציות ״לפני אחרי״ בצורה משכנעת. מושלם למשווקים ואנשי מכירות שמעוניינים להדגיש שינויים מרשימים."
    },
    {
        id: 10,
        title: "כותרת למודעת גוגל",
        url: "/google-headline",
        image: "/assets/icons/google.png",
        "description": "צור כותרות מושכות ל-Google Ads שלך. ספק את פרטי המוצר או השירות שלך, ותן ל-Doda AI לכתוב."
    },
    {
        id: 11,
        title: "סיפור יצירתי",
        url: "/creative-story",
        image: "/assets/icons/story.png",
        "description": "תבנית ליצירת סיפורים מרתקים ומלאי דמיון. ספק את הנושא או פרופילי הדמויות, ותן ל-Doda AI ליצור."
    },
    // {
    //     id: 12,
    //     title: "הגדרת תבנית טקסט",
    //     url: "/faq",
    //     image: "/assets/icons/faq.png",
    //     "description": "ליצור טקסט טוב יותר עם יצירתיות ללא גבולות. הזן רעיון ותן ל-Doda AI לייצר תוכן ייחודי ומשכנע."
    // },
    {
        id: 13,
        title: "מודעת ׳איידה׳",
        url: "/aida-framework",
        image: "/assets/icons/aida.png",
        description:"התבנית מסייעת לך לבנות מסר לפי עקרון AIDA. ספק פרטי המוצר או השירות, ו-Doda AI תיצור עותק מעודד פעולה"
    },
    {
        id: 14,
        title: "מודעה לפייסבוק",
        url: "/facebook-ad",
        image: "/assets/icons/fb.png",
        "description": "יצירת מודעות פייסבוק מרתקות. ספק את פרטי המוצר, קהל היעד, ומטרת המודעה, ואפשר ל-Doda AI לעזור."
    },
    {
        id: 15,
        title: "זוויות שיווק",
        url: "/marketing-angles",
        image: "/assets/icons/marketing.png",
        "description": "תבנית לגילוי נקודות מבט שיווקיות ייחודיות. הזן פרטי מוצר/שירות וגלה זוויות שיווק מגוונות. אידיאלי למשווקים וקופירייטרים."
    },
    {
        id: 16,
        title: "סיכום טקסט",
        url: "/text-summarizer",
        image: "/assets/icons/magic.png",
        "description": "תבנית לצמצום טקסטים ארוכים לסיכומים תמציתיים. פשוטה לשימוש ומושלמת לכל מי שמעוניין לחסוך זמן."
    },
    {
        id: 17,
        title: "להסביר לילדים",
        url: "/explain-to-child",
        image: "/assets/icons/child.png",
        "description": "תבנית להפשטת מושגים מורכבים לילדים. מסייעת לפרק רעיונות מורכבים לשפה פשוטה וקלה להבנה."
    },
    {
        id: 18,
        title: "שאלות מגבשות",
        url: "/engaging-questions",
        image: "/assets/icons/qst.png",
        "description": "תבנית ליצירת שאלות שמעוררות שיחה. ספק את הנושא ואפשר ל-Doda AI ליצור שאלות מרתקות. אידיאלי למחנכים."
    },
    {
        id: 19,
        title: "מודעת נדל״ן מותאמת",
        url: "/real-estate-listing",
        image: "/assets/icons/estate.png",
        "description": "תיאור הנכס בצורה שמושכת קונים או שוכרים פוטנציאליים. ספק פרטי הנכס ואפשר ל-Doda AI ליצור."
    },
    {
        id: 20,
        title: "כותרות מושלמות",
        url: "/headline",
        image: "/assets/icons/headline.png",
        "description": "תבנית ליצירת כותרות מרשימות. השתמש ב-Doda AI ליצירת כותרת שתשוב לב קוראים פוטנציאליים."
    },
    {
        id: 21,
        title: "מדיניות לאתר",
        url: "/policies",
        image: "/assets/icons/policy.png",
        "description": "צור מדיניות פרטיות ותנאים מפורטים. תבנית זו מסייעת לך להתוות את תנאי שימוש ותקני פרטיות."
    },
    {
        id: 22,
        title: "מודעת דוא״ל",
        url: "/email",
        image: "/assets/icons/email.png",
        "description": "תבנית ליצירת דואר אלקטרוני אפקטיבי. ספק את מטרת הדוא'ל ונקודות מפתח, ואפשר ל-Doda AI ליצור."
    },
    {
        id: 23,
        title: "שיפור פרסומת קיימת",
        url: "/improve-ad",
        image: "/assets/icons/improve.png",
        "description": "הבא לרמה הבאה את המודעה שלך. ספק את המודעה הקיימת שלך ואפשר ל-Doda AI להציע שיפורים."
    },
    {
        id: 24,
        title: "הודעה לקידום מוצר",
        url: "/short-promotional-message",
        image: "/assets/icons/msg.png",
        "description": "תבנית ליצירת מסרים מקדמים מוצר, שירות או אירוע. השתמש ב-Doda AI ליצירת מסרים אפקטיביים."
    },
    {
        id: 25,
        title: "מאמר-רשימה",
        url: "/listicles",
        image: "/assets/icons/listicle.png",
        "description": "תבנית ליצירת 'Listicle', סוג של כתבה או מאמר שהעיקר בו הוא רשימה. מתאימה לבלוגים ואתרי חדשות."
    },
    {
        id: 26,
        title: "תיאור מוצרים של",
        url: "/shopify-product-description",
        image: "/assets/icons/descproducts.png",
        "description": "תבנית ליצירת תיאורי מוצרים לחנות Shopify שלך. מסייעת לך להציג את מוצריך באופן מרשים ומושך."
    },
]