import { Link } from "react-router-dom"
import { useDispatch } from "react-redux";
import { deleteHistory } from "../../../redux/history/historySlice";


const ProjectItems = ({ history }) => {

    const dispatch = useDispatch();

    const handleDelete = (id) => {
        alert("Are you sure?");
        dispatch(deleteHistory(id));
        window.location.reload();
    }

    return (
        <>
            {history && <div className="card border border-gray/20">
                <div className="flex flex-col items-center p-4 text-center sm:p-5">
                    <h3 className="pt-3 text-lg font-medium text-slate-700 dark:text-navy-100">
                        {history.project}
                    </h3>
                    <p className="text-sm">{history.project}</p>
                    <div className="my-3.5 flex flex-wrap space-x-2 space-x-reverse">
                        <div className="avatar h-8 w-8">
                            <div className="is-initial rounded-full bg-secondary text-xs+ uppercase text-white">  jd
                            </div>
                        </div>
                        <div className="avatar h-8 w-8">
                            <div className="is-initial rounded-full bg-secondary text-xs+ uppercase text-white">  jd
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex divide-x divide-x-reverse divide-slate-150 border-t border-slate-150 dark:divide-navy-500 dark:border-navy-500">
                    {/* <Link to={`/edit/${history.project}/${history._id}`} className="admin-btn h-11 w-full rounded-none rounded-br-lg font-medium text-slate-700 hover:bg-slate-300/20 active:bg-slate-300/25 dark:text-navy-100 dark:hover:bg-navy-300/20 dark:active:bg-navy-300/25"> הצגה </Link> */}
                    <button onClick={() => handleDelete(history._id)} className="admin-btn h-11 w-full rounded-none rounded-bl-lg font-medium text-red hover:bg-red/20 focus:bg-red/20 active:bg-dudu/25 dark:text-accent-light dark:hover:bg-accent-light/20 dark:focus:bg-accent-light/20 dark:active:bg-accent-light/25"> מחק </button>
                </div>
            </div>}
        </>
    )
}
export default ProjectItems