import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { en } from "./locales/en";

const resources = {
  en: {
    translation: en,
  },
};

const options = {
  order: ["localStorage", "navigator"],
  caches: ["localStorage"],
};

i18n.use(initReactI18next).init({
  detection: options,
  resources,
  ns: ["translation"],
  defaultNS: "translation",
  fallbackLng: "en",
  lng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
