import { Link } from "react-router-dom"
import { TypeAnimation } from 'react-type-animation';



const Hero = () => {
    const texts = [
        'הנשק הסודי שלך נגד דפים ריקים.',
        'AI רב עוצמה עבור מילים חזקות.',
        'תגידו שלום לדפים ריקים.',
        'המקום שבו טכנולוגיה פוגשת יצירתיות.',
        'היי לך! רוצה להפוך את משימות הכתיבה שלך לפשוטות?',
        'מי אמר שכתיבה חייבת להיות קשה?'
    ];
    return (
        <div
            style={{ backgroundImage: "url('/assets/images/heroBg.png')", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}
            className="font-assistant overflow-hidden pt-[82px] sm:-mx-[250px]  lg:-mx-[150px] lg:pt-[106px]">
            <div className="relative">
                <div className="container">
                    <div style={{ backgroundImage: "url(/assets/images/world-map.png)" }} className="relative bg-cover bg-top bg-no-repeat pt-14 pb-12 lg:pt-20 lg:pb-60 xl:pt-36">
                        <div className="w-full">
                            <div className="w-full relative z-[1] flex flex-col items-start text-right text-white">
                                <div className="lg:h-[450px] h-[300px]">
                                <h2 className="font-extrabold leading-normal text-[50px] lg:text-[125px]"
                                >
                                     <TypeAnimation
                                        sequence={texts} wrapper="span"
                                        cursor={true} repeat={Infinity}
                                        speed={300} />
                                </h2>
                                </div>
                              <div className="flex justify-center mt-12 w-full text-[20px] items-center">
                              <Link to="/login" className="ml-4 flex text-white font-bold  justify-center items-center rounded-full px-4 py-3 w-[300px] bg-secondary hover:scale-90">
                                    <span className="ml-2">להתחברות</span>
                                </Link>
                                <Link to="/sign-up" className="flex text-white font-bold  justify-center items-center rounded-full px-4 py-3 w-[300px] bg-secondary hover:scale-90">
                                    <span className="ml-2"> להרשמה</span>
                                </Link>
                              </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Hero