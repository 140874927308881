import axios from 'axios'
axios.defaults.withCredentials = true;


// Get user result openai
const getResult = async (historyItem, token) => {
    const response = await axios.post(process.env.REACT_APP_BACKEND_API + 'gpt', { historyItem })
    return response;
}

// Get user result chatgpt
export const getResultChat = async (prompt) => {
    const response = await axios.post(process.env.REACT_APP_BACKEND_API + 'gpt/chatgpt', { prompt })
    return response.data
}

const openaiService = {
    getResult, getResultChat
}

export default openaiService