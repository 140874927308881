import { BrowserRouter as Router, Routes, Route, Navigate  } from "react-router-dom";
import Home from "./frontpage/pages/Home";
import Register from "./toolspage/auth/Register";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Login from "./toolspage/auth/Login";
import Projects from "./toolspage/pages/Projects";
import Tools from "./toolspage/pages/Tools";
import { useSelector, useDispatch } from "react-redux";
import AuthVerify from "./redux/auth/AuthVerify";
import { logout, reset } from "./redux/auth/authSlice";
import { createContext, useState } from "react";
import ResetPassword from "./toolspage/auth/ResetPassword";
import Pricing from "./toolspage/pages/Pricing";

import AboutUs from "./frontpage/pages/AboutUs";
import PrivacyPolicy from "./frontpage/pages/PrivacyPolicy";
import EditSharedForm from "./toolspage/components/sections/EditSharedForm";
import TermsOfServices from "./frontpage/pages/TermsOfServices";
import Faq from "./toolspage/funcionalities/Faq";
import CreativeStory from "./toolspage/funcionalities/CreativeStory";
import TextSummarizer from "./toolspage/funcionalities/TextSummarizer";
import ExplainToChild from "./toolspage/funcionalities/ExplainToChild";
import EngagingQuestions from "./toolspage/funcionalities/EngagingQuestions";
import RealEstate from "./toolspage/funcionalities/RealEstate";
import Policies from "./toolspage/funcionalities/Policies";
import ImproveAd from "./toolspage/funcionalities/ImproveAd";
import Promotional from "./toolspage/funcionalities/Promotional";
import Listicle from "./toolspage/funcionalities/Listicle";
import Chat from "./toolspage/funcionalities/Chat";
import useCache from "./frontpage/components/hooks/useCache";
import useWords from "./frontpage/components/hooks/useWords";
import { toolsRoutes } from "./config/constant";
import SharedForm from "./toolspage/components/sections/SharedForm";

export const RemainingWordsContext = createContext({});
export const ThemeContext = createContext();

function App() {
  const { user, isLoading } = useSelector((state) => state.auth);
  const [darkMode, setDarkMode] = useState(false);
  const [userWords, setUserWords] = useState(0);

  const dispatch = useDispatch();


  useCache();
  useWords(user, setUserWords);

  const onLogout = () => {
    dispatch(logout(user));
    dispatch(reset());
  };
  if (isLoading) {
    return <div>Loading...</div>;
  }
  return (
    <>
     <Router>
        <ThemeContext.Provider value={{ darkMode, setDarkMode }}>
          <div className={`${darkMode ? 'dark' : ''}`}>
            <Routes>
              <Route
                path="/*"
                element={
                  user ? (
                    <RemainingWordsContext.Provider value={{ userWords, setUserWords }}>
                      <div className="font-assistant">
                        <div className="min-h-100vh flex-col flex grow bg-white dark:bg-navy-900">
                          <Routes>
                          {toolsRoutes.map((tool) => (
                          <Route
                            key={tool.id}
                            path={`/${tool.id}`}
                            element={
                              <SharedForm
                                toolName={tool.name}
                                titleLabel={tool.title}
                                descLabel={tool.description}
                                titlePlaceholder={tool.titlePlaceholder}
                                descriptionPlaceholder={tool.descriptionPlaceholder}
                              />
                            }
                          />
                        ))}
                            <Route path="/billing" element={<Pricing />} />
                            <Route path="/chat" element={<Chat />} />
                            <Route path="/tools" element={<Tools />} />
                            <Route path="/projects" element={<Projects />} />
                            <Route path="/listicles" element={<Listicle />} />
                            <Route path="/faq" element={<Faq />} />
                            <Route path="/creative-story" element={<CreativeStory />} />
                            <Route path="/text-summarizer" element={<TextSummarizer />} />
                            <Route path="/explain-to-child" element={<ExplainToChild />} />
                            <Route path="/engaging-questions" element={<EngagingQuestions />} />
                            <Route path="/real-estate-listing" element={<RealEstate />} />
                            <Route path="/policies" element={<Policies />} />
                            <Route path="/improve-ad" element={<ImproveAd />} />
                            <Route path="/short-promotional-message" element={<Promotional />} />
                            <Route path="/edit/:type/:id" element={<EditSharedForm />} />
                          </Routes>
                        </div>
                      </div>
                    </RemainingWordsContext.Provider>
                  ) : (<Navigate to="/login" />)
                }
              />
              <Route path="/" element={<Home />} />
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/sign-up" element={<Register />} />
              <Route path="/login" element={<Login />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/terms-of-services" element={<TermsOfServices />} />
              <Route path="/reset-password/:token" element={<ResetPassword />} />
            </Routes>
          </div>
        </ThemeContext.Provider>
        <AuthVerify logout={onLogout} />
      </Router>
      <ToastContainer />
    </>
  );
}

export default App;
