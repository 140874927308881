import React from 'react'
import { KeyboardDoubleArrowLeftOutlined} from '@mui/icons-material';

const BlogIntro = () => {
  return (
    <section className="bg-[#B2B6F4] py-14 lg:py-20">
    <div className="container">
        <div className="mt-16 grid gap-x-7 gap-y-12 text-lg font-semibold sm:grid-cols-1 lg:grid-cols-3 lg:gap-y-20 text-[#1E1457]">
            <div className="md:col-span-2 flex gap-5" data-aos="fade-up" data-aos-duration="1000">
                <div className="flex-1">
                    <h3 className="mb-1 lg:text-[70px] text-[40px] leading-[40px] lg:leading-[50px] font-extrabold ">  עיבוד שפה טבעית </h3>
                    <p className="font-semibold lg:text-[50px] text-[20px] leading-normal mb-6">חלוצים בעידן הבינה המלאכותית הקרב ובא</p>
                    <p className="font-medium lg:text-[20px] text-[16px] mb-12 leading-[20px] ">עם Doda, אנחנו בחזית המהפכה בעיבוד שפה טבעית (NLP) - הטכנולוגיה המאפשרת למכונות להבין וליצור טקסט דמוי אדם. המשימה שלנו נטועה בהכרה ששפה היא תכונה אנושית ייחודית וקידום ה-NLP דורש פריצות דרך מדעיות וטכנולוגיות משמעותיות.</p>
                    <p className="font-medium mb-4 lg:text-[20px] text-[16px] leading-[20px]">ב-Doda AI, אנו שואפים להוביל את השינוי הזה. ההצלחה שלנו תסמל צעד מונומנטלי לקראת הפיכת מכונות לשותפים אופטימליים במחשבה וביצירתיות.</p>
                    <p className="font-medium lg:text-[16px] leading-[16px] cursor-pointer hover:underline">קראו עוד על הדרך שבה אנו שואפים לשפר מודלי שפה מתקדמים מבוססי בינה מלאכותית
                    <KeyboardDoubleArrowLeftOutlined />
                    </p>
                </div>
            </div>

            <div className="flex items-center justify-center w-full" data-aos="fade-up" data-aos-duration="1000">
               <img src="/assets/images/square.png" alt="Doda AI" className="md:w-full w-1/2" />
            </div>
        </div>
    </div>
</section>
  )
}

export default BlogIntro