import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '@ckeditor/ckeditor5-build-classic/build/translations/he';


const CKEditorForm = () => {
    return (
        <div className="col-span-12 lg:col-span-6">
            <div className="">
                <CKEditor
                    config={{ language: 'he' }}
                    editor={ClassicEditor}
                    data=""
                    onReady={(editor) => {
                        console.log("CKEditor5 React Component is ready to use!", editor);
                    }}
                    onChange={(event, editor) => {
                        const data = editor.getData();
                        console.log({ event, editor, data });
                    }}
                />
            </div>
        </div>
    )
}
export default CKEditorForm