import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../redux/auth/authSlice';
import historyReducer from '../redux/history/historySlice';
import openaiReducer from '../redux/openai/openaiSlice';
import chatReducer from '../redux/chat/chatSlice';

export const store = configureStore({
    reducer: {
        auth: authReducer,
        history: historyReducer,
        openai: openaiReducer,
        chat: chatReducer
    },
})