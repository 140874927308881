import Payment from "../../../payements/Payment";


const PayementForm = ({ showModal, setShowModal, data }) => {
    return (
        <>
            {showModal ? (
                <>
                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="relative w-auto my-6 mx-auto max-w-3xl">
                            <div className="relative p-6 flex-auto">
                                <div className="w-full mx-auto rounded-lg bg-white shadow-lg p-5 text-gray-700" style={{ width: "600px" }}>
                                   <Payment data={data} setShowModal={setShowModal}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
        </>
    )
}

export default PayementForm