import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getResult } from "../../../redux/openai/openaiSlice";
import { Helmet } from "react-helmet-async";
import Layout from "../layouts/Layout";
import MainTitle from "../layouts/MainTitle";
import ToolForm from "../shared/ToolForm";
import Results from "./Results";
import { fetchAuthUser } from "../../../redux/auth/authService";
import { RemainingWordsContext } from "../../../App";


const SharedForm = ({ toolName, titlePlaceholder, descriptionPlaceholder, titleLabel, descLabel}) => {

    const [isEdit, setIstEdit] = useState(false);
    const { setUserWords } = useContext(RemainingWordsContext);

    const [formData, setFormData] = useState({
        title: "", description: "", tone: "משפיע ומרשים" })

    const dispatch = useDispatch();
    const { title, description, tone, } = formData;

    const { data, isLoading } = useSelector(
        (state) => state.openai
    )

    const getUserWords = async () => {
        const currentUser = await fetchAuthUser();
         setUserWords(currentUser.words);
       }
       
       useEffect(() => {
        if (!isLoading) { 
            getUserWords();
        }
    }, [data, isLoading]); 

    const handleSubmit = async (e) => {
        e.preventDefault();
        let prompt = `Write a ${tone} ${toolName}. The ${titleLabel} is [${title}]. The ${descLabel} is [${description}].`;
        let Newprompt = { ...formData, prompt: prompt, project: `${toolName}` }
        dispatch(getResult(Newprompt));
    }

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, []);

    return (
        <>
            <Helmet>
                <title>Doda.ai | {toolName} </title>
            </Helmet>
            <Layout>
                <div className="min-h-100vh flex grow bg-white dark:bg-navy-900">
                    <main className="main-content w-full px-[var(--margin-x)] pb-8">
                    <MainTitle title={toolName} />
                    <div className="grid grid-cols-12 gap-4 sm:gap-5 lg:gap-6">
                        <ToolForm  
                        isEdit={isEdit}
                        formData={formData}
                        setFormData={setFormData}
                        titleLabel={titleLabel} 
                        descLabel={descLabel}
                        titlePlaceholder={titlePlaceholder}
                        descriptionPlaceholder={descriptionPlaceholder}
                        handleSubmit={handleSubmit} />
                        <Results isLoading={isLoading} data={data} />
                        </div>
                    </main>
                </div>
            </Layout>
        </>
    )
}
export default SharedForm