import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from "react-redux";
import { resetPassword } from "../../redux/auth/authSlice";

const ResetPassword = () => {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [success, setSuccess] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    let { token } = useParams();

    const { isError, message } = useSelector((state) => state.auth);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            toast.error("Password mismatch", {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
        }
        if (isError) {
            toast.error(message)
        } else {
            const formData = { token, password }
            dispatch(resetPassword(formData));
            setSuccess(true);
            setTimeout(() => {
                setSuccess(false);
                navigate("/login")
            }, 3000);
        }

    };
    return (
        <>
            <div className="flex justify-center items-center h-screen">
                <section className="flex bg-gray-50 justify-center md:pb-24 pb-12 md:pt-20 pt-6">
                    <div className="bg-white p-6 shadow-lg rounded-lg" style={{ width: "550px" }}>
                        {success && <div className="alert flex overflow-hidden rounded-lg border border-info text-info">
                            <div className="bg-info p-3 text-white">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-5 w-5"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                    />
                                </svg>
                            </div>
                            <div className="px-4 py-3 sm:px-5">Success! Login to your account</div>
                        </div>}
                        <h2 className="text-lg font-bold my-4">Reset Password</h2>
                        <form onSubmit={handleSubmit}>
                            <label className="relative flex">
                                <input
                                    className="form-input peer w-full rounded-lg bg-slate-150 px-8 py-2 ring-primary/50 placeholder:text-slate-400 hover:bg-slate-200 focus:ring dark:bg-navy-900/90 dark:ring-accent/50 dark:placeholder:text-navy-300 dark:hover:bg-navy-900 dark:focus:bg-navy-900"
                                    placeholder="Password" onChange={(e) => setPassword(e.target.value)} value={password}
                                    type="password" name="password" required />
                                <span
                                    className="pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-5 w-5 transition-colors duration-200"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="1.5"
                                            d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
                                        />
                                    </svg>
                                </span>
                            </label>
                            <label className="relative flex my-4">
                                <input
                                    className="form-input peer w-full rounded-lg bg-slate-150 px-8 py-2  ring-primary/50 placeholder:text-slate-400 hover:bg-slate-200 focus:ring dark:bg-navy-900/90 dark:ring-accent/50 dark:placeholder:text-navy-300 dark:hover:bg-navy-900 dark:focus:bg-navy-900"
                                    placeholder="Repeat Password" onChange={(e) => setConfirmPassword(e.target.value)} value={confirmPassword}
                                    type="password" name="confirmPassword"
                                />
                                <span
                                    className="pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-5 w-5 transition-colors duration-200"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="1.5"
                                            d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
                                        />
                                    </svg>
                                </span>
                            </label>
                            <button type="submit" className="rounded-lg text-white text-md btn px-4 py-2">Reset Password</button>
                        </form>
                    </div>
                </section>
            </div>
        </>
    )
}
export default ResetPassword