import { useEffect, useState } from "react";
import Footer from "../components/layouts/Footer";
import Header from "../components/layouts/Header";
import Hero from "../components/layouts/Hero";
import Contact from "../components/sections/Contact";
import HelpSection from "../components/sections/HelpSection";
import Pricing from "../components/sections/Pricing";
import Services from "../components/sections/Services";
import VideoEx from "../components/sections/VideoEx";
import Testmonials from "../components/sections/Testmonials";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useLocation } from "react-router-dom";
import AiSection from "../components/sections/AiSection";
import Features from "../components/sections/Features";
import Publicity from "../components/sections/Publicity";
import BlogIntro from "../components/sections/BlogIntro";

const Home = () => {
    const location = useLocation();
    const [showLoader, setShowLoader] = useState(true);
    const [showTopButton, setShowTopButton] = useState(false);
    const THREE_DAYS_IN_MS = 3 * 24 * 60 * 60 * 1000;
    const NOW_IN_MS = new Date().getTime();
  
    const dateTimeAfterThreeDays = NOW_IN_MS + THREE_DAYS_IN_MS;
    const goToTop = () => {
        const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        if (scrollTop > 0) {
            window.requestAnimationFrame(goToTop);
            window.scrollTo(0, scrollTop - scrollTop / 8);
        }
    };

    const onScrollHandler = () => {
        if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
            setShowTopButton(true);
        } else {
            setShowTopButton(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', onScrollHandler);

        const screenLoader = document.getElementsByClassName('screen_loader');
        if (screenLoader?.length) {
            // screenLoader[0].classList.add("animate__fadeOut");
            setTimeout(() => {
                setShowLoader(false);
            }, 300);
        }

        return () => {
            window.removeEventListener('onscroll', onScrollHandler);
        };
    }, [location.pathname]);

    useEffect(() => {
        window.scroll(0, 0);
    }, [location.pathname]);

    useEffect(() => {
        AOS.init({
            once: true,
        });
    }, []);
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, []);
    return (
        <>
            {showLoader && (
                <div className="screen_loader fixed inset-0 z-[60] grid place-content-center bg-[#fafafa] dark:bg-[#060818]">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        width="200px"
                        height="200px"
                        viewBox="0 0 100 100"
                        preserveAspectRatio="xMidYMid"
                    >
                        <circle cx="50" cy="50" r="0" fill="none" stroke="#47bdff" strokeWidth="4">
                            <animate
                                attributeName="r"
                                repeatCount="indefinite"
                                dur="1s"
                                values="0;16"
                                keyTimes="0;1"
                                keySplines="0 0.2 0.8 1"
                                calcMode="spline"
                                begin="0s"
                            ></animate>
                            <animate
                                attributeName="opacity"
                                repeatCount="indefinite"
                                dur="1s"
                                values="1;0"
                                keyTimes="0;1"
                                keySplines="0.2 0 0.8 1"
                                calcMode="spline"
                                begin="0s"
                            ></animate>
                        </circle>
                        <circle cx="50" cy="50" r="0" fill="none" stroke="#b476e5" strokeWidth="4">
                            <animate
                                attributeName="r"
                                repeatCount="indefinite"
                                dur="1s"
                                values="0;16"
                                keyTimes="0;1"
                                keySplines="0 0.2 0.8 1"
                                calcMode="spline"
                                begin="-0.5s"
                            ></animate>
                            <animate
                                attributeName="opacity"
                                repeatCount="indefinite"
                                dur="1s"
                                values="1;0"
                                keyTimes="0;1"
                                keySplines="0.2 0 0.8 1"
                                calcMode="spline"
                                begin="-0.5s"
                            ></animate>
                        </circle>
                    </svg>
                </div>
            )}
            <div className="font-assistant bg-white text-base text-gray antialiased dark:bg-[#101926] dark:from-transparent dark:to-transparent">
                <Header className={showTopButton ? 'sticky-header' : ''} />
                <div className="-mt-[82px] overflow-x-hidden bg-gradient-to-r from-[#FCF1F4] to-[#EDFBF9] dark:bg-none lg:-mt-[106px]">
                    <Hero />
                    <Publicity />
                    <HelpSection />
                    {/* <AiSection />
                    */}
                    <BlogIntro />
                    <Features />
                    <Pricing targetDate={dateTimeAfterThreeDays} />
                    <Testmonials />
                    <VideoEx />
                    <Contact />
                    <Footer />
                </div>
            </div>
            {showTopButton && (
                <button type="button" className="fixed bottom-5 z-10 animate-bounce ltr:right-5 rtl:left-5" onClick={goToTop}>
                    <div className="group flex h-14 w-14 items-center justify-center rounded-full border border-black/20 bg-black text-white transition duration-500 hover:bg-secondary dark:bg-primary dark:hover:bg-secondary">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="h-6 w-6 transition group-hover:text-black"
                        >
                            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                        </svg>
                    </div>
                </button>
            )}
        </>

    )
}
export default Home