import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom";
import { getHistories, reset } from "../../redux/history/historySlice";

import Layout from "../components/layouts/Layout";
import Spinner from "../components/layouts/Spinner";
import ProjectItems from "../components/sections/ProjectItems";
import ProjectTools from "../components/sections/ProjectTools";

const Projects = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { user } = useSelector((state) => state.auth)
    const { histories, isLoading, isError, message } = useSelector(
        (state) => state.history
    )

    useEffect(() => {
        if (isError) {
            console.log(message)
        }
        if (!user) {
            navigate('/login')
        }
        dispatch(getHistories())
        return () => {
            dispatch(reset())
        }
    }, [user, navigate, isError, message, dispatch])

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, []);

    return (
        <Layout>
            <div className="min-h-100vh flex grow bg-white dark:bg-navy-900">
                <main className="main-content w-full px-[var(--margin-x)] pb-8">
                    <ProjectTools />
                </main>
            </div>
        </Layout>
    )
}
export default Projects